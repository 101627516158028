import { Node, Edge, isNode, isEdge } from "reactflow";
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from "lodash"
import {
    Workflow,
    Action,
    Operator,
    OperatorType,
    WorkflowNodeType,
    WorkflowNodeProps,
    WorkflowStepType,
    WorkflowStepAction,
    WorkflowStepOperator,
    WorkflowStepProvider,
    WorkflowStepProviders,
    ActionType,
    IODataValueMap,
} from "types";
import { useWorkflowStepsStore } from "store";

import {
    getStepName,
    generateReferenceName,
} from "utility/workflow";

import {
    DecisionStepLabel,
    OperatorConditionEdgeLabel,
    OperatorApprovalEdgeLabel,
    EdgeData,
    OperatorParallelEdgeLabel,
    DropzoneNodeHint,
    LabelColors
} from "./constants";

import {
    getCanvasElementsFromWorkflow
} from "./canvas";


export function addWorkflowNode(props: WorkflowNodeProps, dropzoneNodeId: string, workflow: Workflow, elements: any[], labelColors: LabelColors) {
    const step = constructStepFromProps(props, workflow);
    addWorkflowStep(props, step, dropzoneNodeId, workflow, elements);
    return getCanvasElementsFromWorkflow(workflow, labelColors);
}

export function moveWorkflowNode(props: WorkflowNodeProps, dropzoneNodeId: string, workflow: Workflow, labelColors: LabelColors) {
    const step = workflow.steps[props.id];
    if(step) {
        const newStep = cloneDeep(step);    
        //First delete workflow step with removing it completely from workflow
        deleteWorkflowStep(props.id, step, "", workflow);
        newStep.nextStep = "";
        const newElements = getCanvasElementsFromWorkflow(workflow, labelColors);
        //add the same step at new dropzone
        addWorkflowStep(props, newStep, dropzoneNodeId, workflow, newElements);
    }
    return getCanvasElementsFromWorkflow(workflow, labelColors);
}

export function pasteWorkflowStep(sourceId: string, dropzoneNodeId: string, workflow: Workflow, elements: any, labelColors: LabelColors) {

    const node = elements.find((x: any) => isNode(x) && x.id == sourceId);
    if (node) {
        const props: WorkflowNodeProps = { ...node.data, id: uuidv4() }
        const step = cloneDeep(workflow.steps[sourceId]) as WorkflowStepType;
        step.name = getStepName(props.resourceId, props.nodeType).displayName;
        step.referenceName = generateReferenceName(step.name, workflow);
        step.nextStep = "";
        addWorkflowStep(props, step, dropzoneNodeId, workflow, elements);
        return getCanvasElementsFromWorkflow(workflow, labelColors);
    }
    return elements;
}

export function swapNodeBranch(sourceId: string, workflow: Workflow, labelColors: LabelColors) {
    //swap steps
    const step = workflow.steps[sourceId] as WorkflowStepOperator;
    if (!step) return;

    const trueSteps = step.decisionSteps[DecisionStepLabel.True];
    const falseSteps = step.decisionSteps[DecisionStepLabel.False];
    step.decisionSteps[DecisionStepLabel.False] = trueSteps;
    step.decisionSteps[DecisionStepLabel.True] = falseSteps;

    workflow.steps[sourceId] = step;
    return getCanvasElementsFromWorkflow(workflow, labelColors);
}

export function deleteWorkflowNode(sourceId: string, keepBranchId: string, workflow: Workflow, labelColors: LabelColors) {
    const step = workflow.steps[sourceId];
    if(step) {
        deleteWorkflowStep(sourceId, step, keepBranchId, workflow);
    }
    return getCanvasElementsFromWorkflow(workflow, labelColors);
}


export function getEditorNotCompatibleDroppables(draggableId: string, elements: any) {
    const connectdEdges = elements.filter((x: any) => isEdge(x)
        && (x.source == draggableId || x.target == draggableId));
    const uncompatibleDroppables: string[] = [];
    if (connectdEdges) {
        for (const edge of connectdEdges) {
            if (edge.source != draggableId) {
                uncompatibleDroppables.push(edge.source);
            }
            if (edge.target != draggableId) {
                uncompatibleDroppables.push(edge.target);
            }
        }
    }
    return uncompatibleDroppables
}

export function getNotCompatibleDroppablesForBreakOperator(elements: any[]) {
    const uncompatibleDroppables: string[] = [];
    const dropzoneNodes = elements.filter( (x: any) =>  isNode(x) && x.type == WorkflowNodeType.Dropzone) as Node [];
    dropzoneNodes.forEach( (n: Node) => {
        if (!(n.data.dropzoneHint && n.data.dropzoneHint == DropzoneNodeHint.InsideLoopAndBreakCompatible)) {
            uncompatibleDroppables.push(n.id);
        }
    });
    return uncompatibleDroppables
}

function addWorkflowStep(props: WorkflowNodeProps, step: WorkflowStepType, dropzoneNodeId: string, workflow: Workflow, elements: any[]) {
    const parentNode = findParentNodeOfDropzone(dropzoneNodeId, elements);
    if (!parentNode) return;

    const childNode = findChildNodeOfDropzone(dropzoneNodeId, elements);
    //link the step->nextStep to existing childNode(if any)
    if (childNode) {
        step.nextStep = childNode.id;
    }

    //Link parentnode->nextStep to currentStep
    switch (parentNode.type) {
        case WorkflowNodeType.Trigger: {
            workflow.triggerRef.nextStep = props.id;
            break;
        }
        case WorkflowNodeType.Operator: {
            const parentStep = workflow.steps[parentNode.id];
            switch (parentNode.data.resourceType) {
                case OperatorType.Condition: {
                    const edge = elements.find((x) => isEdge(x) && x.target == dropzoneNodeId);
                    //determine inside branch or not
                    switch (edge.label) {
                        case OperatorConditionEdgeLabel.True:
                            (parentStep as WorkflowStepOperator).decisionSteps[DecisionStepLabel.True] = props.id;
                            break;
                        case OperatorConditionEdgeLabel.False:
                            (parentStep as WorkflowStepOperator).decisionSteps[DecisionStepLabel.False] = props.id;
                            break
                        default:
                            parentStep.nextStep = props.id;
                    }
                    break;
                }
                case OperatorType.Approval: {
                    const edge = elements.find((x) => isEdge(x) && x.target == dropzoneNodeId);
                    //determine inside branch or not
                    switch (edge.label) {
                        case OperatorApprovalEdgeLabel.True:
                            (parentStep as WorkflowStepOperator).decisionSteps[DecisionStepLabel.True] = props.id;
                            break;
                        case OperatorApprovalEdgeLabel.False:
                            (parentStep as WorkflowStepOperator).decisionSteps[DecisionStepLabel.False] = props.id;
                            break
                        default:
                            parentStep.nextStep = props.id;
                    }
                    break;
                }
                case OperatorType.Search: {
                    parentStep.nextStep = props.id;
                    break;
                }
                case OperatorType.Wait: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Break: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.KV: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Subworkflow: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Http: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Db: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Ai: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Script: {
                    parentStep.nextStep = props.id
                    break;
                }
                case OperatorType.Loop: {
                    const edge = elements.find((x) => isEdge(x) && x.target == dropzoneNodeId);
                    switch (edge.data.hiddenLabel) {
                        case OperatorParallelEdgeLabel.Join: {
                            parentStep.nextStep = props.id;
                            break;
                        }
                        default:
                            (parentStep as WorkflowStepOperator).loopStep = props.id
                    }
                    break;
                }
                case OperatorType.Parallel: {
                    const edge = elements.find((x) => isEdge(x) && x.target == dropzoneNodeId);
                    //determine inside branch or not
                    switch (edge.data.hiddenLabel) {
                        case OperatorParallelEdgeLabel.Branch: {
                            (parentStep as WorkflowStepOperator).parallelSteps.push(props.id);
                            break;
                        }
                        case OperatorParallelEdgeLabel.Top: {
                            const sourceEdge = elements.find((x) => isEdge(x) && x.source == dropzoneNodeId);
                            const topStep = workflow.steps[sourceEdge.target];
                            if(topStep) {
                                //find the top most and replace it with props.id
                                for( let i = 0 ; i < (parentStep as WorkflowStepOperator).parallelSteps.length; i++){
                                    if(sourceEdge.target == (parentStep as WorkflowStepOperator).parallelSteps[i]) {
                                        step.nextStep = (parentStep as WorkflowStepOperator).parallelSteps[i];
                                        (parentStep as WorkflowStepOperator).parallelSteps[i] = props.id;
                                        break;
                                    }
                                }
                            }else {
                                (parentStep as WorkflowStepOperator).parallelSteps.push(props.id);
                            }
                            break;
                        }
                        default: //Join
                            parentStep.nextStep = props.id;
                    }
                    break;
                }
            }
            break;
        }
        case WorkflowNodeType.Action: {
            const parentStep = workflow.steps[parentNode.id];
            parentStep.nextStep = props.id
            break;
        }
        default:
            break;
    }
    workflow.isConfigured = false;
    workflow.steps[props.id] = step;
}

function deleteWorkflowStep(sourceId: string, step: WorkflowStepType, keepBranchId: string, workflow: Workflow) {
    //delete branches which are not required, in case of branch type step getting deleted
    deleteStepBranches(step, keepBranchId, workflow);
    
    let nextStep = step.nextStep;
    let rtype : ActionType | OperatorType | "" = ActionType.Custom;
    
    //deduce next step
    switch(step.type){
        case WorkflowNodeType.Operator: {
            rtype = findStepResourceType(step.operatorID, step.type);
            switch (rtype) {
                case OperatorType.Condition: {
                    if (Object.entries(step.decisionSteps).length > 0 ) {
                        nextStep = step.decisionSteps[DecisionStepLabel.True] ? 
                            step.decisionSteps[DecisionStepLabel.True] : step.decisionSteps[DecisionStepLabel.False];
                        const stepsChain: string[] = [];
                        createStepsChain(nextStep, stepsChain, workflow);
                        //find the last node in this branch and attach it to step.nextStep
                        const lastStepIdInChain = stepsChain.at(-1)
                        if(lastStepIdInChain) {
                            workflow.steps[lastStepIdInChain].nextStep = step.nextStep;
                        }
                    }
                    break;
                }
                case OperatorType.Approval: {
                    if (Object.entries(step.decisionSteps).length > 0 ) {
                        nextStep = step.decisionSteps[DecisionStepLabel.True] ? 
                            step.decisionSteps[DecisionStepLabel.True] : step.decisionSteps[DecisionStepLabel.False];
                        const stepsChain: string[] = [];
                        createStepsChain(nextStep, stepsChain, workflow);
                        //find the last node in this branch and attach it to step.nextStep
                        const lastStepIdInChain = stepsChain.at(-1)
                        if(lastStepIdInChain) {
                            workflow.steps[lastStepIdInChain].nextStep = step.nextStep;
                        }
                    }
                    break;
                }
                case OperatorType.Parallel: {
                    if(step.parallelSteps.length > 0) {
                        nextStep = step.parallelSteps[0];
                        const stepsChain: string[] = [];
                        createStepsChain(nextStep, stepsChain, workflow);
                        //find the last node in this branch and attach it to step.nextStep
                        const lastStepIdInChain = stepsChain.at(-1)
                        if(lastStepIdInChain) {
                            workflow.steps[lastStepIdInChain].nextStep = step.nextStep;
                        }
                    }
                    break;
                }
                case OperatorType.Loop: {
                    if(step.loopStep   != "") {
                        nextStep = step.loopStep;
                    }
                    break;
                }
            }
            break;
        }
    }

    const isTriggerParent = workflow.triggerRef.nextStep == sourceId;
    if (isTriggerParent) {
        workflow.triggerRef.nextStep = nextStep;
    } else {
        const parentStepId = findParentStepId(sourceId, workflow);
        if (parentStepId) {
            const parentStep = workflow.steps[parentStepId];
            switch (parentStep.type) {
                case WorkflowNodeType.Operator: {
                    const rtype = findStepResourceType(parentStep.operatorID, parentStep.type);
                    switch (rtype) {
                        case OperatorType.Condition: {
                            for (const [label, dStepId] of Object.entries(parentStep.decisionSteps)) {
                                if (dStepId == sourceId) {
                                    if(nextStep != "") {
                                        parentStep.decisionSteps[label] = nextStep;
                                    }else {
                                        delete parentStep.decisionSteps[label];
                                    }
                                    break;
                                }
                            }
                            if(parentStep.nextStep == sourceId) {
                                parentStep.nextStep = nextStep;
                            }
                            break;
                        }
                        case OperatorType.Approval: {
                            for (const [label, dStepId] of Object.entries(parentStep.decisionSteps)) {
                                if (dStepId == sourceId) {
                                    if(nextStep != "") {
                                        parentStep.decisionSteps[label] = nextStep;
                                    }else {
                                        delete parentStep.decisionSteps[label];
                                    }
                                    break;
                                }
                            }
                            if(parentStep.nextStep == sourceId) {
                                parentStep.nextStep = nextStep;
                            }
                            break;
                        }
                        case OperatorType.Parallel: {
                            for(let i = 0; i<  parentStep.parallelSteps.length; i++) {
                                const pStepId = parentStep.parallelSteps[i];
                                if (pStepId == sourceId) {
                                    if(nextStep != "") {
                                        parentStep.parallelSteps[i] = nextStep
                                    }else {
                                        parentStep.parallelSteps = parentStep.parallelSteps.filter( (x) => (x != sourceId));
                                    }
                                    break;
                                }
                            }
                            if(parentStep.nextStep == sourceId) {
                                parentStep.nextStep = nextStep;
                            }
                            break;
                        }
                        case OperatorType.Loop: {
                            if (parentStep.loopStep == sourceId) {
                                if(nextStep != "") {
                                    parentStep.loopStep = nextStep
                                }else {
                                    parentStep.loopStep = "";
                                break;
                            }
                            }
                            if(parentStep.nextStep == sourceId) {
                                parentStep.nextStep = nextStep;
                            }
                            break;
                        }
                        default:
                            //remove the current steps from the parent->next steps
                            parentStep.nextStep = nextStep;
                    }
                    break;
                }
                default:
                    //remove the current steps from the parent->next steps
                    parentStep.nextStep = nextStep;
            }
        }
    }
    delete workflow.steps[sourceId];
}

function deleteStepBranches(step: WorkflowStepType, keepBranchId: string, workflow: Workflow) {
     if (keepBranchId != "") {
        const opStep = step as WorkflowStepOperator;
        const rtype = findStepResourceType(opStep.operatorID, opStep.type);
        const branchesToBeTraversed: string[] = [];
        switch(rtype) {
            case OperatorType.Parallel:{
                opStep.parallelSteps.forEach ((pStepId, index) =>  {
                    if( pStepId != keepBranchId) {
                        branchesToBeTraversed.push(opStep.parallelSteps[index]);
                    }
                });
                opStep.parallelSteps = [keepBranchId];
                break;
            }
            case OperatorType.Condition:
                Object.entries(opStep.decisionSteps).forEach(([label, dStepId]) => {
                    if (dStepId != keepBranchId) {
                        branchesToBeTraversed.push(dStepId);
                        delete opStep.decisionSteps[label];
                    }
                });
                break;
            case OperatorType.Approval:
                Object.entries(opStep.decisionSteps).forEach(([label, dStepId]) => {
                    if (dStepId != keepBranchId) {
                        branchesToBeTraversed.push(dStepId);
                        delete opStep.decisionSteps[label];
                    }
                });
                break;
        }
        const stepsToBeDeleted: string[] = [];
        
        branchesToBeTraversed.forEach((stepId: string) => {
            createStepsChain(stepId, stepsToBeDeleted, workflow);
        });
        
        stepsToBeDeleted.forEach((stepId: string) => {
            delete workflow.steps[stepId];
        });
    }
}


function findParentNodeOfDropzone(dropzoneId: string, elements: any[]) {
    const parentEdge = elements.find((x) => isEdge(x) && x.target == dropzoneId) as Edge;
    if (parentEdge) {
        if (parentEdge.data) {
            const edgeData = parentEdge.data as EdgeData;
            return elements.find((x) => isNode(x) && x.id == edgeData.parentId) as Node;
        } else {
            return elements.find((x) => isNode(x) && x.id == parentEdge.source) as Node;
        }
    }
}

function findChildNodeOfDropzone(dropzoneId: string, elements: any[]) {
    const dropzoneChildEdge = elements.find((x) => isEdge(x) && x.source == dropzoneId) as Edge;
    if (dropzoneChildEdge) {
        return elements.find((x) => isNode(x) && x.type != WorkflowNodeType.Dropzone && x.type != WorkflowNodeType.End && x.id == dropzoneChildEdge.target);
    }
}

function findParentStepId(stepId: string, workflow: Workflow) {
    for (const [currentStepId, step] of Object.entries(workflow.steps)) {
        switch (step.type) {
            case WorkflowNodeType.Action: {
                if (step.nextStep == stepId) return currentStepId;
                break;
            }
            case WorkflowNodeType.Operator: {
                const rtype = findStepResourceType(step.operatorID, step.type);
                switch (rtype) {
                    case OperatorType.Condition: {
                        for (const [_, dStepId] of Object.entries(step.decisionSteps)) {
                            if (dStepId == stepId) return currentStepId;
                        }
                        if (step.nextStep == stepId) return currentStepId;
                        break;
                    }
                    case OperatorType.Approval: {
                        for (const [_, dStepId] of Object.entries(step.decisionSteps)) {
                            if (dStepId == stepId) return currentStepId;
                        }
                        if (step.nextStep == stepId) return currentStepId;
                        break;
                    }
                    case OperatorType.Parallel: {
                        for (const pStepId of step.parallelSteps) {
                            if (pStepId == stepId) return currentStepId;
                        }
                        if (step.nextStep == stepId) return currentStepId;
                        break;
                    }
                    case OperatorType.Loop: {
                        if(step.loopStep == stepId) return currentStepId;
                        if (step.nextStep == stepId) return currentStepId;
                        break;
                    }
                    default:
                        if (step.nextStep == stepId) return currentStepId;
                }
                break;
            }
        }
    }
}


export function findStepResourceType(resourceId: string, stepType: string) {
    switch (stepType) {
        case WorkflowNodeType.Action: {
            const action = useWorkflowStepsStore.getState().actions.find((i: Action) => i.id == resourceId) as Action;
            return action.type;
        }
        case WorkflowNodeType.Operator: {
            const operator = useWorkflowStepsStore.getState().operators.find((i: Operator) => i.id == resourceId) as Operator;
            return operator.type;
        }
        default:
            return "";
    }
}

function createStepsChain(stepId: string, stepsChain: string[], workflow: Workflow) {
    stepsChain.push(stepId);
    const step = workflow.steps[stepId];
    switch (step.type) {
        case "action": {
            step.nextStep != "" && createStepsChain(step.nextStep, stepsChain, workflow);
            break;
        }
        case "operator": {
            const operator = useWorkflowStepsStore.getState().operators.find((i: Operator) => i.id == step.operatorID) as Operator;
            switch (operator.type) {
                case OperatorType.Condition: {
                    Object.entries(step.decisionSteps).forEach(([label, dStep]) => {
                        createStepsChain(dStep, stepsChain, workflow);
                    });
                    break;
                }
                case OperatorType.Approval: {
                    Object.entries(step.decisionSteps).forEach(([label, dStep]) => {
                        createStepsChain(dStep, stepsChain, workflow);
                    });
                    break;
                }
                case OperatorType.Parallel: {
                    step.parallelSteps.forEach((pStepId) => {
                        createStepsChain(pStepId, stepsChain, workflow);
                    });
                    break;
                }
                default:
                    step.nextStep != "" && createStepsChain(step.nextStep, stepsChain, workflow);
            }
            break;
        }
    }
}

function constructStepFromProps(props: WorkflowNodeProps, workflow: Workflow) {
    switch (props.nodeType) {
        case WorkflowNodeType.Action: {
            const stepName = getStepName(props.resourceId, props.nodeType).displayName;
            const step: WorkflowStepAction = {
                isConfigured: false,
                isAuditRequired: false,
                type: "action",
                name: stepName,
                referenceName: generateReferenceName(stepName, workflow),
                actionID: props.resourceId ? props.resourceId : "",
                parameters: {},
                includeRawOutput: false,
                nextStep: "",
                prevStep: "",
                provider: {} as WorkflowStepProvider,
            }
            return step;
        }
        case WorkflowNodeType.Operator: {
            const stepName = getStepName(props.resourceId, props.nodeType).displayName;
            const step: WorkflowStepOperator = {
                isConfigured: false,
                isAuditRequired: false,
                type: "operator",
                name: stepName,
                referenceName: generateReferenceName(stepName,workflow),
                operatorID: props.resourceId ? props.resourceId : "",
                operatorType: props.resourceType as OperatorType,
                parameters: {},
                searchProviders: [] as WorkflowStepProviders,
                actionProvider: {} as WorkflowStepProvider,
                httpProvider: {} as WorkflowStepProvider,
                dbProvider: {} as WorkflowStepProvider,
                actionParameters: {} as IODataValueMap,
                includeRawOutput: false,
                nextStep: "",
                prevStep: "",
                decisionSteps: {},
                parallelSteps: [],
                branchSteps: [],
                loopStep: "",
            }
            return step;
        }
        default:
            throw Error("not supported step");
    }
}