import {
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Input, theme, Tooltip, Typography } from "antd";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OpenAIOutlined } from "@ant-design/icons";

import { useOrganizationStore, useSearchArtifactStore, useSearchStore } from "store";
import { AIBasedSearchQueryPolicyName, PolicyCategoryType, SearchBarProps, SearchMetaData } from "types";
import { validateSearchQuery } from "utility/search";
import { getSearchSuggestions } from "utility/search/assist";
import styles from "./SearchBar.module.scss";
import { usePolicyStore } from "store/policy";

const SearchBar: FC<SearchBarProps> = ({ inHeader, onQuery }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [searchButtonStatus, setSearchButtonStatus] = useState(false);
  const [searchSuggestionOptions, setSearchSuggestionOptions] = useState<{ label: string; value: string }[]>([]);
  const [searchQueryLastTokensList, setSearchQueryLastTokensList] = useState<string[]>([]);
  const [suggestionBoxOpen, setSuggestionBoxOpen] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const { context } = useOrganizationStore( (state) => ({
    context: state.context
  }));

  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));

  const [aiBasedSearchQuery, setAiBasedSearchQuery] = useState<boolean>();
  const {
    searchPolicies,
    getPolicies
  } = usePolicyStore((state) => ({
    searchPolicies: state.policies[PolicyCategoryType.SearchGeneral],
    getPolicies: state.getPolicies
  }));

  const { searchMetaData, setSearchMetaData, runSearchX, runSearch, resetSearchState } = useSearchStore(
    (state) => ({
      searchMetaData: state.searchMetaData,
      setSearchMetaData: state.setSearchMetaData,
      runSearchX: state.runSearchX,
      runSearch: state.runSearch,
      resetSearchState: state.resetSearchState,
    })
  );

  const syncArtifacts = async () => {
    try {
      if (!artifacts.length) {
        await getArtifacts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      syncArtifacts();
      await getPolicies(PolicyCategoryType.SearchGeneral);
    };
    asyncUseEffect();
    if (searchMetaData && searchMetaData.query && searchMetaData.query != "") {
      validateSearch();
    }
  }, []);

  useEffect(() => {
    if (searchPolicies) {
      const policy = searchPolicies.find((p) => p.name == AIBasedSearchQueryPolicyName);
      setAiBasedSearchQuery(policy?.value.data);
    }
  }, [searchPolicies]);

  useEffect(() => {
   setSearchMetaData({
      ...searchMetaData,
      searchTags: [context],
    } as SearchMetaData);
  }, [context]);

  useEffect(() => {
    onSearchItemChange(searchMetaData.query);
  }, [searchMetaData.query]);

  const resetState = () => {
    setSearchButtonStatus(false);
    setSearchSuggestionOptions([]);
    setSearchQueryLastTokensList([]);
    setSuggestionBoxOpen(false);
    setSearchInProgress(false);
  };

  function setSearchOptions(value: string) {
    // generating search suggestion
    const { suggestions, lastTokens } = getSearchSuggestions(value);
    const searchSuggestionOptions = suggestions.map((suggestion) =>
      suggestion.tokens.map((token) => {
        const isMandatory = suggestion.mandatoryTokens?.find((x) => x == token);
        return {
          label: isMandatory ? token + "*" : token,
          value: token,
        };
      })
    );
    setSearchSuggestionOptions(searchSuggestionOptions[0]);
    setSearchQueryLastTokensList(lastTokens);
    if (value != searchMetaData.query) {
      setSearchMetaData({
        ...searchMetaData,
        query: value,
        isAiBasedQuery: searchMetaData.isAiBasedQuery,
        searchTags: [context],
      } as SearchMetaData);
    } else {
      setSearchMetaData({
        ...searchMetaData,
        query: value,
        nativeQuery: "",
        searchTags: [context],
      } as SearchMetaData);
    }
  }

  function onSearchItemChange(value: string) {
    setSearchButtonStatus(false);
    if (value == "") {
      setSearchOptions(value);
      return;
    }
    // generating search suggestion
    setSearchOptions(value);
    validateSearch();
  }

  function calculateSearchQueryOnSuggestionSelect(
    selectedValue: string
  ): string {
    let newSearchQuery = "";
    if (searchMetaData.query) {
      newSearchQuery = searchMetaData.query;
    }

    const searchTokens = searchMetaData.query?.split(" ");
    if (searchTokens?.length) {
      if (searchQueryLastTokensList.length) {
        for (const token of searchQueryLastTokensList) {
          if (
            token.length &&
            selectedValue.includes(token) &&
            searchTokens.pop() == token
          ) {
            newSearchQuery = searchTokens.join(" ");
          }
        }
      }
    }
    if (newSearchQuery.at(-1) && newSearchQuery.at(-1) != " ") {
      newSearchQuery = newSearchQuery + " ";
    }
    return newSearchQuery + selectedValue;
  }

  const onSelect = (value: string) => {
    setSearchButtonStatus(false);
    onSearchItemChange(calculateSearchQueryOnSuggestionSelect(value));
    setSearchButtonStatus(true);
  };

  const onSearch = async () => {
    try {
      if (searchMetaData.query) {
        if (onQuery) onQuery(searchMetaData);
        else {
          setLoader(true);
          const searchRunId = searchMetaData.id
            ? await runSearch()
            : await runSearchX();
          if (searchRunId) {
            resetSearchState();
            navigate(`/search-runs/${searchRunId}`);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setSearchInProgress(false);
    }
  };

  const searchButton = () => (
    <Button
      style={{
        backgroundColor: token.colorPrimaryActive,
      }}
      loading={loader}
      icon={<SearchOutlined style={{ color: token.colorTextLightSolid }} />}
      onClick={() => {
        if (!searchInProgress && searchButtonStatus) {
          setSearchInProgress(true);
          onSearch();
        }
      }}
    />
  );

  const handleKeyDownAutoComplete = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case "Escape": {
        e.preventDefault();
        e.stopPropagation();
        break;
      }
      case "Enter": {
        e.preventDefault();
        e.stopPropagation();
        break;
      }
    }
  };

  const validateSearch = () => {
    if (searchMetaData.query != "") {
      let result = false;
      try {
        if (searchMetaData.query) {
          result = validateSearchQuery(searchMetaData.query);
        }
      } catch (error) {
        result = false;
      }
      result ? setSearchButtonStatus(true) : setSearchButtonStatus(false);
    }
  };

  const renderInputStatus = () => {
    if (searchMetaData.query != "") {
      return searchButtonStatus ? (
        <CheckCircleFilled style={{ color: token.colorSuccess }} />
      ) : (
        <CloseCircleFilled style={{ color: token.colorWarning }} />
      );
    } else {
      return <></>;
    }
  };

  function onAiSearchItemChange(value: string) {
    if (value != searchMetaData.query) {
      setSearchMetaData({
        ...searchMetaData,
        query: value,
        isAiBasedQuery: searchMetaData.isAiBasedQuery,
        searchTags: [context],
      } as SearchMetaData);
    } else {
      setSearchMetaData({
        ...searchMetaData,
        query: value,
        nativeQuery: "",
        searchTags: [context],
      } as SearchMetaData);
    }
  }

  const getInputPrefix = (aiEnabled: boolean) => {
    return (
      <OpenAIOutlined
        className={!aiEnabled ? styles.aiEnabled : styles.aiDisabled}
        onClick={() => {
          setSearchMetaData({
            ...searchMetaData,
            query: "",
            isAiBasedQuery: aiEnabled,
            searchTags: [context],
          } as SearchMetaData);
          resetState();
        }}
        style={{
          color: searchMetaData.isAiBasedQuery
            ? token.colorSuccess
            : token.colorPrimary,
          marginRight: "5px",
        }}
      />
    );
  };

  const getNativeQueryInput = () => {
    return (
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <AutoComplete
          style={{ flex: 1 }}
          autoFocus={inHeader ? false : true}
          //defaultActiveFirstOption={true}
          defaultOpen={false}
          options={searchSuggestionOptions}
          disabled={searchInProgress}
          onSelect={onSelect}
          onSearch={onSearchItemChange}
          onFocus={() => setSearchOptions(searchMetaData.query)}
          value={searchMetaData.query}
          onDropdownVisibleChange={(open: boolean) =>
            setSuggestionBoxOpen(open)
          }
          onKeyDown={handleKeyDownAutoComplete}
        >
          <Input.Search
            placeholder="Search for any artifact across HyprEdge and connected Apps"
            loading={loader}
            enterButton={searchButton()}
            onPressEnter={() => {
              if (
                !searchInProgress &&
                !suggestionBoxOpen &&
                searchButtonStatus
              ) {
                setSearchInProgress(true);
                onSearch();
              }
            }}
            onSearch={() => {
              if (
                !searchInProgress &&
                !suggestionBoxOpen &&
                searchButtonStatus
              ) {
                setSearchInProgress(true);
                onSearch();
              }
            }}
            // prefix={getInputPrefix(true)} // Disable AI for now
            suffix={renderInputStatus()}
          />
        </AutoComplete>
      </div>
    );
  };

  const getAiQueryInput = () => {
    return (
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <Input.Search
          placeholder="Search for any artifact across HyprEdge and connected Apps"
          loading={loader}
          enterButton={searchButton()}
          onPressEnter={() => {
            if (!searchInProgress) {
              setSearchInProgress(true);
              onSearch();
            }
          }}
          onSearch={() => {
            if (!searchInProgress) {
              setSearchInProgress(true);
              onSearch();
            }
          }}
          prefix={getInputPrefix(false)}
          onChange={(e) => {
            onAiSearchItemChange(e.target.value);
          }}
          value={searchMetaData.query}
        />
        {searchMetaData.assistMessage && (
          <Tooltip title={searchMetaData.assistMessage}>
            <Typography.Text
              italic
              ellipsis={inHeader}
              style={{
                marginLeft: token.marginSM,
                fontSize: token.fontSizeSM,
                maxWidth: inHeader ? "500px" : "",
              }}
            >
              {searchMetaData.assistMessage}
            </Typography.Text>
          </Tooltip>
        )}
      </div>
    );
  };

  return aiBasedSearchQuery 
    ?
      !searchMetaData.isAiBasedQuery ? getNativeQueryInput() : getAiQueryInput()
    :
      getNativeQueryInput();
};

export default SearchBar;
