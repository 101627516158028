import { FC, useEffect, useState, } from "react";
import { Form, Input, notification, Space, Switch } from "antd";

const { TextArea } = Input;

import Modal from "components/Modal";
import { FieldLabel } from "components/FieldLabel";
import { CaseNote } from "types";
import { useCaseAttachmentStore, useCaseNotesStore } from "store";
import { TextWithIcon } from "components/TextWithIcon";
import { caseMgmtIcons } from "assets/icons";
import RichEditor from "components/RichEditor";
import { FileUpload } from "./FileUpload";

export interface CaseNoteProps {
  caseId: string;
  noteId?: string;
  open: boolean;
  hideNotification?: boolean;
  hideAttachment?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const CaseMgmtNote: FC<CaseNoteProps> = ({
  caseId,
  noteId,
  open,
  hideNotification,
  hideAttachment,
  onClose,
  onSubmit
}) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [content, setContent] = useState("");

  const {
    selectedNote,
    getCaseNote,
    createCaseNote,
    updateCaseNote
  } = useCaseNotesStore((state) => ({
    selectedNote: state.selectedNote,
    getCaseNote: state.getCaseNote,
    createCaseNote: state.createCaseNote,
    updateCaseNote: state.updateCaseNote
  }));

  const {
    uploadCaseAttachment,
  } = useCaseAttachmentStore((state) => ({
    uploadCaseAttachment: state.uploadCaseAttachment,
  }));

  const loadCaseNote = async (caseId: string, noteId: string) => {
    try {
      setLoader(true);
      const caseNote = await getCaseNote(caseId, noteId);
      form.setFieldsValue(caseNote);
      setContent(caseNote.content);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    noteId && noteId != "" && loadCaseNote(caseId, noteId);
  }, [noteId]);

  const onSaveCaseNote = async (values: any) => {
    try {
      const caseNote = {
        caseID: caseId,
        title: values.title,
        content: values.content,
      } as CaseNote;
      let caseNoteId = noteId;
      if (caseNoteId && caseNoteId != "") {
        caseNote.id = caseNoteId;
        await updateCaseNote(caseId, caseNote);
      } else {
        const cn = await createCaseNote(caseId, caseNote);
        caseNoteId = cn.id;
      }
      if (!hideAttachment && uploadAttachment && values.attachments) {
        const formData = new FormData();
        const uploadFiles = values.attachments as any[];
        uploadFiles.map((file) => formData.append('files', file));
        await uploadCaseAttachment(caseId, formData, caseNoteId);
      }
      if (!hideNotification) {
        notification.success({
          message: `Case note "${caseNote.title}" updated successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      if (!hideNotification) {
        notification.error({
          message: `Failed to update case note, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } 
  };

  return (
    <Modal
      loader={loader}
      title={
        <TextWithIcon 
          icon={caseMgmtIcons.caseMgmtNotesIcon}
          iconSize={18}
          text={noteId && selectedNote ? selectedNote.title : "New Note"}
        />
      }
      open={open}
      footerName={noteId ? "Update" : "Add"} 
      onClose={onClose}
      onSubmit={() => 
        form
          .validateFields()
          .then(async (values) => {
            onClose();
            await onSaveCaseNote(values);
            onSubmit();
          })
          .catch((err) => {
            console.log(err);
          })
      }
    >
      <Form 
        form={form} 
        name="noteForm" 
        layout="vertical" 
        autoComplete="off"
      >
        <Form.Item 
          name="title" 
          label={<FieldLabel label={"Title"}/>}
          rules={[
            { required: true, message: 'Title is required!'}
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="content" 
          label={<FieldLabel label={"Content"} />}
          rules={[
            { required: true, message: 'Content is required!' }
          ]}
        >
          <RichEditor 
            height="150px"
            maxChars={1000}
            editMode={true}
            content={content}
            onChange={(value) => {
              setContent(value);
              form.setFieldValue("content", value);
            }}
          />
        </Form.Item>
        {!hideAttachment 
          &&
          <Form.Item 
            name="attachments" 
            label={<Space>
              <FieldLabel label={"Attachment"}/>
              <Switch
                checked={uploadAttachment}
                onChange={setUploadAttachment}
              />
            </Space>}
            rules={[
              { required: uploadAttachment, message: 'Attachment is required!' }
            ]}
          >
            {uploadAttachment
              &&
              <FileUpload
                multiple={true} 
                onUpload={(files) => {
                  form.setFieldValue("attachments", files);
                }}
              />
            }
          </Form.Item>
        }
      </Form>
    </Modal>
  );
};