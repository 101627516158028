import {
  Badge,
  Button,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Spin,
  theme,
  Typography,
  Select,
  Switch,
} from "antd";
import { FC, useState, useEffect } from "react";
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

import { useEdgeStore, useWorkflowStepsStore, useWorkflowStore } from "store";
import { notification } from 'utility/notification';
import {
  EdgeInfraType,
  EdgeInfraTypeMap,
  SpecStateMap,
  SpecStateType,
  Trigger,
  TriggerType,
  TriggerTypeList,
  TriggerTypeMap,
  WorkflowCardProps,
  Edge,
  EdgeManageType,
  WorkflowTriggerProvider,
  WorkflowAppSubscriptionInfo,
} from "types";

import CollapsePanel from "../../CollapsePanel";
import { validateFormFields } from "utility";

import { workflowIcons } from "assets/icons";
import WorkflowCardOutputs from "./Outputs";
import WorkflowCardParameters from "./Parameters";
import { FieldLabel } from "components/FieldLabel";
import { TextWithIcon } from "components/TextWithIcon";
import { isProduction, defaultEdgeId } from "api/constant";

const WorkflowCard: FC<WorkflowCardProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [parametersForm, setParametersForm] = useState<undefined | FormInstance>(undefined);
  const [outputsForm, setOutputsForm] = useState<undefined | FormInstance>(undefined);
  const [edge, setEdge] = useState<Edge|undefined>();

  const triggers = useWorkflowStepsStore.getState().triggers;

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));
  const [triggerType, setTriggerType] = useState(selectedWorkflow.triggerRef.triggerType);
  const [isSubworkflow, setIsSubworkflow] = useState<boolean>(selectedWorkflow.isSubworkflow);

  const updateSelectedWorkflow = async () => {
    try {
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    }
  };


  const {
    edges,
    getEdges,
  } = useEdgeStore((state) => ({
    edges: state.edges,
    getEdges: state.getEdges,
  }));

  const loadEdges = async () => {
    try {
      if (!isProduction) {
        await getEdges();
      } else {
        await getEdges(false, EdgeInfraType.Amazon);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
   (triggerType != TriggerType.Custom) && loadEdges();
  }, [triggerType]);
  

  useEffect(() => {
    const edgeId = selectedWorkflow.triggerRef?.providers?.[0]?.appSubscriptionInfos?.[0]?.edgeID;
    if (edgeId) {
      setEdge(edges?.find((edge) => edge.id == edgeId));
    } else {
      setEdge(edges?.find((edge) => edge.id == defaultEdgeId));
    }
  }, [edges]);

  const OnFinish = async (values: any) => {
    form
      .validateFields()
      .then(() => {
        setLoader(true);
        selectedWorkflow.name = values.name;
        selectedWorkflow.description = values.description;
        selectedWorkflow.isSubworkflow = isSubworkflow;
        selectedWorkflow.tags = values.labels;
        
        if (triggerType != selectedWorkflow.triggerRef.triggerType) {
          selectedWorkflow.triggerRef.isConfigured = (triggerType == TriggerType.Manual);
          if (triggerType == TriggerType.Manual || triggerType == TriggerType.Schedule) {
            const trigger = triggers.find((i: Trigger) => i.type == triggerType) as Trigger;
            if (trigger) {
              selectedWorkflow.triggerRef.triggerID = trigger.id;
            }
          } else {
            selectedWorkflow.triggerRef.triggerID = "";
          }
        }

        if (triggerType != TriggerType.Custom) {
          if (edge) {
            const edgeId = selectedWorkflow.triggerRef?.providers?.[0]?.appSubscriptionInfos?.[0]?.edgeID;
            if (!edgeId) {
              selectedWorkflow.triggerRef.providers = [] as WorkflowTriggerProvider[];
              selectedWorkflow.triggerRef.providers[0] = {} as WorkflowTriggerProvider;
              selectedWorkflow.triggerRef.providers[0].appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
              selectedWorkflow.triggerRef.providers[0].appSubscriptionInfos[0] = {} as WorkflowAppSubscriptionInfo;
            }
            selectedWorkflow.triggerRef.providers[0].appSubscriptionInfos[0].edgeID = edge.id;
          }
        }

        selectedWorkflow.triggerRef.triggerType = values.triggerType;
        selectedWorkflow.parameters = values.parameters;
        selectedWorkflow.outputs = values.outputs;
        updateSelectedWorkflow();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="workflowCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" size={token.sizeXXS} style={{ display: "flex" }}>
          <Space size={token.sizeXXS} direction="vertical" style={{
             borderLeft: `2px solid ${token.colorPrimary}`,
             paddingLeft: token.paddingXS
          }}>
            <Space>
              {<FieldLabel label={"Status: "} bold />}
              {SpecStateMap.get(selectedWorkflow.state)}
              {selectedWorkflow.state == SpecStateType.StatePublishedDraft && (
                <Badge count="draft" color="orange" />
              )}
              {selectedWorkflow.state == SpecStateType.StatePublished &&
                (selectedWorkflow.enabled ? (
                  <Badge count="enabled" color="green" />
                ) : (
                  <Badge count="disabled" color="grey" />
                ))}
            </Space>
            <Space>
              {<FieldLabel label={"Version: "} bold />}
              {selectedWorkflow.versionName}
            </Space>
          </Space>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Name of workflow"} />}
            initialValue={selectedWorkflow.name}
            rules={[{ required: true, message: "Name is required!" }]}
            style={{ marginTop: token.margin }}
          >
            <Input disabled={!props.editMode} />
          </Form.Item>
          <Form.Item
            name="description"
            label={<FieldLabel label={"Description"} help={"Description of workflow"} />}
            initialValue={selectedWorkflow.description}
            rules={[{ required: true, message: "Description is required!" }]}
          >
            <TextArea
              disabled={!props.editMode}
              showCount
              maxLength={100}
            />
          </Form.Item>
          <Form.Item 
            name="labels"
            label={<FieldLabel label={"Labels"}/>}
            initialValue={selectedWorkflow.tags}
            validateTrigger="onSubmit"
          >
            <Select
              showAction={["focus", "click"]}
              placeholder={`Enter labels`}
              allowClear
              mode="tags"
            />
          </Form.Item>
          {triggerType == TriggerType.Manual
            &&
            <Form.Item
              name="isSubworkflow"
              label={<FieldLabel label={"Subworkflow"} help={"Select Subworkflow"} />}
            >
              <Switch
                checked={isSubworkflow}
                onChange={setIsSubworkflow}
              />
            </Form.Item>
          }
          {!isSubworkflow
            &&
            <Form.Item
              name="triggerType"
              label={<FieldLabel label={"Select Trigger Type"} help={"Select trigger to initiate the workflow execution"} />}
              initialValue={selectedWorkflow.triggerRef?.triggerType}
            >
              <Radio.Group
                disabled={!props.editMode}
                onChange={(e: RadioChangeEvent) => setTriggerType(e.target.value)}
                value={triggerType}
              >
                <Space direction="vertical">
                  {TriggerTypeList.map((triggerType) => (
                    <Radio key={triggerType} value={triggerType}>
                      {TriggerTypeMap.get(triggerType)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          }
          {triggerType != TriggerType.Custom
            &&
            <Form.Item 
              name="edgeId" 
              label={<FieldLabel label={"Edge"} help={"Select edge where trigger to be executed"} />}
              rules={[{ required: true, message: "Edge is required!" }]}
              required
              initialValue={selectedWorkflow.triggerRef?.providers?.[0]?.appSubscriptionInfos?.[0]?.edgeID ? selectedWorkflow.triggerRef?.providers?.[0]?.appSubscriptionInfos?.[0]?.edgeID : defaultEdgeId}
              extra={edge &&  
                <Text italic type="secondary">
                  <div>Type: {EdgeInfraTypeMap.get(edge.infraType)}</div>
                  <div>Region: {edge.region}</div>
                  <div>Managed By: {edge.manageType == EdgeManageType.HyprEdge? "HyprEdge" : "Customer"}</div>
                </Text>}
            >
              <Select
                showAction={["focus", "click"]}
                placeholder={`Select edge`}
                onSelect={(value) => setEdge(edges?.find((edge) => edge.id == value))}
                disabled={!props.editMode}
              >
                {edges?.map((edge: Edge) =>  (
                    <Option key={edge.id} value={edge.id}>
                      {edge.displayName}
                    </Option>
                ))}
              </Select>
            </Form.Item>
          }
          <CollapsePanel
            name={
              <TextWithIcon 
                icon={workflowIcons.parametersShortIcon}
                text={"Parameters"}
              />
            }
            ghost={false}
            collapsePanel={false}
          >
            <Form.Item
              name="parameters"
              rules={[
                { validator: (_, value) => validateFormFields(parametersForm) },
              ]}
            >
              <WorkflowCardParameters
                editMode={props.editMode}
                parameters={selectedWorkflow.parameters}
                onRender={(form) => setParametersForm(form)}
              />
            </Form.Item>
          </CollapsePanel>
          <CollapsePanel
            name={
              <TextWithIcon 
                icon={workflowIcons.outputsShortIcon}
                text={"Outputs"}
              />
            }
            style={{ marginTop: token.marginXXS }}
            ghost={false}
            collapsePanel={false}
          >
            <Form.Item
              name="outputs"
              rules={[
                { validator: (_, value) => validateFormFields(outputsForm) },
              ]}
            >
              <WorkflowCardOutputs
                editMode={props.editMode}
                outputs={selectedWorkflow.outputs}
                onRender={(form) => setOutputsForm(form)}
              />
            </Form.Item>
          </CollapsePanel>
        </Space>
      </Spin>
      {props.editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={props.onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default WorkflowCard;
