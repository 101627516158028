import { FC, useEffect, useState } from "react";
import {
    Descriptions,
    Divider,
    Form,
    Input,
    Select,
    Space,
    Spin,
    Switch,
    Typography,
    Modal as AntModal,
    theme,
    Button,
    message,
} from "antd";
import { DownloadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import YAML from 'yaml';
import { v4 as uuidv4 } from 'uuid';
import { SvgIcon } from "components/SvgIcon";
import FileSaver from "file-saver";

import { FieldLabel } from "components/FieldLabel";
import { notification } from 'utility/notification';

const { TextArea } = Input;
const { Text } = Typography;

import awsRegions from "aws-regions";

import {
  EdgeConfigProps,
  EdgeInfraType,
  EdgeInfraTypeMap,
  EdgeManageType,
  Edge,
  EdgeDeployMode,
  EdgeReachability,
  EdgeActionType,
} from "types";
  
import { useEdgeStore } from "store";

import { getEdgeShortLogo } from "utility/edge";
import { actionEdgeApi } from "api";
const EdgeConfig: FC<EdgeConfigProps> = ({
  open,
  onClose,
}) => {

  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [awsForm] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [configDownloadStatus, setonfigDownloadStatus] = useState("");
  const [infraType, setInfraType] = useState<EdgeInfraType>(EdgeInfraType.Amazon);
  const [edgeId, setEdgeId] = useState("");
  const [edgeName, setEdgeName] = useState("");
  const [region, setRegion] = useState("");
  const [deployNow, setDeployNow] = useState(false);

  const createEdge = useEdgeStore((state) =>  state.createEdge);

  useEffect(() => {
    form.setFieldValue("infraType", infraType);
  }, [infraType]);

  const generateEdgeConfig = async () => {
    try {
      const edgeId = uuidv4();
      setEdgeId(edgeId);
      const edge = {
        id: edgeId,
        name: edgeName,
        infraType: infraType,
      } as Edge;
      const jsonConfig = await actionEdgeApi(edgeId, EdgeActionType.EdgeActionTypeGenerateConfig, edge);
      const yamlConfig = new YAML.Document();
      yamlConfig.contents = jsonConfig;
      const blob = new Blob([yamlConfig.toString()], {
        type: "application/json;charset=utf-8",
      });
      FileSaver.saveAs(
        blob,
        'config.yaml'
      );
      setonfigDownloadStatus("completed");
    } catch (error) {
      console.error(`failed to download edge configuration, ${JSON.stringify(error)}`);
      setonfigDownloadStatus("error");
    }
  };

  const saveEdge = async () => {
    let config: any;
    let infraConfig: any = {};
    let hasValidationError = false;

    try {
      setLoader(true);
      try {
        config = await form.validateFields();
        switch (config.infraType) {
          case EdgeInfraType.Amazon:
            infraConfig = await awsForm.validateFields();
            break;
          case EdgeInfraType.OnPrem:
            if (configDownloadStatus == "") {
              throw "config should be downloaded";
            }
            break;
          default:
            throw "invalid infra";
        }
      } catch (error) {
        hasValidationError = true;
        throw error;
      }
      
      const edge = {
          id: edgeId,
          name: edgeName,
          displayName: config.displayName,
          description: config.description,
          manageType: EdgeManageType.Custom,
          infraType: config.infraType,
          configuration: infraConfig
      } as Edge;

      if (infraType == EdgeInfraType.Amazon) {
        edge.deployMode = EdgeDeployMode.Remote;
        edge.reachability = EdgeReachability.Direct;
      } else if (infraType == EdgeInfraType.OnPrem) {
        edge.deployMode = EdgeDeployMode.Local;
        edge.reachability = EdgeReachability.Proxy;
      }

      await createEdge(edge, deployNow);
      
      setLoader(false);
      onClose(true);
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to create edge, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoader(false);
      if (!hasValidationError) {
        onClose(true);
      }
    }
  };
  
  const getEdgeLabel = (infraType: EdgeInfraType) => {
    return (
      <Space>
        <SvgIcon component={getEdgeShortLogo(infraType)} />
        {EdgeInfraTypeMap.get(infraType)}
      </Space>
    );
  };

  const generateName = (value: string) => {
    return value.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()
  }

  return (  
    <AntModal
      title={"New Edge"}
      open={open}
      width={500}
      centered={true}
      keyboard={false}
      maskClosable={false}
      onCancel={async () => onClose(false)}
      footer={[
        <Button 
          key="cancel" 
          type="primary" 
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>,
        <Button 
          key="create" 
          type="primary" 
          onClick={() => saveEdge()}
        >
          Create
        </Button>
      ]}
    >
      <Spin spinning={loader}>
        <Form
          form={form}
          name="edgeConfigForm"
          layout="vertical"
          autoComplete="off"
        >
          <Space direction="vertical" size={0} style={{ display: "flex" }}>
            <Form.Item
              name="displayName"
              label={<FieldLabel label={"Name"}/>}
              initialValue={""}
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input onChange={(e) => setEdgeName(generateName(e.target.value))}/>
            </Form.Item>
            <Form.Item 
              name="description" 
              label={<FieldLabel label={"Description"}/>}
              initialValue={""}
            >
              <TextArea autoSize maxLength={100} />
            </Form.Item>
            <Form.Item
              name="infraType"
              label={<FieldLabel label={"Select Infrastructure"} help={"Infrastructure where to host edge"} />}
              rules={[{ required: true, message: "Infrastructure is required" }]}
            >
              <Select
                showAction={["click"]}
                value={infraType}
                defaultValue={infraType}
                onSelect={(value) =>  setInfraType(value)}
                /* TBD will add based on backend infra support options={Array.from(EdgeInfraTypeMap, (item) => ({ label: item[1], value: item[0] }))}/> */
                options={[
                  {
                    label: getEdgeLabel(EdgeInfraType.Amazon),
                    value: EdgeInfraType.Amazon,
                  },
                  {
                    label: getEdgeLabel(EdgeInfraType.OnPrem),
                    value: EdgeInfraType.OnPrem,
                  }
                ]}
              />
            </Form.Item>
            {infraType && (
              <Divider style={{ borderColor: token.colorPrimary }} >
                <Text>{EdgeInfraTypeMap.get(infraType)} Configuration</Text>
              </Divider>
            )}
          </Space>
        </Form>
        {infraType == EdgeInfraType.Amazon && (
          <Form
            form={awsForm}
            name="configuration"
            layout="vertical"
            autoComplete="off"
          >
            <Space direction="vertical" style={{ display: "flex" }}>
              <Form.Item
                name="accessKeyID"
                label={<FieldLabel label={"Access Key ID"} help={"AWS Access Key ID"} />}
                rules={[{ required: true, message: "Access Key ID is required" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="secretAccessKey"
                label={<FieldLabel label={"Secret Access Key"} help={"AWS Secret Access Key"} />}
                rules={[{ required: true, message: "Secret Access Key is required" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="region"
                label={<FieldLabel label={"Region"} help={"AWS Region where to deploy edge"} />}
                rules={[{ required: true, message: "Region is required" }]}
              >
                <Select
                  showAction={["focus", "click"]}
                  placeholder="Select Region..."
                  value={region}
                  onSelect={(value) => setRegion(value)}
                  options={Array.from(awsRegions.list(), (item) => ({
                    label: item.full_name,
                    value: item.code,
                  }))}
                />
              </Form.Item>
              {/* <Form.Item
                name="vpcCidr"
                label={<FieldLabel label={"VPC CIDR"} help={"AWS VPC CIDR"} />}
              >
                <Input />
              </Form.Item> */}
              {
                <Space direction="vertical">
                  <FieldLabel label={"Deploy Now"} help={"Edge is deployed Immediately on select or else deferred till first edge subscription"} />
                  <Switch defaultChecked={false} onChange={setDeployNow} />
                </Space>
              }
            </Space>
          </Form>
        )}
        {infraType == EdgeInfraType.OnPrem && (
          <Descriptions
              size="small"
              layout="vertical"
              colon={false}
              column={1}
          >
            <Descriptions.Item label="Pull docker image">
              <Text copyable>docker pull ghcr.io/zs-hypredge/zs-worker:latest</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Download configuration">
              <Space size={"middle"}>
                <Text 
                  copyable={{
                    icon: [
                      <DownloadOutlined key="download-icon" onClick={() => edgeName != "" ? generateEdgeConfig(): undefined}/>, 
                      configDownloadStatus == "completed" ? <CheckOutlined key="downloaded-icon"/> : <CloseOutlined key="downloaded-icon"/>,
                    ],
                    tooltips: ['Download', configDownloadStatus == "error" ? 'Failed' : 'Downloaded' ],
                  }}
                >
                  config.yaml
                </Text>
                {configDownloadStatus == "error" && <Text type="danger">Error in downloading configuration!</Text>}
                {configDownloadStatus == "" 
                  && <Text mark>{edgeName == "" ? "Name is required for configuration!" : "Config should be downloded"}</Text>}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Run docker image from path where config.yaml is located">
              <Text copyable>docker run -d -it -v $(pwd)/config.yaml:/etc/hypredge/config.yaml:ro ghcr.io/zs-hypredge/zs-worker:latest</Text>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Spin>
    </AntModal>
  );
};

export default EdgeConfig;
