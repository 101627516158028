import { FC, useEffect, useState } from "react";
import { 
  Button, 
  Spin, 
  Form, 
  Input, 
  Space, 
  Typography, 
  theme, 
  Row, 
  Select,
  Modal as AntModal
} from "antd";

import JSZip from 'jszip';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';

import {
  WorkflowNodeCardProps,
  TriggerType,
  WorkflowStepOperator,
  ScriptLanguageMap,
  ScriptLanguage,
  ArrayObject,
  WorkflowFile,
} from "types";
import { notification } from 'utility/notification';
import { useSettingsStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";
import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
  getRegEx,
} from "components/Suggestions";
import { FieldLabel } from "components/FieldLabel";
import moment from "moment";
import { OperatorIODatasForm, OperatorKeyValueForm } from "./OperatorUtils";
import { validateFormFields } from "utility";
import { FormInstance } from "antd/lib";
import { SvgIcon } from "@mui/material";
import { commonIcons, workflowIcons } from "assets/icons";
import { JsonEditor } from "components/JsonEditor";
import CollapsePanel from "components/CollapsePanel";
import { downloadWorkflowFileApi, getWorkflowFileApi, uploadWorkflowFileApi } from "api";

const { Text } = Typography;

const ScriptOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [envVarsForm, setEnvVarsForm] = useState<undefined | FormInstance>(undefined);
  const [inputsForm, setInputsForm] = useState<undefined | FormInstance>(undefined);
  const [outputsForm, setOutputsForm] = useState<undefined | FormInstance>(undefined);
  const lightMode = useSettingsStore((state) => state.lightMode);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [scriptSuggestionsTree, setScriptSuggestionsTree] = useState<[]>([]);
  //const [showJsonView, setShowJsonView] = useState<{enable: boolean, data?: any}>({enable: false});
  //const [showJsonLoadError, setShowJsonLoadError] = useState<{enable: boolean, error?: string}>({enable: false});

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
  const [selectedLanguage, setSelectedLanguage] = useState<ScriptLanguage>(ScriptLanguage.Python);
  const [script, setScript] = useState(`import os\n\ndef script_handler(input, context):\n\toutput = {}\n\toutput['status'] = True\n\treturn output`);
  const [requirements, setRequirements] = useState("");
  const [envVars, setEnvVars] = useState<Record<string, string>>({} as Record<string, string>);
  const [inputs, setInputs] = useState<Record<string, string>>({} as Record<string, string>);
  const [timeoutMinutes, setTimeoutMinutes] = useState<number>(0);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(30);
  const [scriptOutputs, setScriptOutputs] = useState<ArrayObject[]>([]);
  const [scriptFileInfo, setScriptFileInfo] = useState<WorkflowFile>();

  const syncCurrentState = async () => {
    try {
      setLoader(true);
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if (operatorStep) {
        if (operatorStep.parameters.language && operatorStep.parameters.language != "") {
          setSelectedLanguage(operatorStep.parameters.language);
          form.setFieldValue("language", operatorStep.parameters.language);
        } else {
          form.setFieldValue("language", selectedLanguage);
        }

        if (operatorStep.parameters.timeout) {
          const timeout = moment.duration(operatorStep.parameters.timeout, 'seconds');
          if (timeout) {
            setTimeoutMinutes(timeout.minutes());
            setTimeoutSeconds(timeout.seconds());
          }
        }

        const vars = operatorStep.parameters.env_vars as Record<string, string>;
        if (vars) {
          form.setFieldValue("env_vars", vars);
          setEnvVars(vars);
        }

        const input = operatorStep.parameters.input as Record<string, string>;
        if (input) {
          form.setFieldValue("input", input);
          setInputs(input);
        }

        if (operatorStep.parameters.file_id && operatorStep.parameters.file_id != "") {
          const workflowFile = await getWorkflowFileApi(selectedWorkflow.id, operatorStep.parameters.file_id);
          setScriptFileInfo(workflowFile);
          const responseData = await downloadWorkflowFileApi(selectedWorkflow.id, workflowFile.id);
          const blob = new Blob([responseData], {type: workflowFile.type});
          const zip = new JSZip();
          const zipContent = await zip.loadAsync(blob);
          for (const [fileName, file] of Object.entries(zipContent.files)) {
            if (fileName == "requirements.txt") {
              const fileData = await file.async("string"); 
              setRequirements(fileData);
              form.setFieldValue("requirements", fileData);
            }
            if (fileName == "script.py") {
              const fileData = await file.async("string"); 
              setScript(fileData);
              form.setFieldValue("script", fileData);
            }
          }
        } else {
          form.setFieldValue("script", script);
          form.setFieldValue("requirements", requirements);
        }
        
        const outputs = operatorStep.parameters.script_outputs as ArrayObject[];
        if (outputs) {
          setScriptOutputs(outputs);
          form.setFieldValue("script_outputs", outputs);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow]);

  useEffect(() => {
    buildScriptSuggestions();
  }, [referenceName]);

  const buildScriptSuggestions = () =>  {
    const suggestionsTree: any[] = [];
    suggestionsTree.push({
      title: "script",
      label: "script",
      key: "$.script",
      regex: getRegEx(),
      value: "{{$.script",
      selectable: false,
      children: [
        {
          title: referenceName,
          label: referenceName,
          key: `$.script.${referenceName}`,
          regex: getRegEx("script"),
          value: `{{$.script.${referenceName}`,
          selectable: false,
          children: [
            {
              title: "outputs",
              label: "outputs",
              key: `$.script.${referenceName}.outputs`,
              regex: getRegEx("script", referenceName),
              value: `{{$.script.${referenceName}.outputs`,
              isLeaf: true,
            }
          ]
        }
      ]
    });
    setScriptSuggestionsTree(suggestionsTree as []);
  }

  const generateSHA256Hash = async (blob: Blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
    return hashHex;
  }

  const OnFinish = async (values: any) => {
    let hasValidationError = false;
    try {
      setLoader(true);
      try {
        await form.validateFields();
      } catch (error) {
        hasValidationError = true;
        throw error;
      }

      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      operatorStep.isConfigured = true;
      operatorStep.name = values.name;
      operatorStep.referenceName = referenceName;
      setReferenceName(operatorStep.referenceName);

      operatorStep.parameters.language = values.language;
      operatorStep.parameters.timeout = timeoutMinutes * 60 + timeoutSeconds;
      operatorStep.parameters.env_vars = values.env_vars;
      operatorStep.parameters.input = values.input;
      
      operatorStep.parameters.script_outputs = values.script_outputs;

      const zip = new JSZip();
      //create with fixed date to get constant sha256 if no changes in content
      const fixedDate = new Date("2000-01-01");
      zip.file("script.py", values.script, { date: fixedDate });
      zip.file("requirements.txt", values.requirements ? values.requirements : "", { date: fixedDate });
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const sha256 = await generateSHA256Hash(zipBlob);

      if (scriptFileInfo && scriptFileInfo.sha256 == sha256 && scriptFileInfo.id != "") {
        operatorStep.parameters.file_id = scriptFileInfo.id;
      } else {
        const formData = new FormData();
        formData.append('file', zipBlob, 'script_' + id + '.zip');
        const workflowFile = await uploadWorkflowFileApi(selectedWorkflow.id, formData);
        operatorStep.parameters.file_id = workflowFile.id;
      }

      operatorStep.isAuditRequired = false;
      selectedWorkflow.steps[id] = operatorStep;
     
      await updateWorkflow(selectedWorkflow);

      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      if (!hasValidationError) {
        notification.error({
          message: `Updating workflow ${selectedWorkflow.name} failed`,
          duration: 6,
        });
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <Form
      form={form}
      name="aiCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Form.Item
          name="name"
          label={<FieldLabel label={"Name"} help={"Name of action"} />}
          initialValue={selectedWorkflow.steps[id]?.name}
          validateTrigger="onSubmit"
          rules={[
            { required: true, message: "Name is required!" },
          ]}
          extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
        >
          <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
        </Form.Item>
        <CollapsePanel
          name={
            <Space size={token.marginXXS}>
              <SvgIcon component={workflowIcons.parametersShortIcon} />
              <Text>Parameters</Text>
            </Space>
          }
          ghost={false} 
          collapsePanel={false}
        >
          <Form.Item
            name="language"
            label={<FieldLabel label={"Language"}/>}
            validateTrigger="onSubmit"
            rules={[
              { required: true, message: "Language should be selected!" },
            ]}
          >
            <Select
              disabled={!editMode}
              showAction={["focus", "click"]}
              onSelect={setSelectedLanguage}
              options={Array.from(ScriptLanguageMap, ([k, v]) => ({
                label: v,
                value: k,
              }))}
            />
          </Form.Item>
          <Form.Item 
            name="timeout"
            required
            label={<FieldLabel label={"Timeout"} help={"Max time to wait for script execution"} />}
            validateTrigger="onSubmit"
          >
            {
              <Space style={{ display: "flex" }}>
                <Select
                  disabled={!editMode}
                  style={{ width: 60 }}
                  value={timeoutMinutes}
                  onSelect={(mints) => {
                    if(mints == 15) {
                      setTimeoutSeconds(0);
                    }
                    setTimeoutMinutes(mints);
                  }}
                  options={Array.from({ length: 16 }, (_, i) => ({ value: i }))}
                />
                {"Minutes"}
                <div/>
                <Select
                  disabled={!editMode || timeoutMinutes == 15}
                  style={{ width: 60 }}
                  value={timeoutSeconds}
                  onSelect={setTimeoutSeconds}
                  options={Array.from({ length: 60 }, (_, i) => ({ value: i }))}
                />
                {"Seconds"}
              </Space>
            }
          </Form.Item>
          <Form.Item
            name={'env_vars'}
            label={<FieldLabel label={"Environment Variables"}/>}
            validateTrigger="onSubmit"
            rules={[
              { validator: (_, value) =>  validateFormFields(envVarsForm) },
            ]}
          >
            <OperatorKeyValueForm
              editMode={editMode} 
              name={"Variable"} 
              keyValues={envVars} 
              suggestionsTree={parameterSuggestionsTree}
              onRender={form => setEnvVarsForm(form)}
            />
          </Form.Item>
          <Form.Item
            name={"input"}
            label={<FieldLabel label={"Inputs"}/>}
            validateTrigger="onSubmit"
            rules={[
              { validator: (_, value) =>  validateFormFields(inputsForm) },
            ]}
          >
            <OperatorKeyValueForm
              editMode={editMode} 
              name={"Input"} 
              keyValues={inputs} 
              suggestionsTree={parameterSuggestionsTree}
              onRender={form => setInputsForm(form)}
            />
          </Form.Item>
          <Form.Item
            name="script"
            label={<FieldLabel label={ScriptLanguageMap.get(selectedLanguage) + " Script (script.py)"} help={"Script must have a handler with name script_handler(input, context)"}/>}
            validateTrigger="onSubmit"
            rules={[
              { required: true, message: "Script is required!" },
            ]}
          >
            <CodeMirror 
              readOnly={!editMode}
              value={script} 
              theme={lightMode ? "light": "dark"}
              height="200px" 
              extensions={[python()]} 
              onChange={(script) => setScript(script)}
            />
          </Form.Item>
          {selectedLanguage == ScriptLanguage.Python
            &&
            <Form.Item
              name="requirements"
              label={<FieldLabel label={"Requirements (requirements.txt)"} help={"Optional, Add if any additional package requirements for python script"}/>}
              validateTrigger="onSubmit"
            >
              <CodeMirror
                readOnly={!editMode} 
                value={requirements} 
                theme={lightMode ? "light": "dark"}
                height="120px" 
                placeholder={`e.g. requests==2.32.3`}
                onChange={(reqs) => setRequirements(reqs)} 
              />
            </Form.Item>
          }
          <Form.Item
            name={"script_outputs"}
            label={<FieldLabel label={"Outputs"} help={"Script operator output definitions"} />}
            validateTrigger="onSubmit"
            rules={[
              { validator: (_, value) =>  validateFormFields(outputsForm) },
            ]}
          >
            <OperatorIODatasForm 
              editMode={editMode} 
              isOutput={true}
              iodatas={scriptOutputs} 
              suggestionsTree={scriptSuggestionsTree}
              onRender={form => setOutputsForm(form)}
            />
          </Form.Item>
        </CollapsePanel>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
      {/* {showJsonView.enable && 
        <JsonEditor
          open={showJsonView.enable}
          width={800}
          title={"Script Outputs"}
          editMode={false}
          data={showJsonView.data}
          icon={commonIcons.httpIcon}
          onClose={() => setShowJsonView({enable: false})}
        />
      }
      {showJsonLoadError.enable && 
        <AntModal
          title="Error"
          onCancel={() => setShowJsonLoadError({enable: false})}
          open={showJsonLoadError.enable}
          footer={[
            <Button 
              key="ok" 
              type="primary" 
              onClick={() => setShowJsonLoadError({enable: false})}
            >
              OK
            </Button>
          ]}
        >
          {showJsonLoadError?.error}
        </AntModal>
      } */}
    </Form>
  );
};

export default ScriptOperatorCard;
