import { FC, useEffect, useMemo, useRef, useState } from "react";
import { AutoComplete, Modal, Input, theme } from "antd";
import type { InputRef } from 'antd';

import ReactQuill from "react-quill";
import Quill from "quill"
import "react-quill/dist/quill.snow.css";

import { SuggestionsInputProps, TextType } from "types";
import { getRegEx } from "./tree";
import { DefaultOptionType } from "antd/es/select";
import { getSuggestionLabelWithIcon } from "./icons";
import { TextWithIcon } from "components/TextWithIcon";
import { workflowIcons } from "assets/icons";
import SearchableTree from "components/SearchableTree";

export const SuggestionsInput: FC<SuggestionsInputProps> = ({
  editMode,
  name,
  suggestionsTree,
  allowClear,
  textType,
  setValue,
  onChange,
  ...restProps
}) => {
  const { token } = theme.useToken();
  const TreeLabel = "Select from tree...";
  const [options, setOptions] = useState<DefaultOptionType[] | undefined>([]);
  const [showTree, setShowTree] = useState<boolean>(false);
  const [treeValuePrefix, setTreeValuePrefix] = useState<string>("");
  const [treeValuePosition, setTreeValuePosition] = useState<number>(0);
  const inputRef = useRef<InputRef>(null);
  const [reactQuillRef, setReactQuillRef] = useState<ReactQuill|undefined>(undefined);
  const [editor, setEditor] = useState<Quill|undefined>(undefined);
  const [unprivilegedEditor, setUnprivilegedEditor] = useState<any|undefined>(undefined);

  const suggestions = useMemo(() => {
    const suggestionList: Map<string, DefaultOptionType[]> = new Map<string, DefaultOptionType[]>([]);
    const buildSuggestionsList = (data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { regex, label, value } = node;
        let keys = suggestionList.get(regex)
        if (!keys) {
          keys = [];
        }
        keys.push({label: label, value: value});
        suggestionList.set(regex, keys);
        if (node.children) {
          buildSuggestionsList(node.children);
        }
      }
    };
    suggestionsTree && buildSuggestionsList(suggestionsTree);
    return suggestionList;
  }, [suggestionsTree]);

  const buildFirstLevelSuggestions = (treeValue?: string) => {
    if (suggestions) {
      const opts = suggestions.get(getRegEx())?.map((str) => ({
        label: getSuggestionLabelWithIcon(str.label),
        value: treeValue ? treeValue.substring(0, treeValue.length-2) + str.value : str.value,
      }));
      if (suggestionsTree) {
        opts?.push({label: TreeLabel, value:  treeValue ? treeValue : ""})
      }
      setOptions(opts);
    }
  };

  /* useEffect(() => {
    buildFirstLevelSuggestions();
  }, [suggestions]); */

  const showSuggestions = (value: string) => {
    if (value.endsWith("$.") || value.endsWith("$.\n")) {
      buildFirstLevelSuggestions(value);
    } else {
      let opts: DefaultOptionType[] = [];
      suggestions?.forEach((keys, regex) => {
        let re = new RegExp(regex);
        if (textType == TextType.Rich) {
          regex = regex.replace(".$", ".\n$")
          re = new RegExp(regex);
        }
        if (value.match(re)) {
          opts = keys.map((key) => ({
            label: getSuggestionLabelWithIcon(key.label),
            value: value.replace(re, key.value as string),
          }));
        }
      });
      setOptions(opts);
    }
  };
  
  const onSelect = (value: string) => {
    if (value.endsWith("}}") || value.endsWith("}}\n")){
      setOptions([]);
    }
    if (value.endsWith("$.") || value.endsWith("$.\n")) {
      setShowTree(true);
      if (textType == TextType.Rich) {
        const range = unprivilegedEditor?.getSelection();
        const position = range ? range.index : 0;
        setTreeValuePosition(position);
        onChange?.(unprivilegedEditor?.getHTML());
      } else {
        setTreeValuePrefix(value.substring(0, value.length-2));
      }
    } else {
      if (textType == TextType.Rich) {
        const range = unprivilegedEditor?.getSelection();
        const position = range ? range.index : 0;
        const text = editor?.getText();
        if (text && text.endsWith("$.\n")) {
          value = value.substring(position-1);
          position >= 2 && editor?.deleteText(position-2, 2);
          editor?.insertText(position-2, value);
        } else {
          value = value.substring(position);
          editor?.insertText(position, value);
        }
        onChange?.(unprivilegedEditor?.getHTML());
      }
      setValue && setValue(value);
    }
  };

  const onTreeNodeSelect = (node: any) => {
    if (textType == TextType.Rich) {
      treeValuePosition >= 2 && editor?.deleteText(treeValuePosition-2, 2);
      editor?.insertText(treeValuePosition-2, node.value);
      editor?.setSelection(treeValuePosition-2+node.value.length);
      onChange?.(unprivilegedEditor?.getHTML() as string);
    } else {
      onChange?.(treeValuePrefix + node.value);
      setTreeValuePrefix("");
      inputRef?.current?.focus({ cursor: 'end' });
    }
    setShowTree(false);
  };

  useEffect(() => {
    const e = reactQuillRef?.getEditor() as Quill;
    const unprivilegedEditor = reactQuillRef?.makeUnprivilegedEditor(e);
    setEditor(e);
    setUnprivilegedEditor(unprivilegedEditor);
  }, [reactQuillRef]);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{'color': [] }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
    ],
    clipboard: {matchVisual: false},
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'color',
    'list', 'bullet', 'indent',
    'link'
  ];

  return (
    <>
      <AutoComplete
        key={name}
        disabled={!editMode}
        options={options}
        onSearch={showSuggestions}
        onSelect={onSelect}
        onChange={(value) => onChange?.(value)}
        style={{ "display": "inline"}}
        {...restProps}
      >
        {textType == TextType.Rich ?
          <div>
            <ReactQuill
              ref={(el) => el && setReactQuillRef(el)}
              key={name}
              theme="snow"
              modules={modules}
              formats={formats}
              readOnly={!editMode}
              placeholder={`Enter ${name} or $. for previous steps outputs suggestions`}
              onChange={(value) =>  {
                const text = unprivilegedEditor?.getText() as string;
                text && showSuggestions(text);
                onChange?.(value);
              }}
              {...restProps}
            />
          </div>
          :
          textType == TextType.Text ?
            <Input.TextArea 
              ref={inputRef} 
              placeholder={`Enter ${name} or $. for previous steps outputs suggestions`}
              showCount
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
            :
            <Input
              ref={inputRef}
              placeholder={`Enter ${name} or $. for suggestions`}
              allowClear={allowClear?allowClear:undefined}
            />
        }
      </AutoComplete>
      <Modal 
        title={
          <TextWithIcon 
            icon={workflowIcons.parametersShortIcon}
            iconSize={18}
            text={"Tree"}
          />
        }
        open={showTree} 
        onCancel={() => setShowTree(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <SearchableTree
          placeholder="search parameters"
          treeData={suggestionsTree ? suggestionsTree : []}
          onSelect={onTreeNodeSelect}
        />
      </Modal>
    </>
  );
};