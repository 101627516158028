import {
  Avatar,
  Button,
  theme,
  Checkbox,
  Descriptions,
  Flex,
  List,
  notification,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import VirtualList from "rc-virtual-list";

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "components/Modal";
import { useCaseStore, useOrganizationStore, useUserInfoStore } from "store";
import {
  Case,
  CaseCategoriesMap,
  CasePriorityMap,
  CaseSeverityMap,
  CaseStatusMap,
} from "types";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/lib/table";
import { HLink } from "components/Link";
import { TextWithIcon } from "components/TextWithIcon";
import { caseMgmtIcons } from "assets/icons";
import SearchInput from "components/SearchInput";
import { getCasesApi } from "api";
import { getCasePriority, getCaseSeverity, getCaseStatus } from "./utils";
import { getTagColor } from "utility";
import { FiltersAndSorts } from "./FiltersAndSorters";

const { Text } = Typography;

export interface CaseMgmtLinksProps {
  caseId: string;
}

export const CaseMgmtLinks: FC<CaseMgmtLinksProps> = ({ caseId }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [casesLoader, setCasesLoader] = useState(false);
  const [showCasesModal, setShowCasesModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [currentCases, setCurrentCases] = useState<Case[]>([]);
  const [linkedCases, setLinkedCases] = useState<Case[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [unlinkFromParentModal, setUnlinkFromParentModal] = useState(false);
  const defaultPageSize = 12;
  const containerHeight = 480;

  const [caseFilters, setCaseFilters] = useState<Record<string, string[]>>({});

  const { context, mspEnabled, tags } = useOrganizationStore((state) => ({
    context: state.context,
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const loadCaseLinks = async (refresh?: boolean) => {
    try {
      setLoader(true);
      await getCase(caseId, refresh);
    } catch (error) {
      console.log(error);
      //On failure of loading case, it could be a unauthorized access, switch it to cases home page
      navigate(`/cases`);
    } finally {
      setLoader(false);
    }
  };

  const { getCase, parentLink, links, linkCase, unlinkCase } = useCaseStore(
    (state) => ({
      getCase: state.getCase,
      parentLink: state.selectedCaseParent,
      links: state.selectedCaseLinks,
      linkCase: state.linkCase,
      unlinkCase: state.unlinkCase,
    })
  );

  useEffect(() => {
    caseId && caseId != "" && loadCaseLinks();
  }, [caseId, context]);

  //Fetch user info for all users linked to case
  useEffect(() => {
    try {
      const userIds = [] as string[];
      if (parentLink) {
        parentLink.assigneeID &&
          parentLink.assigneeID != "" &&
          userIds.push(parentLink.assigneeID);
        parentLink.userID &&
          parentLink.userID != "" &&
          userIds.push(parentLink.userID);
      }
      links?.map((link) => {
        link.assigneeID &&
          link.assigneeID != "" &&
          userIds.push(link.assigneeID);
        link.userID && link.userID != "" && userIds.push(link.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [links, parentLink]);

  const loadCases = async (append?: boolean) => {
    try {
      setCasesLoader(true);
      const filters = [];
      Object.entries(caseFilters).map(([name, values]) => {
        if (values.length > 0) {
          const orFilters = [] as string[];
          values.map((value) => {
            orFilters.push("'" + value + "'");
          });
          filters.push("equals(" + name + "," + orFilters.join() + ")");
        }
      });
      filters.push("contains(name,'" + searchText + "')");
      let [cases] = await getCasesApi(currentPage, defaultPageSize, filters);
      if (append) {
        cases = [...currentCases, ...cases];
      }
      cases = cases.filter((c) => c.id != caseId);
      if (parentLink) {
        cases = cases.filter((c) => c.id != parentLink?.id);
      }
      setCurrentCases(cases);
    } catch (error) {
      console.log(error);
    } finally {
      setCasesLoader(false);
    }
  };

  useEffect(() => {
    showCasesModal && loadCases(true);
  }, [currentPage]);

  useEffect(() => {
    showCasesModal && loadCases();
  }, [searchText, caseFilters, showCasesModal]);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          containerHeight
      ) <= 1
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const OnLinkCases = async () => {
    try {
      setLoader(true);
      await Promise.all(
        linkedCases.map(async (c) => {
          await linkCase(c.id);
          notification.success({
            message: `Case ${c.number} linked successfully`,
            duration: 6,
          });
        })
      );
      setLinkedCases([]);
      setSearchText("");
      loadCaseLinks();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while linking cases...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnUnlinkCases = async (items: Case[]) => {
    try {
      setLoader(true);
      const unlinkCases = items as Case[];
      await Promise.all(
        unlinkCases.map(async (c) => {
          await unlinkCase(c.id);
          notification.success({
            message: `Case ${c.number} unlinked successfully`,
            duration: 6,
          });
        })
      );
      loadCaseLinks();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while unlinking cases...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnUnlinkFromParentCase = async () => {
    try {
      setLoader(true);
      await unlinkCase(caseId);
      notification.success({
        message: `Case unlinked from parent case successfully`,
        duration: 6,
      });
      loadCaseLinks();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while unlinking from parent case...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const getTag = (tenantId: string) => {
    const tag = tags?.find((tag) => tag.id == `${tenantId}_tag`);
    return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>;
  };

  const columns: ColumnsType<Case> = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (_, c) => (
        <HLink
          onClick={() => navigate("/cases/" + c.id)}
          text={c.number}
        ></HLink>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <Space>
          <Text>{name}</Text>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      responsive: ["xxl"],
      render: (description) => <Text>{description}</Text>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => {
        return <Text>{CaseCategoriesMap.get(category)}</Text>;
      },
    },
    {
      title: "Tag",
      dataIndex: "tenantID",
      key: "tenantID",
      responsive: ["xxl"],
      hidden: !mspEnabled,
      render: (tenantID: string) => getTag(tenantID),
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (severity) => {
        return getCaseSeverity(severity);
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        return getCasePriority(priority);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return getCaseStatus(status);
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeID",
      key: "assigneeID",
      render: (assigneeID: string) => (
        <Space>
          {userInfos[assigneeID]?.logoUrl && (
            <Avatar src={userInfos[assigneeID]?.logoUrl} />
          )}
          <HLink
            href={"mailto:" + userInfos[assigneeID]?.email}
            text={`${userInfos[assigneeID]?.firstName} ${userInfos[assigneeID]?.lastName}`}
          />
        </Space>
      ),
    },
    {
      title: "Reporter",
      dataIndex: "userID",
      key: "userID",
      responsive: ["xxl"],
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink
            href={"mailto:" + userInfos[userID]?.email}
            text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`}
          />
        </Space>
      ),
    },
  ];

  const getTagsMap = () => {
    const tagsMap: Map<string, any> = new Map<string, any>();
    tags.map((tag) => {
      tagsMap.set(tag.id, <Tag color={getTagColor(tag.id)}>{tag.value}</Tag>);
    });
    return tagsMap;
  };

  return (
    <Spin spinning={loader}>
      {parentLink && (
        <Descriptions
          bordered
          column={6}
          title={"Linked To"}
          extra={
            <Button
              type="primary"
              onClick={() => setUnlinkFromParentModal(true)}
            >
              Unlink
            </Button>
          }
        >
          <Descriptions.Item label="Number" span={2}>
            <HLink
              underline
              onClick={() => navigate("/cases/" + parentLink.id)}
              text={parentLink.number}
            ></HLink>
          </Descriptions.Item>
          <Descriptions.Item label="Name" span={4}>
            {parentLink.name}
          </Descriptions.Item>
          <Descriptions.Item label="Category" span={2}>
            {CaseCategoriesMap.get(parentLink.category)}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={2}>
            {getCaseStatus(parentLink.status)}
          </Descriptions.Item>
          <Descriptions.Item label="Severity" span={1}>
            {getCaseSeverity(parentLink.severity)}
          </Descriptions.Item>
          <Descriptions.Item label="Priority" span={1}>
            {getCasePriority(parentLink.priority)}
          </Descriptions.Item>
          {mspEnabled && (
            <Descriptions.Item label="Tag" span={2}>
              {getTag(parentLink.tenantID)}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Assignee" span={mspEnabled ? 2 : 3}>
            <Space>
              {userInfos[parentLink.assigneeID]?.logoUrl && (
                <Avatar src={userInfos[parentLink.assigneeID]?.logoUrl} />
              )}
              <HLink
                href={"mailto:" + userInfos[parentLink.assigneeID]?.email}
                text={`${userInfos[parentLink.assigneeID]?.firstName} ${
                  userInfos[parentLink.assigneeID]?.lastName
                }`}
              />
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Reporter" span={mspEnabled ? 2 : 3}>
            <Space>
              {userInfos[parentLink.userID]?.logoUrl && (
                <Avatar src={userInfos[parentLink.userID]?.logoUrl} />
              )}
              <HLink
                href={"mailto:" + userInfos[parentLink.userID]?.email}
                text={`${userInfos[parentLink.userID]?.firstName} ${
                  userInfos[parentLink.userID]?.lastName
                }`}
              />
            </Space>
          </Descriptions.Item>
        </Descriptions>
      )}
      <EntitiesDisplay
        header={"Links"}
        dataSource={links as any[]}
        columns={columns}
        //pageNumber={attachmentPage.number}
        //pageSize={attachmentPage.size}
        total={links?.length ? links.length : 0}
        //onChange={onChange}
        /* onSearch={(text: string) => {
          setCurrentPage();
          setAttachmentSearchTextMap(new Map<string, string>().set("name", text));
        }} */
        //searchPlaceholder={"search cases by name"}
        //searchText={attachmentSearchTextMap?.get("name")}
        //onPageChange={(pageNumber: number, pageSize: number)  => setCurrentPage(pageNumber, pageSize)}
        actions={[
          {
            key: "link",
            label: "Add Link",
            enable: () => true,
            hidden: !context?.endsWith("_tag"),
            onClick: () => setShowCasesModal(true),
          },
          {
            key: "unlink",
            label: "Unlink",
            enable: (itemIds) => {
              if (itemIds && itemIds.length) {
                return itemIds.every((id) =>
                  links?.find(
                    (c) => c.id == id
                  )
                );
              } else {
                return false;
              }
            },
            hidden: !context?.endsWith("_tag"),
            showWarn: true,
            onClick: (value) => {
              if (value) {
                const itemIds = value as string[];
                const items = links?.filter((c) =>
                  itemIds.find((id) => id == c.id)
                );
                if (items) {
                  OnUnlinkCases(items);
                }
              }
            },
          },
        ]}
      />
      {showCasesModal && (
        <Modal
          title={
            <TextWithIcon
              icon={caseMgmtIcons.caseMgmtCasesIcon}
              iconSize={18}
              text={"Cases"}
            />
          }
          open={showCasesModal}
          footerName={"Link"}
          disableSubmit={linkedCases?.length == 0}
          onClose={() => {
            setShowCasesModal(false);
            setLinkedCases([]);
            setSearchText("");
          }}
          onSubmit={() => {
            setShowCasesModal(false);
            OnLinkCases();
          }}
        >
          <Space direction="vertical" style={{ display: "flex" }}>
            <Space>
              <div style={{ width: "350px" }}>
                <SearchInput
                  placeholder="search cases by name"
                  onFilter={(e) => {
                    setSearchText(e.target.value);
                    setCurrentPage(1);
                    setCurrentCases([]);
                  }}
                />
              </div>
              <FiltersAndSorts
                title={"Filters"}
                currentFilters={caseFilters}
                filters={[
                  {
                    name: "status",
                    displayName: "Status",
                    options: CaseStatusMap,
                  },
                  {
                    name: "priority",
                    displayName: "Priority",
                    options: CasePriorityMap,
                  },
                  {
                    name: "severity",
                    displayName: "Severity",
                    options: CaseSeverityMap,
                  },
                  {
                    name: "category",
                    displayName: "Category",
                    options: CaseCategoriesMap,
                  },
                  {
                    name: "tagID",
                    displayName: "Tags",
                    single: true,
                    hidden: !mspEnabled,
                    options: getTagsMap(),
                  },
                ]}
                onApply={(filters) => {
                  setCurrentPage(1);
                  setCurrentCases([]);
                  filters && setCaseFilters(filters);
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  setLinkedCases([]);
                  setSearchText("");
                }}
                style={{ float: "right" }}
                disabled={linkedCases?.length == 0}
              >
                Reset
              </Button>
            </Space>
            <List loading={casesLoader}>
              {currentCases.length > 0 && (
                <VirtualList
                  data={currentCases}
                  height={containerHeight}
                  itemKey="id"
                  onScroll={onScroll}
                >
                  {(currentCase: Case) => (
                    <List.Item key={currentCase.id}>
                      <List.Item.Meta
                        avatar={
                          <Checkbox
                            checked={
                              linkedCases.findIndex(
                                (c) => c.id == currentCase.id
                              ) != -1 ||
                              links.findIndex((c) => c.id == currentCase.id) !=
                                -1
                            }
                            disabled={
                              links.findIndex((c) => c.id == currentCase.id) !=
                              -1
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setLinkedCases([...linkedCases, currentCase]);
                              } else {
                                setLinkedCases(
                                  linkedCases.filter(
                                    (c) => c.id !== currentCase.id
                                  )
                                );
                              }
                            }}
                          />
                        }
                        title={
                          <Flex justify="space-between">
                            {currentCase.number}
                            {mspEnabled && getTag(currentCase.tenantID)}
                          </Flex>
                        }
                        description={currentCase.name}
                      />
                    </List.Item>
                  )}
                </VirtualList>
              )}
            </List>
          </Space>
        </Modal>
      )}
      {parentLink && unlinkFromParentModal && (
        <Modal
          title="Unlink From Parent"
          onClose={() => setUnlinkFromParentModal(false)}
          open={unlinkFromParentModal}
          onSubmit={() => {
            setUnlinkFromParentModal(false);
            OnUnlinkFromParentCase();
          }}
        >
          {`Are you sure you want to unlink from parent case "${parentLink?.name}" ?`}
        </Modal>
      )}
    </Spin>
  );
};
