import { Spin, Typography, theme, Space, Avatar, Divider, Tag } from "antd";

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useOrganizationStore,
  useSettingsStore,
  useUserInfoStore,
  useCaseStore,
} from "store";
import { notification } from "utility/notification";

import {
  Case,
  CaseActorMap,
  CaseCategoriesMap,
  CasePriorityMap,
  CaseSeverityMap,
  CaseSLAStatusMap,
  CaseStatus,
  CaseStatusMap,
  CaseTabType,
  dateTimeFormatOptions,
  PolicyCategoryType,
} from "types";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/lib/table";
import { DataType } from "@textea/json-viewer";
import { HLink } from "components/Link";
import { capitalizeFirstWord, getTagColor } from "utility";
import CaseCreateModal from "./CaseCreate";
import { getCasePriority, getCaseSeverity, getCaseSLAStatus, getCaseStatus } from "./utils";
import { cloneCaseApi } from "api";
import { usePolicyStore } from "store/policy";
import { VideoPlayer } from "components/VideoPlayer";
import { QuickLinkComponent, QuickLinkMetaMap } from "types/quicklinks";

const { Title, Text } = Typography;

export const CaseMgmtCases: FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const [loader, setLoader] = useState(false);
  const [createCaseModal, setCreateCaseModal] = useState(false);

  const context = useOrganizationStore((state) => state.context);

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const { mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const getPolicies = usePolicyStore((state) => state.getPolicies);

  const {
    cases,
    totalCasesCount,
    casePage,
    setCurrentPage,
    caseFilter,
    setCaseFilter,
    caseSorter,
    setCaseSorter,
    getCases,
    clearCases,
    createCase,
    deleteCase,
    caseSearchTextMap,
    setCaseSearchTextMap,
    setActiveTab,
  } = useCaseStore((state) => ({
    cases: state.cases,
    totalCasesCount: state.totalCount,
    casePage: state.pageInfo,
    setCurrentPage: state.setCurrentPage,
    caseFilter: state.filter,
    setCaseFilter: state.setFilter,
    caseSorter: state.sorter,
    setCaseSorter: state.setSorter,
    getCases: state.getCases,
    clearCases: state.clearCases,
    createCase: state.createCase,
    deleteCase: state.deleteCase,
    caseSearchTextMap: state.searchTextMap,
    setCaseSearchTextMap: state.setSearchTextMap,
    setActiveTab: state.setActiveTab,
  }));

  const loadCases = async () => {
    try {
      setLoader(true);
      await getCases();
      await getPolicies(PolicyCategoryType.CaseMgmtGeneral);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      clearCases();
    };
  }, []);

  useEffect(() => {
    clearCases();
    loadCases();
  }, [context]);

  useEffect(() => {
    loadCases();
  }, [caseFilter, caseSorter, caseSearchTextMap, casePage]);

  //Fetch user info for all users linked to cases
  useEffect(() => {
    try {
      const userIds = [] as string[];
      cases.map((c) => {
        c.assigneeID && c.assigneeID != "" && userIds.push(c.assigneeID);
        c.userID && c.userID != "" && userIds.push(c.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [cases]);

  const OnCreateCase = async (values: any) => {
    try {
      setLoader(true);
      const caseIn = {
        name: values.name,
        description: values.description,
        status: CaseStatus.New,
        category: values.category,
        priority: values.priority,
        severity: values.severity,
        assigneeID: values.assigneeID,
        tags: values.tags,
      } as Case;
      const caseOut = await createCase(caseIn);
      notification.success({
        message: `Case "${caseIn.name}" created successfully`,
        duration: 6,
      });
      const caseId = caseOut.id;
      navigate(`/cases/${caseId}`);
      setActiveTab(CaseTabType.Notes);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while creating case...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnDeleteCases = async (items: any[]) => {
    try {
      setLoader(true);
      const cases = items as Case[];
      await Promise.all(
        cases.map(async (c) => {
          await deleteCase(c.id);
          notification.success({
            message: `Case ${c.name} deleted successfully`,
            duration: 6,
          });
        })
      );

      await getCases();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting cases...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnCloneCase = async (
    item: any,
    selectedTags?: string[],
    tagsOnly?: boolean
  ) => {
    try {
      setLoader(true);
      const c = item as Case;
      c.cloneTags = selectedTags;
      await cloneCaseApi(c.id, c, tagsOnly);
      notification.success({
        message: `Case ${c.name} cloned successfully`,
        duration: 6,
      });
      loadCases();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while cloning case...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns: ColumnsType<Case> = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (_, c) => (
        <HLink
          onClick={() => navigate("/cases/" + c.id)}
          text={c.number}
        ></HLink>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: true,
      sortDirections: ["ascend", "descend", null],
      render: (name) => {
        return <Text>{name}</Text>;
      },
    },
    {
      title: "SLA",
      dataIndex: "slaStatus",
      key: "slaStatus",
      filters: Array.from(CaseSLAStatusMap, ([status, value]) => ({
        text: value,
        value: status,
      })),
      filteredValue: caseFilter["slaStatus"],
      render: (slaStatus) => {
        return <Text>{getCaseSLAStatus(slaStatus)}</Text>;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: Array.from(CaseCategoriesMap, ([category, value]) => ({
        text: value,
        value: category,
      })),
      filterSearch: true,
      filteredValue: caseFilter["category"],
      render: (category) => {
        return <Text>{CaseCategoriesMap.get(category)}</Text>;
      },
    },
    {
      title: "Tag",
      dataIndex: "tenantID",
      key: "tenantID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({
        text: tag.value,
        value: tag.id.split("_")[0],
      })),
      filteredValue: caseFilter["tenantID"],
      render: (tenantID: string) => {
        const tag = tags?.find((tag) => tag.id == `${tenantID}_tag`);
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>;
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      filters: Array.from(CaseSeverityMap, ([tag, value]) => ({
        text: value,
        value: tag,
      })),
      filterSearch: true,
      filteredValue: caseFilter["severity"],
      render: (severity) => {
        return getCaseSeverity(severity);
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      filters: Array.from(CasePriorityMap, ([tag, value]) => ({
        text: value,
        value: tag,
      })),
      filterSearch: true,
      filteredValue: caseFilter["priority"],
      render: (priority) => {
        return getCasePriority(priority);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: Array.from(CaseStatusMap, ([tag, value]) => ({
        text: value,
        value: tag,
      })),
      filterSearch: true,
      filteredValue: caseFilter["status"],
      render: (status) => {
        return getCaseStatus(status);
      },
    },
    {
      title: "#Links",
      dataIndex: "links",
      key: "links",
      render: (_, c) => {
        return (
          <>
            {c.links?.length > 0 ? (
              <HLink
                alwaysLinkColor
                underline
                onClick={() => {
                  setActiveTab(CaseTabType.Links);
                  navigate("/cases/" + c.id);
                }}
                text={c.links?.length}
              />
            ) : (
              <Text>{c.links?.length}</Text>
            )}
          </>
        );
      },
    },
    {
      title: "Actor",
      dataIndex: "actor",
      key: "actor",
      responsive: ["xxl"],
      filters: Array.from(CaseActorMap, ([tag, value]) => ({
        text: value,
        value: tag,
      })),
      filterSearch: true,
      filteredValue: caseFilter["actor"],
      render: (status) => {
        return <Text>{capitalizeFirstWord(status)}</Text>;
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeID",
      key: "assigneeID",
      responsive: ["xxl"],
      render: (assigneeID: string) => (
        <Space>
          {userInfos[assigneeID]?.logoUrl && (
            <Avatar src={userInfos[assigneeID]?.logoUrl} />
          )}
          <HLink
            href={"mailto:" + userInfos[assigneeID]?.email}
            text={`${userInfos[assigneeID]?.firstName} ${userInfos[assigneeID]?.lastName}`}
          />
        </Space>
      ),
    },
    {
      title: "Reporter",
      dataIndex: "userID",
      key: "userID",
      responsive: ["xxl"],
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink
            href={"mailto:" + userInfos[userID]?.email}
            text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`}
          />
        </Space>
      ),
    },
    {
      title: "Reported",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["xxl"],
      sorter: true,
      sortDirections: ["descend", "ascend", null],
      render: (createdAt: string) =>
        new Date(createdAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "Closed",
      dataIndex: "closedAt",
      key: "closedAt",
      responsive: ["xxl"],
      sorter: true,
      sortDirections: ["descend", "ascend", null],
      render: (_, c) => {
        return (c.status == CaseStatus.Closed) && c.closedAt ? (
          new Date(c.closedAt).toLocaleTimeString(
            undefined,
            dateTimeFormatOptions
          )
        ) : (
          <Text>N/A</Text>
        );
      },
    },
    {
      title: "Labels",
      dataIndex: "tags",
      key: "tags",
      responsive: ["xxl"],
      //filterSearch: true,
      //filters: Array.from(AppTagsMap, ([tag, value]) => ({ text: value, value: tag })),
      //filteredValue: appFilter["tags"],
      render: (tags: string[]) => {
        return (
          <Space size={1} style={{ display: "flex" }}>
            {tags?.map((tag) => (
              <Tag key={tag} color={getTagColor(tag)}>
                {tag}
              </Tag>
            ))}
          </Space>
        );
      },
    },
  ];

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setCaseSorter(sorter);
    setCaseFilter(filters);
    setCurrentPage();
  };

  return (
    <div style={{ margin: token.margin, width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "60px",
          paddingRight: token.padding,
          alignItems: "center",
        }}
      >
        <Title level={3}>Cases</Title>
        <div style={{ position: "sticky", marginTop: token.margin, height:"90px", width: "200px", border: "solid", borderColor: token.colorPrimary}}>
          <VideoPlayer
            videoUrl={QuickLinkMetaMap.get(QuickLinkComponent.Cases)?.url as string}
            thumbnailUrl={QuickLinkMetaMap.get(QuickLinkComponent.Cases)?.thumbnail as string}
            playIconSize="small"
            pipMode={true}
          />
        </div>
      </div>
      {useDivider && <Divider />}
      <Spin spinning={loader}>
        <EntitiesDisplay
          header={"Cases"}
          dataSource={cases as any[]}
          columns={columns}
          pageNumber={casePage.number}
          pageSize={casePage.size}
          total={totalCasesCount}
          onChange={onChange}
          onSearch={(text: string) => {
            setCurrentPage();
            setCaseSearchTextMap(new Map<string, string>().set("name", text));
          }}
          searchPlaceholder={"search cases by name"}
          searchText={caseSearchTextMap?.get("name")}
          onPageChange={(pageNumber: number, pageSize: number) =>
            setCurrentPage(pageNumber, pageSize)
          }
          getItemProps={(record: Case) => {
            return {
              disabled: `${record.tenantID}_tag` != context,
            };
          }}
          actions={[
            {
              key: "create",
              label: "Create",
              enable: () => true,
              onClick: () => setCreateCaseModal(true),
            },
            {
              key: "clone",
              label: "Clone",
              enable: (itemIds) => {
                return itemIds && itemIds.length == 1;
              },
              mspMode: mspEnabled,
              onClick: (value, selectedTags, tagsOnly) => {
                if (value) {
                  const id = value[0] as string;
                  const item = cases.find((c) => c.id == id);
                  if (item) {
                    OnCloneCase(item, selectedTags, tagsOnly);
                  }
                }
              },
            },
            {
              key: "delete",
              label: "Delete",
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) =>
                    cases.find(
                      (c) =>
                        c.id == id &&
                        c.links?.length == 0 &&
                        `${c.tenantID}_tag` == context
                    )
                  );
                } else {
                  return false;
                }
              },
              hidden: !context?.endsWith("_tag"),
              showWarn: true,
              onClick: (value) => {
                const itemIds = value as string[];
                const items = cases.filter((c) =>
                  itemIds.find((id) => id == c.id)
                );
                if (items) {
                  OnDeleteCases(items);
                }
              },
            },
          ]}
        />
        {createCaseModal && (
          <CaseCreateModal
            open={createCaseModal}
            onClose={() => setCreateCaseModal(false)}
            onSubmit={(values) => OnCreateCase(values)}
          />
        )}
      </Spin>
    </div>
  );
};
