import { FC, useEffect, useState, useReducer } from "react";
import {
  Button,
  Spin,
  Form,
  FormInstance,
  Select,
  Space,
  Typography,
  theme,
  Row,
  Switch
} from "antd";
import { SvgIcon } from "components/SvgIcon";
import {
  TriggerType,
  Trigger,
  TriggerProvider,
  WorkflowTriggerProviders,
  WorkflowNodeCardProps,
  AppInfo,
  IODataMap,
  IODataValue,
  IODataValueMap,
  WorkflowAppSubscriptionInfo,
  WorkflowStepProvider,
  AppSubscriptionInfos,
  WorkflowNodeType,
  IODatas,
} from "types";
import {
  useWorkflowStepProvidersStore,
  useWorkflowStore,
  useWorkflowStepsStore,
} from "store";
import { validateFormFields } from "utility";


import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
} from "components/Suggestions";

import CollapsePanel from "../../CollapsePanel";
import NodeCardApps from "./Apps";
import NodeCardParameters from "./Parameters";
import NodeCardRules from "./Rules";

import { workflowIcons } from "assets/icons";
import { FieldLabel } from "components/FieldLabel";
import { notification } from 'utility/notification';
import NodeCardOutputs from "./Outputs";
import { autoPopulateTriggerRefProvider } from "utility/workflow/utils";
const { Text } = Typography;

const CustomTriggerCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [parametersForm, setParametersForm] = useState<undefined | FormInstance>(undefined);
  const [rulesForm, setRulesForm] = useState<undefined | FormInstance>(undefined);
  const [selectedTriggerId, setSelectedTriggerId] = useState("");
  const [appInfos, setAppInfos] = useState(new Map<string, AppInfo>());
  const [currentApps, setCurrentApps] = useState<Record<string, string|string[]>[]>([]);
  const [selectedApps, setSelectedApps] = useState<Record<string, string|string[]>[]>([]);

  const [triggerParameters, setTriggerParameters] = useState({} as IODataMap);
  const [triggerOutputs, setTriggerOutputs] = useState({} as IODatas);
  const [triggerParametersValues, setTriggerParametersValues] = useState({} as IODataValueMap);
  const [triggerProviderParameterValues, setTriggerProviderParameterValues] = useState(new Map<string, Record<string, IODataValue>>());
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [parameterSuggestionsTreeForRules, setParameterSuggestionsTreeForRules] = useState<[]>([]);

  const [providerParametersForms, dispatch] = useReducer(
    (providerParametersForms: Map<string, FormInstance>, action: any) => {
      switch (action.type) {
        case "add": {
          return new Map<string, FormInstance>(providerParametersForms).set(
            action.providerId,
            action.form
          );
        }
        default:
          throw new Error(`Unknown action type: ${action.type}`);
      }
    },
    new Map<string, FormInstance>()
  );

  const { triggers } = useWorkflowStepsStore((state: any) => ({
    triggers: state.triggers,
  }));

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const { triggerProvidersMap, getTriggerProviders } =
    useWorkflowStepProvidersStore((state) => ({
      triggerProvidersMap: state.triggerProvidersMap,
      getTriggerProviders: state.getTriggerProviders,
    }));

  const loadProviders = async (triggerId: string) => {
    try {
      if(triggerProvidersMap.get(triggerId) == undefined) {
        setLoader(true);
        await getTriggerProviders(
          triggerId,
          selectedWorkflow.triggerRef.triggerType
        );
      }
      selectedWorkflow.triggerRef.triggerID = triggerId;
      setSelectedTriggerId(triggerId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const syncCurrentState = async () => {
    try {
      const triggerRef = selectedWorkflow.triggerRef;
      const triggerApps = [] as Record<string, string|string[]>[];
      const providerParamValuesMap = new Map<string, Record<string, IODataValue>>();
      
      if (triggerRef.providers) {
        triggerRef.providers.map((provider) => {
          const paramsValues = provider.parameters
            ? provider.parameters
            : ({} as IODataValueMap);
          providerParamValuesMap.set(provider.providerID, paramsValues);
          if (provider.providerID != "") {
            triggerApps.push({
              providerId: provider.providerID,
              appSubscriptions: provider.appSubscriptionInfos?.map((appSubscriptionInfo) => (
                appSubscriptionInfo.appSubscriptionID
              ))
            });
          }
        });
        setCurrentApps(triggerApps);
        setSelectedApps(triggerApps);
      }
       
      if (!triggerRef.isConfigured) {
        const triggerProvider = triggerProvidersMap.get(triggerRef.triggerID);
        if (triggerProvider) {
          if (!triggerRef.parameters) {
            triggerRef.parameters = {};
          }
          triggerProvider.trigger.parameters &&
            Object.entries(triggerProvider.trigger.parameters).forEach(
              ([key, value]) => {
                if (value.default != undefined) {
                  triggerRef.parameters[key] = value.default;
                }
              }
            );
        }

        if (triggerRef.triggerType == TriggerType.Custom) {
          triggerProvider?.providers.map((provider) => {
            const params = providerParamValuesMap.get(provider.id);
            if (params) {
              provider.parameters &&
                Object.entries(provider.parameters).forEach(([key, value]) => {
                  if (value.default != undefined) {
                    params[key] = value.default;
                  }
                });
              providerParamValuesMap.set(provider.id, params);
            }
          });
        }
      }

      if (triggerRef.triggerType == TriggerType.Custom) {
        form.setFieldsValue({
          trigger: selectedWorkflow.triggerRef.triggerID,
          apps: triggerApps,
        });
        form.setFieldsValue({ rules: triggerRef.rules });
      }

      const paramsValues = triggerRef.parameters as IODataValueMap;

      form.setFieldsValue({ parameters: paramsValues });

      setTriggerParametersValues(paramsValues);
      setTriggerProviderParameterValues(providerParamValuesMap);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomTriggerDisplayName = (triggerId: string): string => {
    if (triggers) {
      const trigger = triggers.find(
        (i: Trigger) => i.type == TriggerType.Custom && i.id == triggerId
      ) as Trigger;
      if (trigger) {
        return trigger.displayName;
      }
    }
    return "";
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  const buildParameterSuggestionsForRules = (triggerID: string) => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      buildTriggerOutputTree(triggerID, suggestionsTree);
      setParameterSuggestionsTreeForRules(suggestionsTree);
    }
  };

  useEffect(() => {
    if (selectedWorkflow && selectedWorkflow.triggerRef) {
      if (selectedWorkflow.triggerRef.triggerID != "") {
        loadProviders(selectedWorkflow.triggerRef.triggerID);
        syncCurrentState();
        buildParameterSuggestions();
        buildParameterSuggestionsForRules(selectedWorkflow.triggerRef.triggerID);
      }
    }
  }, [triggers, selectedWorkflow]);

  const syncAppInfos = async () => {
    try {
      const triggerProvider = triggerProvidersMap.get(selectedTriggerId);
      const appInfos = new Map<string, AppInfo>();
      if (triggerProvider) {
        triggerProvider.providers &&
          triggerProvider.providers.map((provider: TriggerProvider) => {
            if (provider?.appInfo?.appSubscriptionsInfos) {
              provider.appInfo.appSubscriptionsInfos = provider.appInfo.appSubscriptionsInfos.filter((as) => as.tenantID == selectedWorkflow.tenantID);
            }
            appInfos.set(provider.id, provider.appInfo);
          });
      }
      setAppInfos(appInfos);
    } catch (error) {
      console.log(error);
    }
  };

  const syncTriggerParameters = async () => {
    try {
      const triggerProvider = triggerProvidersMap.get(selectedTriggerId);
      if (triggerProvider) {
        setTriggerParameters(triggerProvider.trigger.parameters);
        setTriggerOutputs(triggerProvider.trigger.outputs);
      } else {
        setTriggerParameters({} as IODataMap);
        setTriggerOutputs({} as IODatas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncAppInfos();
    syncTriggerParameters();
    buildParameterSuggestionsForRules(selectedTriggerId);
  }, [selectedTriggerId]);

  const getTriggerProviderParameters = (providerId: string) => {
    const triggerProvider = triggerProvidersMap.get(selectedTriggerId);
    if (triggerProvider) {
      const appInfo = appInfos.get(providerId);
      if (appInfo) {
        const provider = triggerProvider.providers.find(
          (provider: TriggerProvider) => provider.id === appInfo.providerID
        );
        if (provider && provider.parameters) {
          return provider.parameters;
        }
      }
    }
  };

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = async (values: any) => {
    form
      .validateFields()
      .then(() => {
        const triggerRef = selectedWorkflow.triggerRef;
        triggerRef.isConfigured = true;
        triggerRef.parameters = values.parameters;
        triggerRef.triggerID = values.trigger;
        triggerRef.rules = values.rules;

        const providers = [] as WorkflowTriggerProviders;
        selectedApps.map((selectedApp) => {
          if (selectedApp) {
            const triggerProvider = {} as WorkflowStepProvider;
            const providerId = selectedApp.providerId as string;
            if (providerId) {
              const appInfo = appInfos.get(providerId);
              if (appInfo) {
                triggerProvider.appID = appInfo.id;
                triggerProvider.displayName = appInfo.displayName;
                triggerProvider.providerID = providerId;
                triggerProvider.parameters = values[providerId] ? values[providerId] : triggerProviderParameterValues.get(providerId);
                triggerProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
                const subscriptions = selectedApp.appSubscriptions as string[];
                subscriptions?.map((subscriptionId) => {
                  triggerProvider.appSubscriptionInfos.push({
                    appSubscriptionID: subscriptionId,
                  } as WorkflowAppSubscriptionInfo)
                });
              }
              providers.push(triggerProvider);
            }
          }
        });

        triggerRef.providers = providers;
        setCurrentApps(selectedApps);

        triggerRef.includeRawOutput = values.includeRawOutput;
        triggerRef.isAuditRequired = false;
        selectedWorkflow.triggerRef = triggerRef;

        updateSelectedWorkflow();
        setTriggerParametersValues(values.parameters);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showApps = () => {
    return selectedTriggerId && selectedTriggerId != "";
  };

  const showParameters = () => {
    return  selectedApps?.length > 0 
            && (showTriggerParameters() || showTriggerProviderParameters());
  };

  const showTriggerParameters = () => {
    return triggerParameters && Object.keys(triggerParameters).length > 0;
  };

  const showOutputs = () => {
    return selectedApps?.length > 0  && showTriggerOutputs()
  };

  const showTriggerOutputs = () => {
    return triggerOutputs && Object.keys(triggerOutputs).length > 0;
  };

  const showTriggerProviderParameters = () => {
    return selectedApps.some((selectedApp) => undefined != getTriggerProviderParameters(selectedApp?.providerId as string));
  };

  const showRules = () => {
    return editMode ? selectedTriggerId && selectedTriggerId != "" : selectedWorkflow?.triggerRef?.rules?.length > 0;
  };

  return (
    <Form
      id="customTriggerCardForm"
      form={form}
      name="customTriggerCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="trigger"
            label={<FieldLabel label={"Trigger"} help={"Select trigger type which triggers workflow"} />}
            rules={[
              { required: true, message: "Trigger should be selected!" },
            ]}
          >
            <Select
              disabled={!editMode}
              showAction={["focus", "click"]}
              placeholder="Select Trigger..."
              value={getCustomTriggerDisplayName(selectedTriggerId)}
              onSelect={(value) => {
                selectedWorkflow.triggerRef.providers = [];
                loadProviders(value).then( () => {
                  autoPopulateTriggerRefProvider(selectedWorkflow.triggerRef).then ( (changeHappened) => {
                    syncCurrentState();
                  })
                })
              }}
              options={triggers
                .filter((i: Trigger) => i.type == TriggerType.Custom)
                .map((trigger: Trigger) => ({
                  label: trigger.displayName,
                  value: trigger.id,
                }))}
            />
          </Form.Item>
          {showApps() && 
            <CollapsePanel
              name={
                <Space size={token.marginXXS}>
                  <SvgIcon component={workflowIcons.appsShortIcon} />
                  <Text>Apps</Text>
                </Space>
              }
              ghost={false}
              collapsePanel={false}
            >
              <Form.Item
                name="apps"
                initialValue={currentApps}
                rules={[
                  {
                    validator: (_, value) =>  value.length == 0
                        ? Promise.reject("Apps should be selected!")
                        : Promise.resolve(),
                  },
                ]}
              >
                <NodeCardApps
                  isSingle={true}
                  appInfos={appInfos}
                  selectedApps={selectedApps[0]}
                  editMode={editMode}
                  onChange={(apps) => {
                    const newApps = form.getFieldValue("apps")
                      ? form.getFieldValue("apps")
                      : apps as Record<string, string | string[]>[];
                    setSelectedApps([newApps]);
                  }}
                />
              </Form.Item>
            </CollapsePanel>
          }
          { showParameters() && 
            <CollapsePanel
              name={
                <Space size={token.marginXXS}>
                  <SvgIcon component={workflowIcons.parametersShortIcon} />
                  <Text>Parameters</Text>
                </Space>
              }
              ghost={false}
              collapsePanel={false}
            >
              <Space direction="vertical" style={{ display: "flex" }}>
                {showTriggerParameters() && 
                  <Form.Item
                    name="parameters"
                    initialValue={triggerParametersValues}
                    rules={[
                      {
                        validator: (_, value) => validateFormFields(parametersForm),
                      },
                    ]}
                  >
                    <NodeCardParameters
                      nodeType={nodeType}
                      editMode={editMode}
                      parameters={triggerParameters}
                      parametersValues={triggerParametersValues}
                      parameterSuggestionsTree={parameterSuggestionsTree}
                      onRender={(form) => setParametersForm(form)}
                    />
                  </Form.Item>
                }
                {showTriggerProviderParameters() && selectedApps.map((selectedApp) => {
                    const appInfo = appInfos.get(selectedApp?.providerId as string);
                    const providerParams = getTriggerProviderParameters(selectedApp?.providerId as string);
                    const providerParamsValues = triggerProviderParameterValues.get(selectedApp?.providerId as string);
                    return appInfo && providerParams ? (
                      <Form.Item
                        name={selectedApp?.providerId as string}
                        initialValue={providerParamsValues}
                        rules={[
                          {
                            validator: (_, value) =>
                              validateFormFields(
                                providerParametersForms.get(selectedApp?.providerId as string)
                              ),
                          },
                        ]}
                      >
                        <NodeCardParameters
                          nodeType={nodeType}
                          editMode={editMode}
                          parameters={providerParams}
                          parametersValues={providerParamsValues}
                          parameterSuggestionsTree={parameterSuggestionsTree}
                          appName={appInfo.displayName}
                          onRender={(form) =>
                            dispatch({
                              type: "add",
                              providerId: selectedApp?.providerId as string,
                              form: form,
                            })
                          }
                        />
                      </Form.Item>
                    ) : null;
                })}
              </Space>
            </CollapsePanel>
          }
          {showRules() &&
            <CollapsePanel
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.rulesShortIcon} />
                <Text>Rules</Text>
              </Space>
            }
            ghost={false}
            collapsePanel={false}
          >
            <Form.Item
              name="rules"
              rules={[
                { validator: (_, value) => validateFormFields(rulesForm) },
              ]}
            >
              <NodeCardRules
                editMode={editMode}
                rules={selectedWorkflow.triggerRef.rules}
                factSuggestionsTree={parameterSuggestionsTreeForRules}
                onRender={(form) => setRulesForm(form)}
              />
            </Form.Item>
            </CollapsePanel>
          }
          {showOutputs() &&
            <CollapsePanel
              name={
                <Space size={token.marginXXS}>
                  <SvgIcon component={workflowIcons.outputsShortIcon} />
                  <Text>Outputs</Text>
                </Space>
              }
              ghost={false}
              collapsePanel={false}
            >
              <NodeCardOutputs
                nodeType={WorkflowNodeType.Trigger}
                outputs={triggerOutputs}
              />
            </CollapsePanel>
          }
          <Form.Item
            name="includeRawOutput"
            label={<FieldLabel label={"Include Raw Output"} help={"Store step raw output to access in following steps"} />}
            initialValue={selectedWorkflow.triggerRef.includeRawOutput}
          >
            <Switch
              disabled={!editMode}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={false}
            />
          </Form.Item>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default CustomTriggerCard;
