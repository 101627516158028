import {
  Space,
  Spin,
  notification,
  theme,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Typography,
  InputNumber,
  Tag,
} from "antd";

import { FC, useEffect, useState } from "react";

import { useRbacStore, useCaseStore } from "store";
import {
  Case,
  CaseCategoriesMap,
  CaseMgmtSLADurationPolicyName,
  CasePriorityMap,
  CaseSeverityMap,
  CaseStatusMap,
  dateTimeFormatOptions,
  PolicyCategoryType,
} from "types";
import { capitalizeWords } from "utility";
import { FieldLabel } from "components/FieldLabel";
import { CaseMgmtNote } from "./CaseNote";
import { getCaseSLAStatus, getSLADueAtColor } from "./utils";
import { usePolicyStore } from "store/policy";
import RichEditor from "components/RichEditor";

const { Text } = Typography;

export interface CaseMgmtInfoProps {
  caseLoader: boolean;
  caseIn: Case;
}

export const CaseMgmtInfo: FC<CaseMgmtInfoProps> = ({
  caseLoader,
  caseIn
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState("");
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [slaPriorityDurationMap, setSlaPriorityDurationMap] = useState();
  const [slaDurationDays, setSlaDurationDays] = useState<number>(0);
  const [slaDurationHrs, setSlaDurationHrs] = useState<number>(0);
  const [slaDueAt, setSlaDueAt] = useState<string>(caseIn.slaDueAt as string);

  const users = useRbacStore((state) => state.users);

  const policies = usePolicyStore((state) => state.policies[PolicyCategoryType.CaseMgmtGeneral]);
  const updateCase = useCaseStore((state) => state.updateCase);
  
  useEffect(() => {
    setLoader(caseLoader);
  }, [caseLoader]);


  const convertDayAndHoursFromHours = (hours: number) => {
    const days = Math.floor(hours / 24);
    const hrs = hours % 24;
    return [days, hrs];
  };

  useEffect(() => {
    if (caseIn && caseIn.id != "") {
      form.setFieldsValue(caseIn);
      setDescription(caseIn.description);
      const [days, hrs] = convertDayAndHoursFromHours(caseIn.slaDuration);
      setSlaDurationDays(days);
      setSlaDurationHrs(hrs);
    }
  }, [caseIn]);

  useEffect(() => {
    if (policies) {
      const slaPolicies = policies?.find((p) => p.name == CaseMgmtSLADurationPolicyName)?.value?.data;
      setSlaPriorityDurationMap(slaPolicies);
    }
  }, [policies]);

  useEffect(() => {
    if (!isNaN(slaDurationDays) && !isNaN(slaDurationHrs)) {
      const slaDuration = slaDurationDays * 24 + slaDurationHrs;
      form.setFieldsValue({slaDuration: slaDuration});
      const currentTime = new Date();
      const slaDueAt = new Date(currentTime.setHours(currentTime.getHours() + slaDuration));
      slaDueAt.setMinutes(0);
      slaDueAt.setSeconds(0);
      slaDueAt.setMilliseconds(0);
      const slaDueAtStr = slaDueAt?.toISOString()
      form.setFieldsValue({slaDueAt: slaDueAtStr});
      setSlaDueAt(slaDueAtStr);
    }
  }, [slaDurationDays, slaDurationHrs]);
 
  const updateCurrentCase = async () => {
    let c: Case;
    let hasValidationError = false;

    try {
      setLoader(true);
      try {
        c = await form.validateFields();
      } catch (error) {
        hasValidationError = true;
        throw error;
      }
      c.id = caseIn.id;
      c.slaDuration = Number(c.slaDuration);
      await updateCase(c);
      notification.success({
        message: `Case "${c.name}" updated successfully`,
        duration: 6,
      });
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to update case, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoader(false);
    }
  };

  const getRules = (name: string, required: boolean): any[] => {
    const rules = [];
    if (required) {
      rules.push({ required: true, message: `${name} is required!` })
    }
    return rules;
  };

  return (
    <>
      <Form
        form={form}
        name="caseForm"
        layout="vertical"
        autoComplete="off"
      >
        <Spin spinning={loader}>
          <Space size={0} direction="vertical" style={{ display: 'flex' }}>  
            <Row justify="end" style={{ marginBottom: token.margin }}>
              <Button 
                type="primary" 
                htmlType="submit"
                size="middle"
                onClick={() => form
                  .validateFields()
                  .then(() => setAddNoteModal(true))
                  .catch((err) => {
                    console.log(err);
                  })
                }
              >
                Save
              </Button>
            </Row>
            <Form.Item
              name={"name"}
              label={<FieldLabel bold label={"Name"} />}
              rules={getRules("name", true)}
              validateTrigger="onSubmit"
            >
              <Input
                placeholder={`Enter case name`}
                maxLength={512}
                onChange={(e) => {
                  form.setFieldValue("name", capitalizeWords(e.target.value))
                }}
              />
            </Form.Item>
            <Form.Item
              name={"description"}
              label={<FieldLabel bold label={"Description"} />}
              validateTrigger="onSubmit"
            >
              <RichEditor 
                height="150px"
                maxChars={4096}
                editMode={true}
                content={description}
                onChange={(value) => {
                  setDescription(value);
                  form.setFieldValue("description", value);
                }}
              />
            </Form.Item>
            <Form.Item
              name={"id"}
              label={<FieldLabel bold label={"UUID"} />}
            >
              <Text copyable type="secondary">{caseIn.id}</Text>
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="assigneeID"
                  label={<FieldLabel bold label={"Assignee"} />}
                  rules={getRules("assigneeID", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Object.entries(users).map(([id, u]) => ({
                      label: u.firstName + ' ' + u.lastName,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="userID"
                  label={<FieldLabel bold label={"Reporter"} />}
                >
                  <Select
                    disabled={true}
                    options={Object.entries(users).map(([id, u]) => ({
                      label: u.firstName + ' ' + u.lastName,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="severity"
                  label={<FieldLabel bold label={"Severity"} />}
                  rules={getRules("severity", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CaseSeverityMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="priority"
                  label={<FieldLabel bold label={"Priority"} />}
                  rules={getRules("priority", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    onChange={(val) => {
                      const slaDuration = slaPriorityDurationMap?.[val];
                      if (slaDuration) {
                        const [days, hrs] = convertDayAndHoursFromHours(slaDuration);
                        setSlaDurationDays(days);
                        setSlaDurationHrs(hrs);
                      }
                    }}
                    options={Array.from(CasePriorityMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>   
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label={<FieldLabel bold label={"Status"} />}
                  rules={getRules("status", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CaseStatusMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label={<FieldLabel bold label={"Category"} />}
                  rules={getRules("category", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CaseCategoriesMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="slaDuration"
                  label={<FieldLabel bold label={"SLA Duration"} />}
                  rules={getRules("slaDuration", true)}
                  validateTrigger="onSubmit"
                >
                  <Space>
                    <InputNumber 
                      value={slaDurationDays}
                      min={0}
                      max={31} 
                      style={{ width: "80px" }} 
                      onChange={(val) => {
                        if (val != null) {
                          setSlaDurationDays(val);
                        }
                      }}
                    /> 
                    <Text>Days</Text>
                    <Text></Text>
                    <InputNumber 
                      value={slaDurationHrs}  
                      min={0}
                      max={23} 
                      style={{ width: "80px" }}
                      onChange={(val) => {
                        if (val != null) {
                          setSlaDurationHrs(val);
                        }
                      }}
                    /> 
                    <Text>Hrs</Text>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="slaStatus"
                  label={<FieldLabel bold label={"SLA Status"} />}
                  validateTrigger="onSubmit"
                >
                  <Text>{getCaseSLAStatus(caseIn.slaStatus)}</Text>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="slaDueAt"
                  label={<FieldLabel bold label={"SLA Due"} />}
                  validateTrigger="onSubmit"
                >
                  <Tag color={getSLADueAtColor(caseIn.slaStatus)}>
                    {new Date(slaDueAt).toLocaleTimeString(undefined, dateTimeFormatOptions)}
                  </Tag>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item 
              name="tags"
              label={<FieldLabel bold label={"Labels"}/>}
              validateTrigger="onSubmit"
            >
              <Select
                showAction={["focus", "click"]}
                placeholder={`Enter labels`}
                allowClear
                mode="tags"
              />
            </Form.Item>
          </Space>
        </Spin>
      </Form>
      {addNoteModal
        &&
        <CaseMgmtNote 
          caseId={caseIn.id}
          open={addNoteModal}
          hideNotification={true}
          hideAttachment={true}
          onClose={() => setAddNoteModal(false)}
          onSubmit={() => updateCurrentCase()}
        />
      }
    </>
  );
};