import {
  BorderOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Space, Spin, theme, Typography, Dropdown, MenuProps, Tooltip } from "antd";
import { ReactFlowProvider } from "reactflow";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { workflowIcons } from "assets/icons";
import { Editor } from "components/HyprFlows";
import { SvgIcon } from "components/SvgIcon";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Node, isNode } from "reactflow";
import { useOrganizationStore, useWorkflowStepsStore, useWorkflowStore } from "store";
import { OperatorType, WorkflowNodeActionType, WorkflowNodeProps, WorkflowNodeType, WorkflowRunStatusCode } from "types";
import { getStyles } from "utility/styles";
import PageWrapper from "../../components/PageWrapper";
import pRetry, { FailedAttemptError } from 'p-retry';

import Controls from "./EditorControls/controls";
import { getStepLogo } from "utility/workflow";
import { notification } from 'utility/notification';

const { Link, Text } = Typography;
export const WorkflowRun: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const { workflowRunId } = useParams();
  const navigate = useNavigate();
  const abortController = new AbortController();

  const context = useOrganizationStore((state) => state.context);

  const {
    getWorkflowRun,
    getWorkflowRunStatus,
    stopWorkflowRun,
    selectedWorkflow,
    selectedWorkflowRun,
    resetWorkflowState,
    elements,
    setActiveNodeCardId,
  } = useWorkflowStore((state) => ({
    getWorkflowRun: state.getWorkflowRun,
    getWorkflowRunStatus: state.getWorkflowRunStatus,
    stopWorkflowRun: state.stopWorkflowRun,
    selectedWorkflow: state.selectedWorkflow,
    selectedWorkflowRun: state.selectedWorkflowRun,
    setActiveNodeCardId: state.setActiveNodeCardId,
    resetWorkflowState: state.resetWorkflowState,
    elements: state.elements,
    
  }));

  const syncWorkflowRunInBackground = async (workflowRunId: string) => {
    const status = await getWorkflowRunStatus(workflowRunId);
    if (status == WorkflowRunStatusCode.Running || status == WorkflowRunStatusCode.PendingApproval) {
      throw new Error(status)
    }
  }

  const syncWorkflowRun = async () => {
    try {
      setLoader(true);
      await getWorkflowRun(workflowRunId as string, true);
      pRetry(() => syncWorkflowRunInBackground(workflowRunId as string), {
        retries: 120,
        minTimeout: 5000,
        maxTimeout: 5000,
        signal: abortController.signal
      }).catch((error: any) => {
        console.log("pretry sync workflow run status completed, exiting sync of status", error);
      });
    } catch (error) {
      console.log(error);
      //On failure of loading workflow run, it could be a unauthorized access, switch it to workspace home page
      navigate(`/workspace`);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (workflowRunId) {
      syncWorkflowRun();
    }
    return () => { abortController.abort("exiting workflow run page"); resetWorkflowState() };
  }, [context]);

  const stopCurrentWorkflow = async () => {
    try {
      setLoader(true);
      await stopWorkflowRun(selectedWorkflowRun.id);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while stopping your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const showRunStatus = () => {
    switch (selectedWorkflowRun?.status) {
      case WorkflowRunStatusCode.Running:
        return <SvgIcon size={18} style={{ cursor: "default" }} component={workflowIcons.logProgressIcon} hexColor="orange" />
      case WorkflowRunStatusCode.PendingApproval:
        return <SvgIcon size={18} style={{ cursor: "default" }} component={workflowIcons.logPendingApprovalIcon} hexColor="blue" />
      case WorkflowRunStatusCode.Completed:
        return <SvgIcon size={18} style={{ cursor: "default" }} component={workflowIcons.logSuccessIcon} hexColor="green" />
      case WorkflowRunStatusCode.Failed:
        return <SvgIcon size={18} style={{ cursor: "default" }} component={workflowIcons.logFailedIcon} hexColor="red" />
      default:
        return <></>;
    }
  };
  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS },
  })();

  const handlePendingApprovalMenuClick: MenuProps["onClick"] = (e) => {
    const node = elements.find((el: any) => isNode(el)) as Node;
    if (node && node.data) {
      const props = node.data as WorkflowNodeProps;
      props.actionCallback &&
        props.actionCallback(WorkflowNodeActionType.Click, e.key);
    }
  };

  const getPendingApprovals = () => {
    const approvalItems: MenuProps["items"] = [];
    if (selectedWorkflowRun?.result?.steps) {
      Object.entries(selectedWorkflowRun.result.steps).map(([stepId, result]) => {
        if (result.status == WorkflowRunStatusCode.PendingApproval) {
          approvalItems.push({
            key: stepId,
            label: selectedWorkflow.steps?.[stepId]?.name,
            icon: <SvgIcon component={getStepLogo(WorkflowNodeType.Operator, OperatorType.Approval)} />,
          });
        }
      })
    }
    return {
      items: approvalItems,
      onClick: handlePendingApprovalMenuClick,
    };
  };

  return (
    <Spin spinning={loader}>
      <PageWrapper
        content={
          <DndProvider  backend={HTML5Backend}  options={{enableMouseEvents: true}}>
            <ReactFlowProvider>
              <div id="workflow-run-container" className={classes.container}>
                <div
                  id="workflow-run-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: token.paddingXS,
                    backgroundColor: token.colorBorderSecondary,
                    alignItems: "center",
                  }}
                >
                  <Controls editMode={false}></Controls>
                  {selectedWorkflow?.name ?
                    <Space size={8}>
                      {showRunStatus()}
                      <Text style={{ fontSize: token.fontSizeHeading5 }} strong>{selectedWorkflow.name}/{workflowRunId}</Text>
                      <Tooltip
                      title={"Workflow Properties"}
                      color={token.colorPrimary}
                      placement="top"
                    >
                      <InfoCircleOutlined onClick={() => setActiveNodeCardId("workflow")}/>
                    </Tooltip>
                    </Space>
                    : <></>
                  }
                  <div>
                    <Space>
                      {selectedWorkflowRun?.status == WorkflowRunStatusCode.PendingApproval
                        &&
                        <Dropdown
                          menu={getPendingApprovals()}
                          autoAdjustOverflow
                          autoFocus
                        >
                          <Button
                            type="primary"
                            icon={<SvgIcon size={18} style={{ cursor: "default" }} component={workflowIcons.logPendingApprovalIcon} hexColor={token.colorWhite} />}
                          >
                            Approvals
                          </Button>
                        </Dropdown>
                      }
                      {(selectedWorkflowRun?.status == WorkflowRunStatusCode.Running || selectedWorkflowRun?.status == WorkflowRunStatusCode.PendingApproval)
                        &&
                        <Button
                          type="primary"
                          icon={<BorderOutlined />}
                          onClick={() => stopCurrentWorkflow()}
                        >
                          Stop
                        </Button>
                      }
                    </Space>
                  </div>
                </div>
                <Editor editMode={false} />
              </div>
            </ReactFlowProvider>
          </DndProvider>
        }
      />
    </Spin>
  );
};
