import { FC, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Form,
  FormInstance,
  Input,
  Space,
  Typography,
  theme,
  Row,
  Select,
  InputNumber,
  Switch,
  Modal as AntModal
} from "antd";

import {
  WorkflowStepProvider,
  WorkflowNodeCardProps,
  TriggerType,
  WorkflowAppSubscriptionInfo,
  App,
  AdapterType,
  AppSubscription,
  HttpRequestType,
  HttpRequestContentType,
  HTTPMethodType,
  Condition,
  WorkflowStepOperator,
  HttpRequest,
  TextType,
  Conditions,
  HttpResponseContentType,
  ArrayObject,
} from "types";
import { notification } from 'utility/notification';
import { useAppStore, useAppSubscriptionStore, useEdgeStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";
import { getUserDetailsFromJWT, validateFormFields } from "utility";
import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
  SuggestionsInput,
  getRegEx
} from "components/Suggestions";
import { FieldLabel } from "components/FieldLabel";
import CollapsePanel from "components/CollapsePanel";
import { SvgIcon } from "components/SvgIcon";
import { commonIcons, workflowIcons } from "assets/icons";
import ConditionsForm from "components/ConditionsForm";
import { OperatorKeyValueForm, OperatorIODatasForm } from "./OperatorUtils";
import { JsonEditor } from "components/JsonEditor";
import { runAppActionApi } from "api";

const { Text } = Typography;
const HttpOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [outputsLoader, setOutputsLoader] = useState(false);
  const [appLoader, setAppLoader] = useState(false);
  const [form] = Form.useForm();
  const [kvHeadersForm, setKvHeadersForm] = useState<undefined | FormInstance>(undefined);
  const [kvQueryParamsForm, setKvQueryParamsForm] = useState<undefined | FormInstance>(undefined);
  const [outputsForm, setOutputsForm] = useState<undefined | FormInstance>(undefined);
  const [currentApp, setCurrentApp] = useState<App>({} as App);
  const [appSubscriptionId, setAppSubscriptionId] = useState("");
  const [conditionsForm, setConditionsForm] = useState<undefined | FormInstance>(undefined);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [httpSuggestionsTree, setHttpSuggestionsTree] = useState<[]>([]);
  const [successConditions, setSuccessConditions] = useState<Array<Condition | Conditions>>([{} as Condition]);
  const [showJsonView, setShowJsonView] = useState<{enable: boolean, readOnly?: boolean, data?: any}>({enable: false});
  const [showJsonLoadError, setShowJsonLoadError] = useState<{enable: boolean, error?: string}>({enable: false});

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
  const [httpRequest, setHttpRequest] = useState<HttpRequest>({
    path: "/",
    method: HTTPMethodType.Get,
    contentType: HttpRequestContentType.Json,
    responseContentType: HttpResponseContentType.Json,
    requestType: HttpRequestType.Sync,
    timeout: 300,
    successConditions: [{fact: `{{$.http.${referenceName}.response.status}}`, operator: "==", value: "200"} as Condition]
  } as HttpRequest);
  const [httpOutputs, setHttpOutputs] = useState<ArrayObject[]>([]);

  const getEdgeEndpoint = useEdgeStore((state) => state.getEdgeEndpoint);

  const { 
    apps, 
    getApps, 
    resetAppStore, 
    setAppFields,
    setAppFilter,
  } = useAppStore((state: any) => ({
    apps: state.apps,
    getApps: state.getApps,
    resetAppStore: state.resetAppStore,
    setAppFields: state.setAppFields,
    setAppFilter: state.setAppFilter,
  }));

  const {
    appSubscriptions, 
    getAppSubscriptions,
    clearAppSubscriptions
  } = useAppSubscriptionStore((state: any) => ({
    appSubscriptions: state.appSubscriptions,
    getAppSubscriptions: state.getAppSubscriptions,
    clearAppSubscriptions: state.clearAppSubscriptions
  }));
  
  const loadApps = async () => {
    try {
      setAppLoader(true); 
      setAppFields(["id", "displayName"]);
      setAppFilter({"adapterTypes": [AdapterType.Http]});
      await getApps(true, true);
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoader(false);
    }
  };

  const syncCurrentState = async () => {
    try {
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if(operatorStep) {
        if (operatorStep.httpProvider) {
          setCurrentApp({id: operatorStep.httpProvider.appID, displayName: operatorStep.httpProvider.displayName} as App);
          form.setFieldValue("app", operatorStep.httpProvider.displayName);
          setAppSubscriptionId(operatorStep.httpProvider?.appSubscriptionInfos?.[0].appSubscriptionID);
          form.setFieldValue("appSubscription", (operatorStep.httpProvider?.appSubscriptionInfos?.[0].name));
        }
        const parametersValues = operatorStep.parameters;
        if (parametersValues) {
          const req = parametersValues['http_request'] as HttpRequest;
          if (req) {
            setHttpRequest(req);
            form.setFieldValue("http_request", req);
            setSuccessConditions(req.successConditions);
          } else {
            form.setFieldValue("http_request", httpRequest);
            setSuccessConditions(httpRequest.successConditions);
          }
          const outputs = parametersValues['http_outputs'] as ArrayObject[];
          if (outputs) {
            setHttpOutputs(outputs);
            form.setFieldValue("http_outputs", outputs);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
    return () => {
      resetAppStore();
    };
  }, [selectedWorkflow]);

  useEffect(() => {
    buildHttpSuggestions();
  }, [referenceName]);

  const buildHttpSuggestions = () =>  {
    const suggestionsTree: any[] = [];
    suggestionsTree.push({
      title: "http",
      label: "http",
      key: "$.http",
      regex: getRegEx(),
      value: "{{$.http",
      selectable: false,
      children: [
        {
          title: referenceName,
          label: referenceName,
          key: `$.http.${referenceName}`,
          regex: getRegEx("http"),
          value: `{{$.http.${referenceName}`,
          selectable: false,
          children: [
            {
              title: "response",
              label: "response",
              key: `$.http.${referenceName}.response`,
              regex: getRegEx("http", referenceName),
              value: `{{$.http.${referenceName}.response`,
              selectable: false,
              children: [{
                title: "status",
                label: "status",
                key: `$.http.${referenceName}.response.status`,
                regex: getRegEx("http", referenceName, "response"),
                value: `{{$.http.${referenceName}.response.status}}`,
                isLeaf: true,
              },
              {
                title: "payload",
                label: "payload",
                key: `$.http.${referenceName}.response.payload`,
                regex: getRegEx("http", referenceName, "response"),
                value: `{{$.http.${referenceName}.response.payload`,
                isLeaf: false,
              }]
            }
          ]
        }
      ]
    });
    setHttpSuggestionsTree(suggestionsTree as []);
  }

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        setReferenceName(operatorStep.referenceName);

        operatorStep.parameters = {};
        const httpReq = values.http_request as HttpRequest;
        switch (httpReq.contentType) {
          case HttpRequestContentType.Json:
            httpReq.responseContentType = HttpResponseContentType.Json;
        }
        operatorStep.parameters.http_request = httpReq;
        operatorStep.parameters.http_outputs = values.http_outputs;
      
        const httpProvider = {} as WorkflowStepProvider;
        httpProvider.appID = currentApp?.id;
        httpProvider.displayName = currentApp?.displayName;

        httpProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
        httpProvider.appSubscriptionInfos.push({
          appSubscriptionID: appSubscriptionId
        } as WorkflowAppSubscriptionInfo);

        operatorStep.includeRawOutput = values.includeRawOutput;
        operatorStep.httpProvider = httpProvider;
        selectedWorkflow.steps[id] = operatorStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="httpCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Name of action"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
          <CollapsePanel
            name={
              <Space  size={token.marginXXS}>
                <SvgIcon component={workflowIcons.appsShortIcon} />
                <div>Apps</div>
              </Space>
            }
            ghost={false}
            collapsePanel={false}
          >
            <Form.Item
              name="app"
              label={<FieldLabel label={"App"} help={"Select app which is configured for http"}/>}
              rules={[
                { required: true, message: "App should be selected!" },
              ]}
            >
              <Select
                showSearch
                disabled={!editMode}
                notFoundContent={appLoader ? <Spin size="small"/> : null}
                showAction={["focus", "click"]}
                placeholder="Select App..."
                value={currentApp?.id}
                filterOption={(input, option) => {
                  const label = option?.label as string;
                  return (label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                onSelect={(_, option) => {
                  setCurrentApp({id: option.value, displayName: option.label} as App);
                  option.value && option.value != "" && getAppSubscriptions(option.value, -1, -1);
                  form.setFieldValue("appSubscription", "")
                }}
                onClick={() => loadApps()}
                options={apps.map((a: App) => ({
                    label: a.displayName,
                    value: a.id,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              name="appSubscription"
              label={<FieldLabel label={"App Subscription"} help={"App subscription"}/>}
              rules={[
                { required: true, message: "App Subscription should be selected!" },
              ]}
            >
              <Select
                showSearch
                disabled={!editMode}
                showAction={["focus", "click"]}
                placeholder="Select App Subscription..."
                value={appSubscriptionId}
                filterOption={(input, option) => {
                  const label = option?.label as string;
                  return (label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                onSelect={(id) => setAppSubscriptionId(id)}
                onClick={() => currentApp?.id  ? getAppSubscriptions(currentApp.id, -1, -1) : clearAppSubscriptions()}
                options={appSubscriptions
                  .map((as: AppSubscription) => ({
                    label: as.name,
                    value: as.id,
                  }))}
              />
            </Form.Item>
          </CollapsePanel>
          <CollapsePanel 
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.parametersShortIcon} />
                <Text>Parameters</Text>
              </Space>
            }
            ghost={false} 
            collapsePanel={false}
          >
            <Form.Item
              name={['http_request', 'path']}
              label={<FieldLabel label={"Path"} help={"Http path, should be prefixed with forward slash"} />}
              rules={[{ required: true, message: "Path is required!" }]}
            >
              <Input disabled={!editMode}/>
            </Form.Item>
            <Form.Item
              name={['http_request', 'method']}
              label={<FieldLabel label={"Method"} help={"Http method"} />}
              rules={[{ required: true, message: "Method is required!" }]}
            >
              <Select
                disabled={!editMode}
                options={[
                  { value: HTTPMethodType.Get, label: "GET" },
                  { value: HTTPMethodType.Post, label: "POST" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['http_request', 'contentType']}
              label={<FieldLabel label={"Content Type"} help={"Http content type"} />}
              rules={[{ required: true, message: "Content Type is required!" }]}
            >
              <Select
                disabled={!editMode}
                options={[
                  { value: HttpRequestContentType.Json, label: "JSON" },
                  //{ value: HttpRequestContentType.Xml, label: "XML" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['http_request', 'timeout']}
              label={<FieldLabel label={"Timeout"} help={"Http response timeout in seconds"} />}
              rules={[{ required: true, message: "Timeout is required!" }]}
            >
              <InputNumber disabled={!editMode}/>
            </Form.Item>
            <Form.Item
              name={['http_request', 'headers']}
              label={<FieldLabel label={"Headers"} help={"Http headers"} />}
              rules={[
                { validator: (_, value) =>  validateFormFields(kvHeadersForm) },
              ]}
            >
              <OperatorKeyValueForm 
                editMode={editMode} 
                name={"Header"} 
                keyValues={httpRequest.headers} 
                suggestionsTree={parameterSuggestionsTree}
                onRender={form => setKvHeadersForm(form)}
              />
            </Form.Item>
            <Form.Item
              name={['http_request', 'queryParams']}
              label={<FieldLabel label={"Query Parameters"} help={"Http query parameters"} />}
              rules={[
                { validator: (_, value) =>  validateFormFields(kvQueryParamsForm) },
              ]}
            >
              <OperatorKeyValueForm 
                editMode={editMode} 
                name={"Query Parameter"} 
                keyValues={httpRequest.queryParams} 
                suggestionsTree={parameterSuggestionsTree}
                onRender={form => setKvQueryParamsForm(form)}
              />
            </Form.Item>
            <Form.Item
              name={['http_request', 'payload']}
              label={
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <FieldLabel label={"Payload"} help={"Http payload"} />
                  <Button 
                    size="small"
                    onClick={() => {
                      try {
                        let payload = form.getFieldValue(['http_request', 'payload']);
                        if (payload) {
                          payload = JSON.parse(payload)
                          setShowJsonView({enable: true, data: payload})
                        } else {
                          setShowJsonView({enable: true, data: {}})
                        } 
                      } catch (error) {
                        console.log(error);
                        setShowJsonLoadError({enable: true, error: "Invalid Json payload"});
                      }
                    }}
                  >
                    <SvgIcon component={commonIcons.jsonIcon} />
                  </Button>
                </Space>
              }
            >
              <SuggestionsInput
                editMode={editMode}
                name={"payload"}
                textType={TextType.Text}
                suggestionsTree={parameterSuggestionsTree}
              />
            </Form.Item>
            <Form.Item 
              name={['http_request', 'successConditions']}
              label={<FieldLabel label={"Success Conditions"} help={"Success Conditions for http request"} />}
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: "Conditions are required!" },
                { validator: (_, value) =>  validateFormFields(conditionsForm) },
              ]}>
                <ConditionsForm
                  key={currentApp.id}
                  editMode={editMode}
                  required={true}
                  conditions={successConditions}
                  factSuggestionsTree={httpSuggestionsTree}
                  //valueSuggestionsTree={parameterSuggestionsTree} 
                  onChange={(values) => {
                    values && setSuccessConditions(values);
                  }}
                  onRender={form => setConditionsForm(form)}
                />
              </Form.Item>
              <Form.Item
                name={"http_outputs"}
                label={
                  <Space style={{ display: "flex", justifyContent: "space-between" }}>
                    <FieldLabel label={"Outputs"} help={"Http outputs"} />
                    <Button 
                      size="small"
                      type="primary"
                      loading={outputsLoader}
                      onClick={() => {
                        form
                          .validateFields()
                          .then(async () => {
                              try {
                                setOutputsLoader(true);
                                const tenantId = getUserDetailsFromJWT()?.tenantId;
                                const appSubscription = appSubscriptions?.find((as: AppSubscription) => as.id == appSubscriptionId) as AppSubscription;
                                if (appSubscription) {
                                    const edgeEndpoint = await getEdgeEndpoint(appSubscription.edgeID);
                                    const httpReq = form.getFieldValue('http_request');
                                    const payload = {
                                      httpName: referenceName,
                                      httpRequest: httpReq
                                    }
                                    const data = await runAppActionApi(edgeEndpoint, tenantId, appSubscriptionId, AdapterType.Http, payload)
                                    if (data) {
                                      setShowJsonView({enable: true, readOnly: true, data: data})
                                    } else {
                                      setShowJsonView({enable: true, readOnly: true, data: {}});
                                    } 
                                  }
                              } catch (error) {
                                console.log(error);
                                setShowJsonLoadError({enable: true, error: "Failed to fetch http outputs"});
                              } finally {
                                setOutputsLoader(false);
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                      }}
                    >
                      Test Run
                    </Button>
                  </Space>
                }
                rules={[
                  { validator: (_, value) =>  validateFormFields(outputsForm) },
                ]}
              >
                <OperatorIODatasForm 
                  editMode={editMode} 
                  isOutput={true}
                  iodatas={httpOutputs} 
                  suggestionsTree={httpSuggestionsTree}
                  onRender={form => setOutputsForm(form)}
                />
              </Form.Item>
              <Form.Item
                name="includeRawOutput"
                label={<FieldLabel label={"Include Raw Output"} help={"Store step raw output to access in following steps"} />}
                initialValue={selectedWorkflow.steps[id]?.includeRawOutput}
              >
                <Switch
                  disabled={!editMode}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={false}
                />
              </Form.Item>
          </CollapsePanel>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
      {showJsonView.enable && 
        <JsonEditor
          open={showJsonView.enable}
          width={800}
          title={showJsonView.readOnly ? "Http Outputs" : "Http Payload"}
          editMode={showJsonView.readOnly ? false : editMode}
          data={showJsonView.data}
          icon={commonIcons.httpIcon}
          onClose={() => setShowJsonView({enable: false})}
          onSave={showJsonView.readOnly ? undefined : (jsonData: any) => {
            const payloadStr = JSON.stringify(jsonData);
            form.setFieldValue(["http_request", "payload"], payloadStr);
            setHttpRequest({...httpRequest, payload: payloadStr});
            setShowJsonView({enable: false});
          }}
        />
      }
      {showJsonLoadError.enable && 
        <AntModal
          title="Error"
          onCancel={() => setShowJsonLoadError({enable: false})}
          open={showJsonLoadError.enable}
          footer={[
            <Button 
              key="ok" 
              type="primary" 
              onClick={() => setShowJsonLoadError({enable: false})}
            >
              OK
            </Button>
          ]}
        >
          {showJsonLoadError?.error}
        </AntModal>
      }
    </Form>
  );
};

export default HttpOperatorCard;
