import { FC, useEffect, useRef, useState } from "react";
import { theme, Typography } from "antd";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { useSettingsStore } from "store";

const { Text } = Typography;

export interface RichEditorProps {
  height?: string;
  maxChars?: number;
  editMode?: boolean;
  content: string;
  onChange?: (content: string) => void;
}

const RichEditor: FC<RichEditorProps> = ({ 
  height,
  maxChars,
  editMode,
  content,
  onChange
}) => {
  const { token } = theme.useToken();
  const quillRef = useRef<HTMLDivElement | null>(null);
  const [textLength, setTextLength] = useState(0);
  const [hover, setHover] = useState(false);
  const lightMode = useSettingsStore((state) => state.lightMode);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current;

      const container = editor.querySelector('.ql-container') as HTMLElement;
      if (container) {
        container.style.borderColor = hover ?  token.colorPrimary : token.colorBorder;
      }

      const toolbar = editor.querySelector('.ql-toolbar') as HTMLElement;
      if (toolbar) {
        toolbar.style.backgroundColor = token.colorPrimaryBg;
        toolbar.style.borderColor = hover ?  token.colorPrimary : token.colorBorder;
      }

      const editorArea = editor.querySelector('.ql-editor') as HTMLElement;
      if (editorArea) {
        editorArea.style.backgroundColor = token.colorBgContainer;
      }
    }
  }, [quillRef, lightMode, hover]);

  useEffect(() => {
    if (maxChars && content) {
      const text = content.replace(/<\/?[^>]+(>|$)/g, "");
      if (text.length <= maxChars) {
        setTextLength(text.length);
      } else {
        setTextLength(maxChars);
      }
    }
  }, [content]);

  const toolbar = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{'color': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
  ];

  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'color',
    'list', 'bullet', 'indent',
    'link'
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div 
        ref={quillRef}
        style={{ height: height }} 
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      > 
        <ReactQuill
          theme={editMode ? "snow" : "bubble"}
          style={{ height: height }}
          modules={{
            toolbar: editMode ? toolbar : false,
            clipboard: {matchVisual: false}
          }}
          formats={formats}
          readOnly={!editMode}
          value={content} 
          onChange={(value) => {
            if (maxChars) {
              const text = value.replace(/<\/?[^>]+(>|$)/g, "");
              if (text.length <= maxChars) {
                setTextLength(text.length);
                onChange?.(value);
              } else {
                setTextLength(maxChars);
                onChange?.(text.slice(0, maxChars));
              }
            } else {
              onChange?.(value);
            }
          }}
        />
      </div>
      {maxChars && 
        <Text 
          style={{
            marginTop: '50px',
            fontSize: '12px',
            color: token.colorTextLabel,
            textAlign: 'right'
          }}
        >
          {textLength}/{maxChars}
        </Text>
      }
    </div>
  );
};

export default RichEditor;
