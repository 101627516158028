import { 
    SpecStateType, 
    AccessInfo, 
    IODataValueMap,
    IODataType,
    TriggerType,
    OperatorParamValueMap,
    Rules,
    TextType,
    OperatorType,
    ApprovalsRequiredFrom
} from "types";

//Actions - actions on Workflow
export enum WorkflowActionType {
    WorkflowActionTypePublish = "publish",
    WorkflowActionTypePublishCancel = "publish-cancel",
    WorkflowActionTypeUnpublish = "unpublish",
    WorkflowActionTypeRevert = "revert",
    WorkflowActionTypeEdit = "edit",
    WorkflowActionTypeEnable = "enable",
    WorkflowActionTypeDisable = "disable",
    WorkflowActionTypeExecute = "execute",
    WorkflowActionTypeRestore = "restore",
    WorkflowActionTypeDelete = "delete",
    WorkflowActionTypeClone = "clone",
    WorkflowActionTypeExport = "export",
    WorkflowActionTypeShare = "share",
    WorkflowActionTypeShareApprove  = "share-approve",
	WorkflowActionTypeShareReject   = "share-reject",
	WorkflowActionTypeDeleteApprove = "delete-approve",
	WorkflowActionTypeDeleteReject  = "delete-reject",
}


export interface WorkflowIOData {
    name: string;
    referenceName: string;
    description: string;
    type: IODataType;
    value: any;
    required?: boolean;
    order?: number;
    textType?: TextType;
}

export type WorkflowIODatas = WorkflowIOData[];

export enum WorkflowNodeType {
    Trigger = "trigger",
    Action = "action",
    Operator = "operator",
    Dropzone = "dropzone",
    End = "end",
}

export enum WorkflowEdgeType {
    Normal = "normal",
    Join = "join"
}

export interface WorkflowRunInfo {
    lastRunAt: string;
    runCount: number;
}


export interface  WorkflowRunInput {
    runName: string;
    triggerProviderID: string;
    appID: string;
    appSubscriptionID: string;
    edgeID: string;
    parametersValues: Record<string, any>;
    triggerOutputsValues?: Record<string, any>;
}

export interface WorkflowAppSubscriptionInfo {
    name: string;
    appSubscriptionID: string;
    edgeID: string;
}

export type WorkflowAppSubscriptionInfos = WorkflowAppSubscriptionInfo[];

export interface WorkflowTriggerProvider {
    providerID: string;
    parameters: IODataValueMap;
    appID: string;
    displayName: string;
    appSubscriptionInfos: WorkflowAppSubscriptionInfos;
}

export type WorkflowTriggerProviders = WorkflowTriggerProvider[];

export interface WorkflowTriggerRef {
    isConfigured: boolean;
    isAuditRequired: boolean;
    triggerType: TriggerType;
    triggerID: string;
    parameters: IODataValueMap;
    includeRawOutput: boolean;
    rules: Rules;
    providers: WorkflowTriggerProviders;
    nextStep: string;
}

export interface WorkflowStepOperator {
    isConfigured: boolean;
    isAuditRequired: boolean;
    type: "operator";
    name: string;
    referenceName: string;
    operatorID: string;
    operatorType: OperatorType;
    parameters: OperatorParamValueMap;
    searchProviders: WorkflowStepProviders;
    actionProvider: WorkflowStepProvider;
    httpProvider: WorkflowStepProvider;
    dbProvider: WorkflowStepProvider;
    actionParameters: IODataValueMap; 
    includeRawOutput: boolean;
    nextStep: string;
    prevStep: string;
    decisionSteps: Record<string, string>;
    parallelSteps: string[];
    loopStep: string;
    branchSteps: string[];
}

export interface WorkflowStepProvider {
    providerID: string;
    parameters: IODataValueMap;   
    appID: string;
    displayName: string;
    appSubscriptionInfos: WorkflowAppSubscriptionInfos;
}

export type WorkflowStepProviders = WorkflowStepProvider[];

export interface WorkflowStepAction {
    isConfigured: boolean;
    isAuditRequired: boolean;
    type: "action";
    name: string;
    referenceName: string;
    actionID: string;
    parameters: IODataValueMap;
    provider: WorkflowStepProvider;
    includeRawOutput: boolean;
    nextStep: string;
    prevStep: string;
}

export type WorkflowStepType = WorkflowStepAction | WorkflowStepOperator;
export type WorkflowSteps = Record<string, WorkflowStepType>;

export interface WorkflowApprover {
	id:   string;
	email: string;
}

export interface WorkflowApprovalInfo {
	approvers: WorkflowApprover[];
	requiredFrom: ApprovalsRequiredFrom;
	timeout: number;
}

export interface WorkflowPendingConfiguration {
    type: "trigger" | "action" | "operator",
    id: string;
    obj: WorkflowStepAction | WorkflowStepOperator | WorkflowTriggerRef
} 
export type WorkflowPendingConfigurations = WorkflowPendingConfiguration[];

export interface WorkflowPendingAudit {
    type: "trigger" | "action" | "operator",
    id: string;
    obj: WorkflowStepAction | WorkflowStepOperator | WorkflowTriggerRef
} 
export type WorkflowPendingAudits = WorkflowPendingAudit[];

export interface Workflow {
    id: string;
    version: string;
    versionName: string;
    comments: string;
    state: SpecStateType;
    shared: boolean;
    name: string;
    description: string;
    enabled: boolean;
    isConfigured: boolean;
    isAuditRequired: boolean;
    isSubworkflow: boolean;
    parameters: WorkflowIODatas;
    triggerRef: WorkflowTriggerRef;
    steps: WorkflowSteps;
    outputs: WorkflowIODatas;
    users: string[];
    tags: string[];
    timeoutPolicy: string;
    timeoutSeconds: number;
    updatedAt: string;
    createdAt: string;
    tenantID: string;
    userID: string;
    approvalID: string;
    approvalInfo: WorkflowApprovalInfo;
    runInput?: WorkflowRunInput;
    runInfo: WorkflowRunInfo;
    accessInfo: AccessInfo;
    importTags?: string[];
}

export type WorkflowUploadObjectType = Omit<Workflow, 'version' | 'versionName' | 'state' | 'shared' | 'runInfo' | 'accessInfo' | 'updatedAt' | 'createdAt'>;

