import { FC, memo, useState, useEffect } from "react";

import {
  Spin,
  Space,
  Dropdown,
  MenuProps,
  Button,
  Typography,
  theme,
  Badge,
  Card,
  Form,
} from "antd";

import {
  AppEdgeConfigProps,
  EdgeInfraTypeMap,
  EdgeInfraType,
  AdapterType,
  EdgeStatus,
} from "types";
import { SvgIcon } from "components/SvgIcon";
import { useAppSubscriptionStore, useEdgeStore } from "store";

import { commonIcons } from "assets/icons";
import { getEdgeShortLogo } from "utility/edge";
import SearchInput from "components/SearchInput";
import AppEdge from "./appEdge";
import { isProduction } from "api/constant";

const { Text, Title } = Typography;

const AppEdgeConfig: FC<AppEdgeConfigProps> = ({
  form,
  appId,
  enableEdgeAutoDetect,
  editMode,
}) => {
  const [loader, setLoader] = useState(false);
  const { token } = theme.useToken();
  const [infraTypeFilter, setInfraTypeFilter] = useState<EdgeInfraType>();
  const [searchText, setSearchText] = useState("");
  const [detectEdgeFailed, setDectEdgeFailed] = useState(false);
  
  const {
    edges,
    getEdges,
   } = useEdgeStore((state) => ({
    edges: state.edges,
    getEdges: state.getEdges,
  }));

  const { 
    selectedApp,
    selectedAppSubscription,
    recommendedEdges, 
    getRecommendedEdges, 
    clearRecommendedEdges 
  } = useAppSubscriptionStore((state) => ({
      selectedApp: state.selectedApp,
      selectedAppSubscription: state.selectedAppSubscription,
      recommendedEdges: state.recommendedEdges,
      getRecommendedEdges: state.getRecommendedEdges,
      clearRecommendedEdges: state.clearRecommendedEdges,
  }));

  const [selectedEdgeId, setSelectedEdgeId] = useState<string>(selectedAppSubscription?.edgeID);
  
  const loadRecommendedEdges = async () => {
    try {
      setLoader(true);
      await getRecommendedEdges(
        appId,
        selectedAppSubscription?.httpConfiguration
      );
    } catch (error) {
      console.log(error);
      setDectEdgeFailed(true);
    } finally {
      setLoader(false);
    }
  };

  const getEdgeLabel = (
    infraType?: EdgeInfraType,
    includeDisplayName?: boolean
  ) => {
    return infraType ? (
      <Space>
        <SvgIcon component={getEdgeShortLogo(infraType)} />
        {includeDisplayName && EdgeInfraTypeMap.get(infraType)}
      </Space>
    ) : (
      "All"
    );
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "all"
      ? setInfraTypeFilter(undefined)
      : setInfraTypeFilter(e.key as EdgeInfraType);
  };

  const getMenuOptions = () => {
    const menuItems: MenuProps["items"] = [
      {
        key: "all",
        label: getEdgeLabel(),
      },
      {
        key: EdgeInfraType.Amazon,
        label: getEdgeLabel(EdgeInfraType.Amazon, true),
      },
      {
        key: EdgeInfraType.Azure,
        label: getEdgeLabel(EdgeInfraType.Azure, true),
      },
      {
        key: EdgeInfraType.Gcp,
        label: getEdgeLabel(EdgeInfraType.Gcp, true),
      },
      {
        key: EdgeInfraType.Oci,
        label: getEdgeLabel(EdgeInfraType.Oci, true),
      },
      {
        key: EdgeInfraType.OnPrem,
        label: getEdgeLabel(EdgeInfraType.OnPrem, true),
      },
    ];

    return {
      items: menuItems,
      selectable: true,
      defaultSelectedKeys: ["all"],
      onClick: handleMenuClick,
    };
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "400px",
        overflow: "auto",
        scrollbarWidth: "thin"
      }}
    >
      <Spin spinning={loader}>
        <div>
          {detectEdgeFailed && (
            <Text type="warning">Error in detection of recommended edges, Retry again!</Text>
          )}
          {editMode
            &&
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <Space size={token.sizeXXS} style={{ display: "flex" }}>
                {(selectedApp?.adapterTypes?.some((type) => type == AdapterType.Http)
                  &&
                  editMode
                  &&
                  enableEdgeAutoDetect)
                  &&
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setDectEdgeFailed(false); 
                      clearRecommendedEdges();
                      loadRecommendedEdges();
                    }}
                  >
                    Auto Detect
                  </Button>
                }
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => {
                    setDectEdgeFailed(false); 
                    clearRecommendedEdges();
                    getEdges();
                  }}
                >
                  Refresh
                </Button>
              </Space>
              <Space size={token.sizeXXS} style={{ display: "flex" }}>
                <div style={{ marginTop: token.marginXS, marginBottom: token.marginXS, width: "400px" }}>
                  <SearchInput
                    placeholder="search edges"
                    onFilter={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <Dropdown menu={getMenuOptions()} trigger={["click"]}>
                  <Button
                    size="middle"
                    icon={<SvgIcon component={commonIcons.filterIcon} />}
                  />
                </Dropdown>
              </Space>
            </Space>
          }
          {edges?.sort((a, b) => a.infraType.localeCompare(b.infraType))
            .sort((a,b) => selectedAppSubscription?.edgeID == b.id || recommendedEdges?.includes(b.id) ? 1 : -1)
            .filter((edge) =>
              infraTypeFilter ? infraTypeFilter == edge.infraType : true
            )
            .filter(
              (edgeInfo) => edgeInfo.displayName.indexOf(searchText) !== -1
            )
            .sort((a, b) =>  b.status == EdgeStatus.Online ? 1 : -1)
            .map((edge) => (
              recommendedEdges?.includes(edge.id)
                ?
                <Badge.Ribbon  key={edge.id} text="Recommended" color="magenta">
                  <AppEdge
                    key={edge.id} 
                    edge={edge}
                    editMode={editMode}
                    isSelectedEdge={edge.id == selectedEdgeId}
                    onClick={() => {
                      if (!isProduction || edge.infraType == EdgeInfraType.Amazon) {
                        setSelectedEdgeId(edge.id);
                        form.setFieldValue("edgeID", edge.id);
                      }
                    }} 
                  />
                </Badge.Ribbon>
                :
                <AppEdge
                  key={edge.id} 
                  edge={edge}
                  editMode={editMode}
                  isSelectedEdge={edge.id == selectedEdgeId}
                  onClick={() => {
                    if (!isProduction || edge.infraType == EdgeInfraType.Amazon) {
                      setSelectedEdgeId(edge.id);
                      form.setFieldValue("edgeID", edge.id);
                    }
                  }} 
                />
            ))
          }
        </div>
        <Form
          form={form}
          name="appParametersForm"
          layout="vertical"
          autoComplete="off"
          disabled={!editMode}
        >
          <Form.Item
            name="edgeID"
            rules={[{ required: true, message: "Edge should be selected" }]}
          />
        </Form>
      </Spin>
    </Card>
  );
};

export default AppEdgeConfig;
