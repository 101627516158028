import { Badge, Dropdown, MenuProps, Space, Tree, theme, Card, Row, Tooltip, Spin } from "antd";
import { FC, ReactElement, useEffect, useReducer, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { createUseStyles } from "react-jss";
import Highlighter from "react-highlight-words";
import { JsonViewer, defineDataType } from '@textea/json-viewer'

import { DataType, IODataMeta, WorkflowRun } from "types";

import { WarningOutlined } from "@ant-design/icons";

import {
  Approval,
  ApprovalStatus,
  Operator,
  OperatorLoopType,
  OperatorType,
  RunLogTreeProps,
  Trigger,
  TriggerType,
  WorkerLogData,
  WorkflowNodeType,
  WorkflowRunResult,
  WorkflowRunStatusCode,
  WorkflowRunStatusCodeMap,
  WorkflowRunStepResult,
  WorkflowStepAction,
  WorkflowStepOperator,
  WorkflowStepProvider,
  WorkflowStepType,
} from "types";

import {
  getDataByPathApi,
  getActionLogsApi,
  getActionOutputsApi,
  getActionParametersApi,
  getOperatorLogsApi,
  getOperatorOutputsApi,
  getOperatorParametersApi,
  getOperatorOutputsMetaApi,
  getTriggerLogsApi,
  getTriggerOutputsApi,
  getTriggerParametersApi,
  getWorkflowOutputsApi,
  getWorkflowParametersApi,
  getWorkflowOutputsMetaApi,
  getOperatorParametersMetaApi,
  getWorkflowParametersMetaApi,
  getWorkflowApi,
  getWorkflowRunApi,
} from "api";

import SearchInput from "../../SearchInput";

import { useEdgeStore, useSettingsStore, useWorkflowStepsStore, useWorkflowStore } from "store";
import {
  getTriggerDisplayName,
  getAllWorkflowStepsWithOrder,
} from "utility/workflow";

import { workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useApprovalStore } from "store/approval";
import { HLink } from "components/Link";

const showTimeStampSettingKey = "showTimestamps";
const viewDetailLogsSettingKey = "viewDetailLogs";

interface ApproverInfo {
  email: string;
  status: ApprovalStatus;
  reason: string;
}

interface LogNode {
  key: string;
  title: any;
  isLeaf?: boolean;
  selectable?: boolean;
  icon?: any;
  children?: LogNode[];
}

const RunLogTree: FC<RunLogTreeProps> = (props) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false)
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [loadedKeys, setLoadedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [showTimeStamp, setShowTimeStamp] = useState<boolean>(false);
  const [showDetailLogs, setShowDetailLogs] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [subworkflowRunMap, setSubworkflowRunMap] = useState<Map<string, WorkflowRun>>(new Map<string, WorkflowRun>());
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const navigate = useNavigate();

  const prev = useRef(props);

  const styles = createUseStyles({
    treeTitle: {
      '&:hover': {
        color: token.colorPrimaryTextHover,
      }
    },
  })();

  const [treeData, setTreeData] = useState<LogNode[]>([]);

  const { selectedWorkflow, selectedWorkflowRun } = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow,
      selectedWorkflowRun: state.selectedWorkflowRun,
    })
  );

  const getApproval = useApprovalStore((state) => state.getApproval);

  const { getEdgeEndpoint } = useEdgeStore((state) => ({
    getEdgeEndpoint: state.getEdgeEndpoint,
  }));

  const getStepType = (stepType: string) =>
    stepType == "action" ? "Action" : "Operator";

  const getStepStatus = (
    runResult: WorkflowRunResult,
    stepId: string
  ) => {
    if (selectedWorkflowRun.status == WorkflowRunStatusCode.NotRunning) {
      return WorkflowRunStatusCode.NotRunning;
    }

    if (runResult) {
      if (props.stepId == WorkflowNodeType.Trigger) {
        return runResult.trigger?.status;
      } else {
        if (runResult.steps) {
          const stepResult = Object.entries(runResult.steps).find(
            ([id]) => id == stepId
          );
          return stepResult
            ? stepResult[1].status
            : WorkflowRunStatusCode.NotRunning;
        }
      }
    }
    
    return WorkflowRunStatusCode.NotRunning;
  };

  const getIconByStatus = (status: WorkflowRunStatusCode) => {
    if (selectedWorkflowRun.status == WorkflowRunStatusCode.NotRunning) {
      return <SvgIcon size={token.sizeSM} component={workflowIcons.logBlankIcon} hexColor="grey" />;
    }

    switch (status) {
      case WorkflowRunStatusCode.Running:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logProgressIcon} hexColor="orange" />;
      case WorkflowRunStatusCode.PendingApproval:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logPendingApprovalIcon} hexColor={"blue"} />
      case WorkflowRunStatusCode.Completed:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logSuccessIcon} hexColor="green" />;
      case WorkflowRunStatusCode.Failed:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logFailedIcon} hexColor="red" />;
      default:
        return <SvgIcon size={token.sizeSM} component={workflowIcons.logBlankIcon} />;
    }
  };

  const isLeafNode = (status: WorkflowRunStatusCode): boolean => {
    switch (status) {
      case WorkflowRunStatusCode.Completed:
        return false;
      case WorkflowRunStatusCode.Failed:
        return false;
      default:
        return true;
    }
  };


  const loadLogNodes = () => {
    setLoader(true);
    const logNodes = [] as LogNode[];

    if (props.isWorkflowCard) {
      const workflowSteps: [string, WorkflowStepType][] = [];
      getAllWorkflowStepsWithOrder(
        selectedWorkflow.steps,
        selectedWorkflow?.triggerRef.nextStep,
        workflowSteps
      );

      if (selectedWorkflow?.parameters?.length) {
        logNodes.push({
          key: "workflow/parameters",
          title: "Parameters",
          isLeaf: isLeafNode(selectedWorkflowRun.result?.trigger?.status),
          selectable: false,
          icon: getIconByStatus(
            selectedWorkflowRun.result?.trigger?.status ==
              WorkflowRunStatusCode.Running
              ? WorkflowRunStatusCode.Running
              : WorkflowRunStatusCode.Completed
          ),
        });
      }

      logNodes.push({
        key: "trigger",
        title: "Trigger - " + getTriggerDisplayName(selectedWorkflow),
        isLeaf: selectedWorkflow.triggerRef?.triggerType == TriggerType.Manual || isLeafNode(selectedWorkflowRun.result?.trigger?.status),
        selectable: false,
        icon: getIconByStatus(selectedWorkflowRun.result?.trigger?.status),
        children: getTriggerLogNodes(),
      });

      workflowSteps.map(([id, step]) => {
        logNodes.push({
          key: id,
          title: getStepType(step.type) + " - " + step.name,
          isLeaf: isApprovalOperator(id) && getStepStatus(selectedWorkflowRun.result, id) == WorkflowRunStatusCode.PendingApproval
                    ? false
                    : isLeafNode(getStepStatus(selectedWorkflowRun.result, id)),
          selectable: false,
          icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, id)),
          children: getStepLogNodes(id, step),
        });
      });

      if (selectedWorkflow?.outputs?.length) {
        logNodes.push({
          key: "workflow/outputs",
          title: "Outputs",
          isLeaf: selectedWorkflowRun.status != WorkflowRunStatusCode.Completed,
          selectable: false,
          icon: getIconByStatus(
                  selectedWorkflowRun.status == WorkflowRunStatusCode.Completed
                    ? WorkflowRunStatusCode.Completed
                    : WorkflowRunStatusCode.Unspecified
                ),
        });
      }
    } else {
      if (props.stepId) {
        if (props.stepId == WorkflowNodeType.Trigger) {
          logNodes.push(...getTriggerLogNodes());
        } else {
          const step = Object.entries(selectedWorkflow?.steps).find(
            ([id]) => id == props.stepId
          )?.[1];
          if (step) {
            logNodes.push(...getStepLogNodes(props.stepId, step));
          }
        }
      }
    }

    setTreeData([...logNodes]);
    setLoader(false);
  }

  useEffect(() => {
    const propsChanged = (props.isWorkflowCard != prev.current.isWorkflowCard) || (props.stepId != prev.current.stepId);
    const initRequired = propsChanged || !treeData.length;
    prev.current = propsChanged ? props : prev.current;
    if (initRequired) {
      loadLogNodes();
      setExpandedKeys([]);
      setLoadedKeys([]);
    }
  }, [props]);

  useEffect(() => {
    loadLogNodes();
  }, [selectedWorkflowRun]);

  useEffect(() => {
    setExpandedKeys([]);
    setLoadedKeys([]);
    loadLogNodes();
  }, [lightMode]);

  const hasTriggerOutputs = (triggerID: string) => {
    if (triggerID == "") {
      return false;
    }
    const triggers = useWorkflowStepsStore.getState().triggers;
    const trigger = triggers.find((i: Trigger) => i.id == triggerID) as Trigger;
    return trigger?.outputs && Object.entries(trigger.outputs).length
  }

  const hasOperatorOutputs = (stepId: string) => {
    if (stepId == "") {
      return false;
    }
    const stepOperator = selectedWorkflow?.steps[stepId] as WorkflowStepOperator;
    if (stepOperator.operatorType == OperatorType.Loop) {
      const loopType = getLoopOperatorType(stepId);
      return loopType == OperatorLoopType.On;
    }
    if (stepOperator.operatorType == OperatorType.KV) {
      return true;
    }
    const operators = useWorkflowStepsStore.getState().operators;
    const operator = operators.find((i: Operator) => i.id == stepOperator.operatorID) as Operator;
    return operator?.outputs != undefined
  }

  const isSearchOperator = (stepId: string) => {
    if (stepId == "") {
      return false;
    }
    const stepOperator = selectedWorkflow.steps[stepId] as WorkflowStepOperator;
    return stepOperator.operatorType == OperatorType.Search;
  }

  const isApprovalOperator = (stepId: string) => {
    if (stepId == "") {
      return false;
    }
    const stepOperator = selectedWorkflow.steps[stepId] as WorkflowStepOperator;
    return stepOperator.operatorType == OperatorType.Approval;
  }

  const getApprovals = (data: any) => {
    const approval = data as Approval;
    const approversInfo = [] as ApproverInfo[];
    approval?.approvers.map((approver) => {
      approversInfo.push({
        email: approver.email,
        status: approver.response.approvalStatus,
        reason: approver.response.reason ? approver.response.reason : "",
      })
    });
    return approversInfo
  }

  const getSearchOutputs = (stepId: string, data: any) => {
    if (data) {
      const isLocalHost = process.env.REACT_APP_SIGNIN_URI?.includes("localhost");
      return {
          "total_count": data?.total_count,
          "results_url": 
            isLocalHost 
            ?
            new URL(window.location.origin + "/search-runs/" + selectedWorkflowRun?.result?.steps?.[stepId]?.searchRunID)
            :
            new URL(data?.results_url)
        };
    }
  }
  
  const getTriggerLogNodes = (): LogNode[] => {
    const logNodes = [] as LogNode[];

    if (selectedWorkflow?.triggerRef.parameters && Object.keys(selectedWorkflow?.triggerRef.parameters).length) {     
      logNodes.push({
        key:  "trigger/" + selectedWorkflow.triggerRef.triggerID + "/parameters",
        title: "Parameters",
        isLeaf: isLeafNode(selectedWorkflowRun.result?.trigger?.status),
        selectable: false,
        icon: getIconByStatus(selectedWorkflowRun.result?.trigger?.status),
      });
    } 

   /*  logNodes.push({
      key:  "trigger/" + selectedWorkflow.triggerRef.triggerID + "/logs",
      title: "Logs",
      isLeaf: isLeafNode(selectedWorkflowRun.result?.trigger?.status),
      selectable: false,
      icon: getIconByStatus(selectedWorkflowRun.result?.trigger?.status),
    }); */

    if (hasTriggerOutputs(selectedWorkflow.triggerRef.triggerID)) {
      logNodes.push({
        key:  "trigger/" + selectedWorkflow.triggerRef.triggerID + "/outputs",
        title: "Outputs",
        isLeaf: isLeafNode(selectedWorkflowRun.result?.trigger?.status),
        selectable: false,
        icon: getIconByStatus(selectedWorkflowRun.result?.trigger?.status),
      });
    } else {
      logNodes.push({
        key:  "outputs",
        title: "Outputs",
        isLeaf: true,
        selectable: false,
        icon: getIconByStatus(WorkflowRunStatusCode.NotRunning),
      });
    }

    return logNodes;
  }
  
  const getStepLogNodes = (stepId: string, step: WorkflowStepType): LogNode[] => {
    const logNodes = [] as LogNode[];

    if (step.type == WorkflowNodeType.Operator) {
      if (isApprovalOperator(stepId)) {
        logNodes.push({
          key: step.type + "/" + stepId + "/approvals",
          title: "Approvals",
          isLeaf: false,
          selectable: false,
          icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, stepId)),
        });
      }
     
      if (selectedWorkflow?.steps[stepId]?.parameters &&
        Object.keys(selectedWorkflow.steps[stepId]?.parameters).length) {
          logNodes.push({
            key: step.type + "/" + stepId + "/parameters",
            title: "Parameters",
            isLeaf: isApprovalOperator(stepId) && getStepStatus(selectedWorkflowRun.result, stepId) == WorkflowRunStatusCode.PendingApproval
                    ? false
                    : isLeafNode(getStepStatus(selectedWorkflowRun.result, stepId)),
            selectable: false,
            icon: getIconByStatus( getStepStatus(selectedWorkflowRun.result, stepId)),
          });
      }

      /* logNodes.push({
        key: step.type + "/" + stepId + "/logs",
        title: "Logs",
        isLeaf: isLeafNode(getStepStatus(selectedWorkflowRun.result, stepId)),
        selectable: false,
        icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, stepId)),
      }); */

      if (hasOperatorOutputs(stepId)) {
        logNodes.push({
          key: step.type + "/" + stepId + "/outputs",
          title: "Outputs",
          isLeaf: isLeafNode(getStepStatus(selectedWorkflowRun.result, stepId)),
          selectable: false,
          icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, stepId)),
        });
      }
    }

    if (step.type == WorkflowNodeType.Action) {
      const actionStep = step as WorkflowStepAction;
      const appSubscriptionInfo = actionStep.provider?.appSubscriptionInfos?.[0];
      if (appSubscriptionInfo) {
        logNodes.push({
          key: step.type +"/" + stepId + "/" + actionStep.provider.providerID + "/" + appSubscriptionInfo.appSubscriptionID + "/parameters",
          title: "Parameters",
          isLeaf: isLeafNode(getStepStatus(selectedWorkflowRun.result, stepId)),
          selectable: false,
          icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, stepId)),
        });
      

        /* logNodes.push({
          key: step.type + "/" + stepId + "/" + actionStep.provider.providerID + "/" + appSubscriptionInfo.appSubscriptionID + "/logs",
          title: "Logs",
          isLeaf: isLeafNode(getStepStatus(selectedWorkflowRun.result, stepId)),
          selectable: false,
          icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, stepId)),
        }); */

        logNodes.push({
          key: step.type +"/" + stepId + "/" + actionStep.provider.providerID + "/" + appSubscriptionInfo.appSubscriptionID + "/outputs",
          title: "Outputs",
          isLeaf: isLeafNode(getStepStatus(selectedWorkflowRun.result, stepId)),
          selectable: false,
          icon: getIconByStatus(getStepStatus(selectedWorkflowRun.result, stepId)),
        });

      } else {
        logNodes.push({
          key: "parameters",
          title: "Parameters",
          isLeaf: true,
          selectable: false,
          icon: getIconByStatus(WorkflowRunStatusCode.NotRunning),
        });
        logNodes.push({
          key: "outputs",
          title: "Outputs",
          isLeaf: true,
          selectable: false,
          icon: getIconByStatus(WorkflowRunStatusCode.NotRunning),
        });
      }
    }

    return logNodes;
  };

  const updateTreeData = (list: LogNode[], key: React.Key, children: LogNode[]): LogNode[] =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
    return node;
  });

  const getHighlightTreeTitle = (text: string) => {
    return (
      <Highlighter
        className={styles.treeTitle}
        searchWords={[searchText]}
        autoEscape={true}
        textToHighlight={text}
      />
    );
  };
  
  const urlDataType = defineDataType({
    is: (value) => value instanceof URL,
    Component: (props) => 
        <Tooltip title="click here for details" color={token.colorPrimaryText}>
          <a href={props.value as string} target="_blank" rel="noopener noreferrer">
              ...
            </a>
        </Tooltip>
  })

  const getJsonViewTreeNodes = (key: string, data: any): LogNode[] => {
    const logNodes = [] as LogNode[];
    logNodes.push({
      key: key+"/values",
      title:  <JsonViewer 
                rootName={false}
                editable={false}
                displayDataTypes={false}
                displaySize={false}
                enableClipboard={true}
                defaultInspectDepth={1}
                collapseStringsAfterLength={100}
                value={data}
                theme={lightMode ? "light" : "dark"}
                highlightUpdates={true}
                sx={{paddingLeft: 2}}
                valueTypes={[urlDataType]}
              />,
     /*  <JsonViewer
              style={{...reactJsonViewTheme, ...reactJsonViewStyle}}
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={true}
              collapsed={1}
              value={data}
              components={{ value: renderJsonValues }}
            />, */
      isLeaf: true,
      selectable: false,
    });
    return logNodes;
  };

  const getErrorTreeNodes = (key: string, msg: string): LogNode[] => {
    const logNodes = [] as LogNode[];
    logNodes.push({
      key: key+"/error",
      title: msg,
      isLeaf: true,
      selectable: false,
      icon: <WarningOutlined style={{ color: "orange" }} />
    });
    return logNodes;
  };

  const onLoadData = ({ key, children }: any) => {
    return new Promise<void>((resolve, reject) => {
      if (children) {
        resolve();
        return;
      }

      const edgeId = selectedWorkflowRun?.edgeID;
      if (key == "workflow/parameters") {
        getEdgeEndpoint(edgeId)
          .then((edgeEndpoint) => {
            if (selectedWorkflowRun.isSubworkflowRun) {
              getWorkflowParametersApi(
                edgeEndpoint,
                selectedWorkflow.tenantID,
                selectedWorkflow.id,
                selectedWorkflowRun.id,
              ).then((parameters) => {
                const parameterValues = {} as Record<string, any>;
                Promise.allSettled(Object.entries(parameters).map( async ([paramRefName, paramMeta]) => {
                  const meta = paramMeta as IODataMeta;
                  const paramName = selectedWorkflow.parameters.find((parameter) => parameter.referenceName == paramRefName)?.name;
                  return Promise.allSettled(meta.pathInfos?.map(async (pathInfo: any) => {
                    const endpoint = await getEdgeEndpoint(pathInfo.edgeID);
                    if (endpoint) {
                      const data = await getDataByPathApi(
                        endpoint,
                        selectedWorkflow.tenantID,
                        pathInfo.path,
                        meta.field,
                      );
                      if (data) {
                        if (data.value) {
                          return data.value
                        }
                        return data;
                      }
                    }
                  })).then((values) => {
                    values.map((value) => {
                      if(value.status == "fulfilled") {
                        if (paramName) {
                          parameterValues[paramName] = value.value;
                        } else {
                          parameterValues[paramRefName] = value.value;
                        }
                      }
                    });
                  }).catch((err) => {
                    throw err;
                  })
                })).then((values) => {
                  setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, parameterValues)));
                  resolve();
                }).catch((err) => {
                  console.log("failed to get workflow parameters", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get parameters, try again")));
                  reject(err);
                });
              }).catch((err) => {
                console.log("failed to get workflow parameters", err);
                setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get parameters, try again")));
                reject("failed to get workflow parameters, try again");
              });
            } else {
              getWorkflowParametersApi(
                edgeEndpoint,
                selectedWorkflow.tenantID,
                selectedWorkflow.id,
                selectedWorkflowRun.id
              )
              .then((parameters) => {
                setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, parameters)));
                resolve();
              })
              .catch((err) => {
                console.log("faield to get workflow parameters", err);
                setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get parameters, try again")));
                reject("failed to get parameters, try again");
              });
            }
          })
          .catch((err) => {
            console.log("failed to get edge endpoint", err);
            setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get parameters, try again")));
            reject("failed to get parameters, try again");
          });
      } else if (key == "workflow/outputs") {
        getEdgeEndpoint(edgeId)
          .then((edgeEndpoint) => {
            getWorkflowOutputsApi(
              edgeEndpoint,
              selectedWorkflow.tenantID,
              selectedWorkflow.id,
              selectedWorkflowRun.id,
            ).then((outputs) => {
              const outputValues = {} as Record<string, any>;
              Promise.allSettled(Object.entries(outputs).map( async ([outputRefName, outputMeta]) => {
                const meta = outputMeta as IODataMeta;
                const outputName = selectedWorkflow.outputs.find((output) => output.referenceName == outputRefName)?.name;
                return Promise.allSettled(meta.pathInfos?.map(async (pathInfo: any) => {
                  const endpoint = await getEdgeEndpoint(pathInfo.edgeID);
                  if (endpoint) {
                    const data = await getDataByPathApi(
                      endpoint,
                      selectedWorkflow.tenantID,
                      pathInfo.path,
                      meta.field,
                    );
                    if (data) {
                      if (data.value) {
                        return data.value
                      }
                      return data;
                    }
                  }
                })).then((values) => {
                  values.map((value) => {
                    if(value.status == "fulfilled") {
                      if (outputName) {
                        outputValues[outputName] = value.value;
                      } else {
                        outputValues[outputRefName] = value.value;
                      }
                    }
                  });
                }).catch((err) => {
                  throw err;
                })
              })).then((values) => {
                setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, outputValues)));
                resolve();
              }).catch((err) => {
                console.log("failed to get workflow outputs", err);
                setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get outputs, try again")));
                reject(err);
              });
            }).catch((err) => {
              console.log("failed to get workflow outputs", err);
              setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get outputs, try again")));
              reject("failed to get workflow outputs, try again");
            });
          })
          .catch((err) => {
            console.log("failed to get edge endpoint", err);
            setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get outputs, try again")));
            reject("failed to get outputs, try again");
          });
      } else {
        const s = key.split("/");
        if (s.length < 3) {
          resolve();
          return;
        }
        const stepType = s[0];
        const stepId = s[1];
        if (stepType == WorkflowNodeType.Trigger) {
          const dataType = s[2];
          switch (dataType) {
            case "parameters":
              getEdgeEndpoint(edgeId)
                .then((edgeEndpoint) => {
                  getTriggerParametersApi(
                    edgeEndpoint,
                    selectedWorkflow.tenantID,
                    selectedWorkflow.id,
                    selectedWorkflowRun.id
                  )
                    .then((parameters) => {
                      setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, parameters)));
                      resolve();
                    })
                    .catch((err) => {
                      console.log("failed to get trigger parameters", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get trigger parameters, try again")));
                      reject("failed to get trigger parameters, try again");
                    });
                })
                .catch((err) => {
                  console.log("failed to get edge endpoint", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get trigger parameters, try again")));
                  reject("failed to get trigger parameters, try again");
                });
              break;
           /*  case "logs":
              getEdgeEndpoint(edgeId)
                .then((edgeEndpoint) => {
                  getTriggerLogsApi(
                    edgeEndpoint,
                    selectedWorkflow.tenantID,
                    selectedWorkflow.id,
                    selectedWorkflowRun.id,
                    showTimeStamp,
                    showDetailLogs
                  )
                    .then((logs) => {
                      setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, logs)));
                      resolve();
                    })
                    .catch((err) => {
                      console.log("failed to get trigger logs", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get trigger logs, try again")));
                      reject("failed to get trigger logs, try again");
                    });
                })
                .catch((err) => {
                  console.log("faield to get edge endpoint", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get trigger logs, try again")));
                  reject("failed to get trigger logs, try again");
                });
              break; */
            case "outputs":
              getEdgeEndpoint(edgeId)
                .then((edgeEndpoint) => {
                  getTriggerOutputsApi(
                    edgeEndpoint,
                    selectedWorkflow.tenantID,
                    selectedWorkflow.id,
                    selectedWorkflowRun.id
                  )
                    .then((outputs) => {
                      setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, outputs)));
                      resolve();
                    })
                    .catch((err) => {
                      console.log("failed to get trigger outputs", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get trigger outputs, try again")));
                      reject("failed to get trigger outputs, try again");
                    });
                })
                .catch((err) => {
                  console.log("faield to get edge endpoint", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get trigger outputs, try again")));
                  reject("failed to get trigger outputs, try again");
                });
              break;
            default:
              resolve();
          }
        } else if (stepType == WorkflowNodeType.Operator) {
          const dataType = s[2];
          switch (dataType) {
            case "approvals":
              getApproval(selectedWorkflowRun?.result?.steps?.[stepId]?.approvalID as string, true)
                .then((approval) => {
                  setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, getApprovals(approval))));
                  resolve();
                })
                .catch((err) => {
                  console.log("failed to get approvals", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get approvals, try again")));
                  reject("failed to get approvals, try again");
                });
              break;
            case "parameters":
              getEdgeEndpoint(edgeId)
                .then((edgeEndpoint) => {
                  if (isSubworkflowOperator(stepId)) {
                    getOperatorParametersApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    ).then((parameters) => {
                      const parametersValues = {} as Record<string, any>;
                      Promise.allSettled(Object.entries(parameters).map( async ([paramRefName, paramMeta]) => {
                        const meta = paramMeta as IODataMeta;
                        let paramName = "";
                        const subworkflowRun = await getSubworkflowRun(selectedWorkflowRun?.result?.steps?.[stepId]?.subworkflowRunID as string);
                        if (subworkflowRun) {
                          paramName =  subworkflowRun.workflows.parameters.find((parameter) => parameter.referenceName == paramRefName)?.name as string;
                        }
                        if (meta.value) {
                          if (paramName != "") {
                            parametersValues[paramName] = meta.value;
                          } else {
                            parametersValues[paramRefName] = meta.value;
                          }
                        } else {
                          return Promise.allSettled(meta.pathInfos?.map(async (pathInfo: any) => {
                            const endpoint = await getEdgeEndpoint(pathInfo.edgeID);
                            if (endpoint) {
                              const data = await getDataByPathApi(
                                endpoint,
                                selectedWorkflow.tenantID,
                                pathInfo.path,
                                meta.field,
                              );
                              if (data) {
                                if (data.value) {
                                  return data.value
                                }
                                return data;
                              }
                            }
                          })).then((values) => {
                            values.map((value) => {
                              if(value.status == "fulfilled") {
                                if (paramName != "") {
                                  parametersValues[paramName] = value.value;
                                } else {
                                  parametersValues[paramRefName] = value.value;
                                }
                              }
                            });
                          }).catch((err) => {
                            throw err;
                          })
                        }
                      })).then((values) => {
                        setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, parametersValues)));
                        resolve();
                      }).catch((err) => {
                        console.log("failed to get subworkflow operator parameters", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get subworkflow operator parameters, try again")));
                        reject(err);
                      });
                    }).catch((err) => {
                      console.log("failed to get subworkflow operator parameters", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get subworkflow operator parameters, try again")));
                      reject("failed to get subworkflow operator parameters, try again");
                    });
                  } else {
                    getOperatorParametersApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    )
                    .then((parameters) => {
                      setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, parameters)));
                      resolve();
                    })
                    .catch((err) => {
                      console.log("failed to get operator parameters", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator parameters, try again")));
                      reject("failed to get operator parameters, try again");
                    });
                  }
                })  
                .catch((err) => {
                  console.log("failed to get edge endpoint", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator parameters, try again")));
                  reject("failed to get operator parameters, try again");
                });
              break;
/*             case "logs":
              getEdgeEndpoint(edgeId)
                .then((edgeEndpoint) => {
                  getOperatorLogsApi(
                    edgeEndpoint,
                    selectedWorkflow.tenantID,
                    selectedWorkflow.id,
                    selectedWorkflowRun.id,
                    stepId,
                    showTimeStamp,
                    showDetailLogs
                  )
                    .then((logs) => {
                      setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, logs)));
                      resolve();
                    })
                    .catch((err) => {
                      console.log("failed to get operator logs", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator logs, try again")));
                      reject("failed to get operator logs, try again");
                    });
                })
                .catch((err) => {
                  console.log("failed to get edge endpoint", err);
                  setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator logs, try again")));
                  reject("failed to get operator logs, try again");
                });
                break; */
            case "outputs":
                getEdgeEndpoint(edgeId)
                  .then((edgeEndpoint) => {
                  if (isLoopOperator(stepId)) {
                    getOperatorOutputsApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    ).then((outputs) => {
                      const outputValues = {} as Record<string, any>;
                      Promise.allSettled(Object.entries(outputs).map( async ([outputRefName, outputMeta]) => {
                        const meta = outputMeta as IODataMeta;
                        return Promise.allSettled(meta.pathInfos?.map(async (pathInfo: any) => {
                          const endpoint = await getEdgeEndpoint(pathInfo.edgeID);
                          if (endpoint) {
                            const data = await getDataByPathApi(
                              endpoint,
                              selectedWorkflow.tenantID,
                              pathInfo.path,
                              meta.field,
                            );
                            if (data) {
                              if (data.value) {
                                return data.value
                              }
                              return data;
                            }
                          }
                        })).then((values) => {
                          values.map((value) => {
                            if(value.status == "fulfilled") {
                              outputValues[outputRefName] = value.value;
                            }
                          });
                        }).catch((err) => {
                          throw err;
                        })
                      })).then((values) => {
                        setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, outputValues)));
                        resolve();
                      }).catch((err) => {
                        console.log("failed to get loop operator outputs", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get loop operator outputs, try again")));
                        reject(err);
                      });
                    }).catch((err) => {
                      console.log("failed to get loop operator outputs", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get loop operator outputs, try again")));
                      reject("failed to get loop operator outputs, try again");
                    });
                  } else if (isKVOperator(stepId)) {
                    getOperatorOutputsApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    ).then((outputs) => {
                      const kvValues = {} as Record<string, any>;
                      Promise.allSettled(Object.entries(outputs).map( async ([kvKey, outputMeta]) => {
                        const meta = outputMeta as IODataMeta;
                        if (meta.pathInfos && meta.pathInfos.length > 0) { 
                          return Promise.allSettled(meta.pathInfos.map(async (pathInfo: any) => {
                            const endpoint = await getEdgeEndpoint(pathInfo.edgeID);
                            if (endpoint) {
                              const data = await getDataByPathApi(
                                endpoint,
                                selectedWorkflow.tenantID,
                                pathInfo.path,
                                meta.field,
                              );
                              if (data) {
                                if (data.value) {
                                  return data.value
                                }
                                return data;
                              }
                            }
                          })).then((values) => {
                            if (meta.type == DataType.Array) {
                              let kvArrayValues = [] as any[];
                              values.map((value) => {
                                if(value.status == "fulfilled") {
                                  kvArrayValues = kvArrayValues.concat(value.value);
                                }
                              });
                              kvValues[kvKey] = kvArrayValues;
                            } else {
                              values.map((value) => {
                                if(value.status == "fulfilled") {
                                  kvValues[kvKey] = value.value;
                                }
                              });
                            }
                          }).catch((err) => {
                            throw err;
                          })
                        } else {
                          switch (meta.type) {
                            case DataType.Array:
                              kvValues[kvKey] = [];
                              break;
                            case DataType.Numeric:
                              kvValues[kvKey] = meta.value ? meta.value : 0;
                              break;
                            case DataType.Boolean:
                              kvValues[kvKey] = meta.value ? meta.value : false;
                              break;
                            default:
                              kvValues[kvKey] = meta.value ? meta.value : "";
                              break;
                          }
                        }
                      })).then((values) => {
                        setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, {"items": kvValues})));
                        resolve();
                      }).catch((err) => {
                        console.log("failed to get kv operator outputs", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get kv operator outputs, try again")));
                        reject(err);
                      });
                    }).catch((err) => {
                      console.log("failed to get kv operator outputs", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get kv operator outputs, try again")));
                      reject("failed to get kv operator outputs, try again");
                    });
                  } else if (isSubworkflowOperator(stepId)) {
                    getOperatorOutputsApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    ).then((outputs) => {
                      const outputsValues = {} as Record<string, any>;
                      Promise.allSettled(Object.entries(outputs).map( async ([outputRefName, outputMeta]) => {
                        const meta = outputMeta as IODataMeta;
                        let outputName = "";
                        const subworkflowRun = await getSubworkflowRun(selectedWorkflowRun?.result?.steps?.[stepId]?.subworkflowRunID as string);
                        if (subworkflowRun) {
                          outputName =  subworkflowRun.workflows.outputs.find((output) => output.referenceName == outputRefName)?.name as string;
                        }
                        return Promise.allSettled(meta.pathInfos?.map(async (pathInfo: any) => {
                          const endpoint = await getEdgeEndpoint(pathInfo.edgeID);
                          if (endpoint) {
                            const data = await getDataByPathApi(
                              endpoint,
                              selectedWorkflow.tenantID,
                              pathInfo.path,
                              meta.field,
                            );
                            if (data) {
                              if (data.value) {
                                return data.value
                              }
                              return data;
                            }
                          }
                        })).then((values) => {
                          values.map((value) => {
                            if(value.status == "fulfilled") {
                              if (outputName != "") {
                                outputsValues[outputName] = value.value;
                              } else {
                                outputsValues[outputRefName] = value.value;
                              }
                            }
                          });
                        }).catch((err) => {
                          throw err;
                        })
                      })).then((values) => {
                        setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, outputsValues)));
                        resolve();
                      }).catch((err) => {
                        console.log("failed to get subworkflow operator outputs", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get subworkflow operator outputs, try again")));
                        reject(err);
                      });
                    }).catch((err) => {
                      console.log("failed to get subworkflow operator outputs", err);
                      setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get subworkflow operator outputs, try again")));
                      reject("failed to get subworkflow operator outputs, try again");
                    });
                  } else if (isSearchOperator(stepId)) {
                    getOperatorOutputsApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    )
                      .then((outputs) => {
                        setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, getSearchOutputs(stepId, outputs))));
                        resolve();
                      })
                      .catch((err) => {
                        console.log("failed to get operator outputs", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator outputs, try again")));
                        reject("failed to get operator outputs, try again");
                      });
                  } else {
                    getOperatorOutputsApi(
                      edgeEndpoint,
                      selectedWorkflow.tenantID,
                      selectedWorkflow.id,
                      selectedWorkflowRun.id,
                      stepId
                    )
                      .then((outputs) => {
                        setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, outputs)));
                        resolve();
                      })
                      .catch((err) => {
                        console.log("failed to get operator outputs", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator outputs, try again")));
                        reject("failed to get operator outputs, try again");
                      });
                    }
                  })
                  .catch((err) => {
                    console.log("failed to get edge endpoint", err);
                    setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get operator outputs, try again")));
                    reject("failed to get operator logs, try again");
                  });
              break;
            default:
              resolve();
          }
        } else if (stepType == WorkflowNodeType.Action) {
          if (s.length < 4) {
            resolve();
            return;
          }
          const providerId = s[2];
          const subscriptionId = s[3];
          const dataType = s[4];
          const step = Object.entries(selectedWorkflow?.steps).find(
            ([id]) => id == stepId
          )?.[1];
          if (step) {
            const actionStep = step as WorkflowStepAction;
            const provider = actionStep.provider;
            if (provider) {
              const appSubscriptionInfo = provider.appSubscriptionInfos.find(
                (as) => as.appSubscriptionID == subscriptionId
              );
              if (appSubscriptionInfo) {
                const edgeId = appSubscriptionInfo.edgeID;
                switch (dataType) {
                  case "parameters":
                    getEdgeEndpoint(edgeId)
                      .then((edgeEndpoint) => {
                        getActionParametersApi(
                          edgeEndpoint,
                          selectedWorkflow.tenantID,
                          selectedWorkflow.id,
                          selectedWorkflowRun.id,
                          stepId,
                          appSubscriptionInfo.appSubscriptionID
                        )
                          .then((parameters) => {
                            setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, parameters)));
                            resolve();
                          })
                          .catch((err) => {
                            console.log("failed to get action parameters", err);
                            setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action parameters, try again")));
                            reject(
                              "failed to get action parameters, try again"
                            );
                          });
                      })
                      .catch((err) => {
                        console.log("failed to get edge endpoint", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action parameters, try again")));
                        reject("failed to get action parameters, try again");
                      });
                    break;
/*                   case "logs":
                    getEdgeEndpoint(edgeId)
                      .then((edgeEndpoint) => {
                        getActionLogsApi(
                          edgeEndpoint,
                          selectedWorkflow.tenantID,
                          selectedWorkflow.id,
                          selectedWorkflowRun.id,
                          stepId,
                          appSubscriptionInfo.appSubscriptionID,
                          showTimeStamp,
                          showDetailLogs
                        )
                          .then((logs) => {
                            setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, logs)));
                            resolve();
                          })
                          .catch((err) => {
                            console.log("failed to get action logs", err);
                            setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action logs, try again")));
                            reject("Failed to get action logs, try again");
                          });
                      })
                      .catch((err) => {
                        console.log("faield to get edge endpoint", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action logs, try again")));
                        reject("failed to get action logs, try again");
                      });
                    break; */
                  case "outputs":
                    getEdgeEndpoint(edgeId)
                      .then((edgeEndpoint) => {
                        getActionOutputsApi(
                          edgeEndpoint,
                          selectedWorkflow.tenantID,
                          selectedWorkflow.id,
                          selectedWorkflowRun.id,
                          stepId,
                          appSubscriptionInfo.appSubscriptionID
                        )
                          .then((outputs) => {
                            setTreeData((origin: any) => updateTreeData(origin, key, getJsonViewTreeNodes(key, outputs)));
                            resolve();
                          })
                          .catch((err) => {
                            console.log("failed to get action outputs", err);
                            setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action outputs, try again")));
                            reject("failed to get action outputs, try again");
                          });
                      })
                      .catch((err) => {
                        console.log("failed to get edge endpoint", err);
                        setTreeData((origin: any) => updateTreeData(origin, key, getErrorTreeNodes(key, "Failed to get action outputs, try again")));
                        reject("failed to get action outputs, try again");
                      });
                    break;
                  default:
                    resolve();
                }
              }
            }
          }
        } else {
          resolve();
        }
      }
    });
  };

  const showRunStatusBadge = (status: WorkflowRunStatusCode) => {
    switch (status) {
      case WorkflowRunStatusCode.NotRunning:
        return <Badge color="grey" />;
      case WorkflowRunStatusCode.Running:
        return <Badge color="orange" status="processing" />;
      case WorkflowRunStatusCode.PendingApproval:
        return <Badge color="blue" status="processing"/>;
      case WorkflowRunStatusCode.Completed:
        return <Badge color="green" status="success" />;
      case WorkflowRunStatusCode.Failed:
        return <Badge color="red" status="error" />;
      default:
        return <></>;
    }
  };

  /* TODO - enable settings once we add logs support to steps and trigger
  const settings: MenuProps["items"] = [
    {
      key: showTimeStampSettingKey,
      label: "Show Timestamps",
      icon: <></>,
    },
    {
      key: viewDetailLogsSettingKey,
      label: "View Detail Logs",
      icon: <></>,
    },
  ];

  const handleSettingsMenuClick: MenuProps["onClick"] = (e) => {
    e.key == showTimeStampSettingKey && setShowTimeStamp(!showTimeStamp);
    e.key == viewDetailLogsSettingKey && setShowDetailLogs(!showDetailLogs);
  };

  const getSelectedSettings = () => {
    const selectedSettings: string[] = [];
    showTimeStamp && selectedSettings.push(showTimeStampSettingKey);
    showDetailLogs && selectedSettings.push(viewDetailLogsSettingKey);
    return selectedSettings;
  };
  */

  const isLoopOperator = (stepId: string) => {
    if (stepId == "") {
      return false;
    }
    const stepOperator = selectedWorkflow?.steps[stepId] as WorkflowStepOperator;
    return stepOperator?.operatorType == OperatorType.Loop;
  }

  const isKVOperator = (stepId: string) => {
    if (stepId == "") {
      return false;
    }
    const stepOperator = selectedWorkflow?.steps[stepId] as WorkflowStepOperator;
    return stepOperator?.operatorType == OperatorType.KV;
  }

  const isSubworkflowOperator = (stepId: string) => {
    if (stepId == "") {
      return false;
    }
    const stepOperator = selectedWorkflow?.steps[stepId] as WorkflowStepOperator;
    return stepOperator?.operatorType == OperatorType.Subworkflow;
  }

  const getLoopOperatorType = (stepId: string) => {
    if (stepId == "") {
      return "";
    }
    const stepOperator = selectedWorkflow?.steps[stepId] as WorkflowStepOperator;
    return stepOperator?.parameters?.type;
  }

  const getSubworkflowRun = async (workflowRunId: string) => {
    let subworkflowRun = subworkflowRunMap.get(workflowRunId);
    if (!subworkflowRun) {
      subworkflowRun = await getWorkflowRunApi(workflowRunId);
      subworkflowRunMap.set(workflowRunId, subworkflowRun);
      setSubworkflowRunMap(subworkflowRunMap);
    }
    return subworkflowRun;
  };

  return (
    <Spin spinning={loader}>
      <Card 
        style={{ 
          height: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: token.borderRadius, 
          background: token.colorPrimaryBg,
          marginBottom: token.marginSM,
        }}
      >
        <Row justify="space-between">
          <Space size={token.sizeSM}>
            <div>{showRunStatusBadge(props.isWorkflowCard ? selectedWorkflowRun?.status : getStepStatus(selectedWorkflowRun.result, props.stepId as string))}</div>
            <div>{WorkflowRunStatusCodeMap.get(props.isWorkflowCard ? selectedWorkflowRun?.status : getStepStatus(selectedWorkflowRun.result, props.stepId as string))}</div>
          </Space>
          {/* <Dropdown
            placement="bottomRight"
            menu={{
              items: settings,
              onClick: handleSettingsMenuClick,
              selectedKeys: getSelectedSettings(),
            }}
          >
            <div>
              <SvgIcon size={token.size} component={workflowIcons.logSettingsIcon} />
            </div>
          </Dropdown> */}
        </Row>
      </Card>
      {/* {(props.isWorkflowCard ? selectedWorkflowRun?.status : getStepStatus(selectedWorkflowRun.result?.steps, props.stepId as string)) == WorkflowRunStatusCode.Completed && (
        <div style={{ margin: "10px", justifyContent: "center" }}>
          <SearchInput
            placeholder="search logs"
            onFilter={(e) => setSearchText(e.target.value)}
          />
        </div>
      )} */}
      <Tree
        showIcon
        onExpand={(keys: React.Key[], {expanded, node}: any) => {
          setExpandedKeys(keys);
          setAutoExpandParent(false);
        }}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        loadData={onLoadData}
        onLoad={(keys) => setLoadedKeys(keys)}
        loadedKeys={loadedKeys}
        treeData={treeData}
      />
    </Spin>
  );
};

export default RunLogTree;
