import { FC, useEffect, useState } from "react";
import {
  Radio,
  RadioChangeEvent,
  Form,
  Input,
  Spin,
  Space,
  theme,
  Select,
  Typography,
  Switch,
} from "antd";

import Modal from "components/Modal";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

import { CreateWorkflowModalProps, TriggerType, TriggerTypeList, TriggerTypeMap, Edge, EdgeInfraType, EdgeManageType, EdgeInfraTypeMap } from "types";
import { FieldLabel } from "components/FieldLabel";
import { useEdgeStore } from "store";
import { isProduction, defaultEdgeId } from "api/constant";

const CreateWorkflowModal: FC<CreateWorkflowModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [selectedTriggerType, setSelectedTriggerType] = useState(TriggerType.Custom);
  const [edge, setEdge] = useState<Edge|undefined>();
  const [isSubworkflow, setIsSubworkflow] = useState<boolean>(false);

  const {
    edges,
    getEdges,
  } = useEdgeStore((state) => ({
    edges: state.edges,
    getEdges: state.getEdges,
  }));

  const loadEdges = async () => {
    try {
      setLoader(true);
      if (!isProduction) {
        await getEdges();       
      } else {
        await getEdges(false, EdgeInfraType.Amazon);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
   (selectedTriggerType != TriggerType.Custom) && loadEdges();
  }, [selectedTriggerType]);
  
  useEffect(() => {
    setEdge(edges?.find((edge) => edge.id == defaultEdgeId));
  }, [edges]);

  const onTriggerTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedTriggerType(value);
  };

  return (
    <Modal
      loader={loader}
      title={"New Workflow"}
      open={open}
      footerName="Create"
      onClose={onClose}
      onSubmit={() => 
        form
          .validateFields()
          .then(onSubmit)
          .catch((err) => {
            console.log(err);
          })
      }
    >
      <Spin spinning={loader}>
        <Form
          form={form}
          name="saveWorkflowForm"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical" 
        >
          <Space size={token.marginXXS} direction="vertical" style={{ display: 'flex' }}>
            <Form.Item
              name="name"
              label={<FieldLabel label={"Workflow Name"} help={"Workflow Name"} />}
              initialValue={""}
              required
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input placeholder="Enter workflow name"/>
            </Form.Item>

            <Form.Item
              name="description"
              label={<FieldLabel label={"Description"} help={"Workflow Description"} />}
              rules={[{ required: true, message: "Description is required!" }]}
              required
              initialValue={""}
            >
              <TextArea placeholder="Enter description" maxLength={2048}/>
            </Form.Item>
            <Form.Item 
              name="labels"
              label={<FieldLabel label={"Labels"}/>}
              validateTrigger="onSubmit"
            >
              <Select
                showAction={["focus", "click"]}
                placeholder={`Enter labels`}
                allowClear
                mode="tags"
              />
            </Form.Item>
            <Form.Item
              name="isSubworkflow"
              label={<FieldLabel label={"Subworkflow"} help={"Select Subworkflow"} />}
            >
              <Switch
                checked={isSubworkflow}
                onChange={setIsSubworkflow}
              />
            </Form.Item>
            {!isSubworkflow 
              &&
              <Form.Item 
                name="triggerType" 
                label={<FieldLabel label={"Select Trigger Type"} help={"Select Trigger Type"} />}
                initialValue={selectedTriggerType}
              >
                <Radio.Group
                  options={TriggerTypeList.map((triggerType) => ({
                    value: triggerType,
                    label: TriggerTypeMap.get(triggerType)
                  }))}
                  onChange={onTriggerTypeChange}
                  value={selectedTriggerType}
                />
              </Form.Item>
            }
            {selectedTriggerType != TriggerType.Custom
              &&
              <Form.Item 
                name="edgeId" 
                label={<FieldLabel label={"Edge"} help={"Select edge where trigger to be executed"} />}
                rules={[{ required: true, message: "Edge is required!" }]}
                required
                initialValue={edge?.id ? edge?.id : defaultEdgeId}
                extra={edge &&  
                  <Text italic type="secondary">
                    <div>Type: {EdgeInfraTypeMap.get(edge.infraType)}</div>
                    <div>Region: {edge.region}</div>
                    <div>Managed By: {edge.manageType == EdgeManageType.HyprEdge? "HyprEdge" : "Customer"}</div>
                  </Text>}
              >
                <Select
                  showAction={["focus", "click"]}
                  placeholder={`Select edge`}
                  onSelect={(value) => setEdge(edges?.find((edge) => edge.id == value))}
                >
                  {edges?.map((edge: Edge) =>  (
                      <Option key={edge.id} value={edge.id}>
                        {edge.displayName}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            }
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateWorkflowModal;
