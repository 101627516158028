import { FC, useEffect, useState } from "react";
import { 
    theme, 
    Button, 
    Card, 
    Dropdown, 
    Space, 
    Checkbox, 
    Row,
    Col,
    Divider,
    Radio
} from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { SvgIcon } from "components/SvgIcon";
import { commonIcons } from "assets/icons";
import { FieldLabel } from "components/FieldLabel";

export interface Filter {
    name: string,
    displayName: string,
    hidden?: boolean,
    single?: boolean,
    options: Map<string, any>
}

export enum SorterType {
    String = "string",
    Numeri = "numeric",
    Time = "time"
}

export interface Sorter {
    name: string,
    displayName: string,
    type: SorterType,
    hidden?: boolean
}

export interface FiltersAndSortsProps {
    title?: any,
    filters?: Filter[];
    sorters?: Sorter[];
    width?: string;
    height?: string;
    currentFilters?: Record<string, string[]>,
    currentSorters?: Record<string, string>,
    onClose?: () => void;
    onApply: (filters: Record<string, string[]> | undefined, sorters: Record<string, string> | undefined) => void; 
}

export const FiltersAndSorts: FC<FiltersAndSortsProps> = ({ 
    title,
    filters,
    sorters,
    width,
    height,
    currentFilters,
    currentSorters,
    onClose,
    onApply,
}) => {
    const { token } = theme.useToken();
    const [filterVisible, setFilterVisible] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]> | undefined>(currentFilters); 
    const [selectedSorters, setSelectedSorters] = useState<Record<string, string> | undefined>(currentSorters); 

    const dropdownRender = () => {
        return (
            <Card
                title={title ? title : "Filters & Sorters"}
                size="small"
                styles={{
                    header: {
                        backgroundColor: token.colorPrimary,
                    },
                    body: {
                        width: width? width : "500px",
                        height: height ? height : "auto",
                        maxHeight: "calc(100vh - 300px)",
                        padding: token.paddingMD,
                        overflow: "auto",
                    },
                }}
                extra={
                    <CloseOutlined onClick={() => {
                        setFilterVisible(false);
                        onClose?.();
                    }} />
                }
            >
                {sorters && sorters?.filter((s) => !s.hidden).length > 0 
                    &&
                    <>
                        <FieldLabel bold label={"Sorters"} />
                        <Divider style={{ borderColor: token.colorPrimaryBorderHover }} />
                    </>
                }
                <Space direction="vertical" size={token.size}>
                    {sorters?.filter((s) => !s.hidden).map((sorter) =>
                        <>
                            <FieldLabel bold label={sorter.displayName} />
                            <Radio.Group
                                key={sorter.name}
                                onChange={(e) => {
                                    setSelectedSorters({...selectedSorters,  ...{ [sorter.name]: e.target.value }});
                                }}
                                defaultValue={currentSorters?.[sorter.name]}
                                value={selectedSorters?.[sorter.name]}
                            >
                                <Row gutter={[8, 8]}>
                                    {sorter.type == SorterType.String
                                        ?
                                        <Col>
                                            <Radio value={"ascend"}>{"A-Z"}</Radio>
                                            <Radio value={"descend"}>{"Z-A"}</Radio>
                                        </Col>
                                        :
                                            sorter.type == SorterType.Time
                                            ?
                                            <Col>
                                                <Radio value={"ascend"}>{"Oldest"}</Radio>
                                                <Radio value={"descend"}>{"Most Recent"}</Radio>
                                            </Col>
                                            :
                                            <Col>
                                                <Radio value={"ascend"}>{"Ascend"}</Radio>
                                                <Radio value={"descend"}>{"Descend"}</Radio>
                                            </Col>
                                    }
                                </Row>
                            </Radio.Group>
                        </>
                    )}
                </Space>
                {filters && filters?.filter((s) => !s.hidden).length > 0 
                    &&
                    <>
                        <FieldLabel bold label={"Filters"} />
                        <Divider style={{ borderColor: token.colorPrimaryBorderHover }} />
                    </>
                }
                <Space direction="vertical" size={token.size}>
                    {filters?.filter((f) => !f.hidden).map((filter) =>
                        <>
                            <FieldLabel bold label={filter.displayName} />
                            {filter?.single
                                ?
                                <Radio.Group
                                    onChange={(e) => {
                                        setSelectedFilters({...selectedFilters,  ...{ [filter.name]: [e.target.value] }});
                                    }}
                                    defaultValue={currentFilters?.[filter.name]?.[0]}
                                    value={selectedFilters?.[filter.name]?.[0]}
                                >
                                    <Row gutter={[8, 8]}>
                                        {Array.from(filter.options).map(([k, v]) => 
                                            <Col key={k}>
                                                <Radio value={k}>{v}</Radio>
                                            </Col>
                                        )}
                                    </Row>
                                </Radio.Group>
                                :
                                <Checkbox.Group
                                    onChange={(values) => {
                                        if (values.length > 0) {
                                            setSelectedFilters({...selectedFilters,  ...{ [filter.name]: [...values] }});
                                        } else {
                                            selectedFilters?.[filter.name] && delete selectedFilters[filter.name];
                                            setSelectedFilters({...selectedFilters});
                                        }
                                    }}
                                    defaultValue={currentFilters?.[filter.name]}
                                    value={selectedFilters?.[filter.name]}
                                >
                                    <Row gutter={[8, 8]}>
                                        {Array.from(filter.options).map(([k, v]) => 
                                            <Col key={k}>
                                                <Checkbox value={k}>{v}</Checkbox>
                                            </Col>
                                        )}
                                    </Row>
                                </Checkbox.Group>
                            }   
                        </>
                    )}
                </Space>
                <Divider style={{ borderColor: token.colorPrimaryBorderHover }} />
                <div style={{ textAlign: "right" }}>
                    <Space>
                        <Button
                            type="primary"
                            disabled={
                                selectedFilters && Object.keys(selectedFilters).length == 0 &&
                                selectedSorters && Object.keys(selectedSorters).length == 0
                            }
                            onClick={() => {
                                setSelectedFilters({});
                                setSelectedSorters({});
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setFilterVisible(false);
                                onApply(selectedFilters, selectedSorters);
                            }}
                        >
                            Apply
                        </Button>
                    </Space>
                </div>
            </Card>
        );
    };

    return (
        <Dropdown
            placement={"bottomRight"}
            open={filterVisible}
            //destroyPopupOnHide={true}
            onOpenChange={(flag) => setFilterVisible(flag)}
            trigger={["click"]}
            dropdownRender={(menu) => dropdownRender()}
        >
            <Button
                icon={
                    <SvgIcon 
                        size={25} 
                        component={commonIcons.filterSortIcon} 
                        hexColor={
                            selectedFilters && Object.keys(selectedFilters).length || selectedSorters && Object.keys(selectedSorters).length ? token.colorPrimary : token.colorBgMask}
                    />
                }
                onClick={(e) => e.preventDefault()}
            />
        </Dropdown>
    );
};
