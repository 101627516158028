import { FC, useEffect, useState } from "react";
import {
  Button,
  Space,
  Spin,
  Form,
  Input,
  Typography,
  theme,
  Row,
  Radio,
  RadioChangeEvent,
  Select,
  FormInstance,
  Switch
} from "antd";

import moment from "moment";
import validator from "validator";
import { v4 as uuidv4 } from "uuid";

import {
  Operator,
  WorkflowNodeCardProps,
  WorkflowStepOperator,
  OperatorParameterMap,
  TriggerType,
  TextType,
  WorkflowStepProvider,
  AppInfo,
  WorkflowAppSubscriptionInfo,
  ActionProvider,
  ApprovalsRequiredFrom,
  OperatorParameterApprover,
  AppSubscriptionInfos,
} from "types";
import { notification } from 'utility/notification';
import { useRbacStore, useWorkflowStepProvidersStore, useWorkflowStepsStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";

import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
  SuggestionsInput,
  validateSuggestionInput
} from "components/Suggestions";
import CollapsePanel from "../../CollapsePanel";

import { SvgIcon } from "components/SvgIcon";
import { workflowIcons } from "assets/icons";
import { FieldLabel } from "components/FieldLabel";
import ConditionsForm from "components/ConditionsForm";
import { validateFormFields } from "utility";
import NodeCardApps from "./Apps";
import { sendEmailActionId } from "api/constant";

const { Text } = Typography;
const { Option } = Select;

const ApprovalOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  resourceType,
  nodeType,
  editMode,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [operatorParameters, setOperatorParameters] = useState({} as OperatorParameterMap);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const { operators } = useWorkflowStepsStore((state: any) => ({operators: state.operators,}));

  const [actionAppInfos, setActionAppInfos] = useState(new Map<string, AppInfo>());
  const [selectedActionApp, setSelectedActionApp] = useState<Record<string, string|string[]>>({});

  const users = useRbacStore((state) => state.users);
  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
  const [approversFieldStatus, setApproversFieldStatus] = useState<"" | "warning" | "error" | undefined>("");
  const [approvers, setApprovers] = useState<string[]>([]);
  const [approvalTimeoutDays, setApprovalTimeoutDays] = useState<number>(0);
  const [approvalTimeoutHours, setApprovalTimeoutHours] = useState<number>(0);
  const [approvalTimeoutMinutes, setApprovalTimeoutMinutes] = useState<number>(0);
  const [approvalsRequiredFrom, setApprovalsRequiredFrom] = useState<string>(selectedWorkflow?.steps[id]?.parameters?.approvals_required_from);
  const [emailApproval, setEmailApproval] = useState<boolean>(selectedWorkflow?.steps[id]?.parameters?.email_approval);
  const [conditionsForm, setConditionsForm] = useState<undefined|FormInstance>(undefined);

  const syncOperator = async () => {
    try {
      setLoader(true);
      const operator = operators.find((i: Operator) => i.id == resourceId);
      operator && setOperatorParameters(operator.parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    syncOperator();
  }, [operators]);

  const syncCurrentState = async () => {
    try {
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if (operatorStep) {
        if (!operatorStep.isConfigured) {
          operatorParameters &&
            Object.entries(operatorParameters).forEach(([key, value]) => {
              if (value.default != undefined) {
                operatorStep.parameters[key] = value.default;
              }
            });
        }

        let currentApprovers = [] as string[];
        if (operatorStep.parameters.approvers) {
          const approversList = operatorStep.parameters.approvers as OperatorParameterApprover[];
          currentApprovers = approversList.map((approver) => approver.isManagedUser ? approver.id : approver.email);
        }

        form.setFieldsValue({ 
          approvers: currentApprovers,
          approvals_required_from: operatorStep.parameters.approvals_required_from,
          email_approval: operatorStep.parameters.email_approval,
          additional_notes: operatorStep.parameters.additional_notes,
          conditions: operatorStep.parameters.conditions
        });

        if (operatorStep.parameters.email_approval) {
          let actionApp = {} as Record<string, string|string[]>;
          if (operatorStep.actionProvider && operatorStep.actionProvider.providerID) {
            actionApp = {
              providerId: operatorStep.actionProvider.providerID,
              appSubscriptions: operatorStep.actionProvider?.appSubscriptionInfos?.map((s) => (s.appSubscriptionID)),
            };
          }
          setSelectedActionApp(actionApp);
          form.setFieldValue("actionApps", actionApp);
        }

        const timeout = moment.duration(operatorStep.parameters.timeout, 'minutes');
        if (timeout) {
          setApprovalTimeoutDays(timeout.days());
          setApprovalTimeoutHours(timeout.hours());
          setApprovalTimeoutMinutes(timeout.minutes());
        }
       
        setApprovers(currentApprovers);
        setApprovalsRequiredFrom(operatorStep.parameters.approvals_required_from);
        setEmailApproval(operatorStep.parameters.email_approval);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow, operatorParameters]);

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const { actionProvidersMap, getActionProviders } = useWorkflowStepProvidersStore((state) => ({
          actionProvidersMap: state.actionProvidersMap,
          getActionProviders: state.getActionProviders,
        }));
  
  const loadActionProviders = async () => {
    try {
      if(actionProvidersMap.get(sendEmailActionId) == undefined) {
        setLoader(true);
        await getActionProviders(sendEmailActionId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    emailApproval && loadActionProviders();
  }, [emailApproval]);
  
  const syncActionAppInfos = async () => {
    try {
      const actionProvider = actionProvidersMap.get(sendEmailActionId);
      const appInfosMap = new Map<string, AppInfo>();
      if (actionProvider) {
        actionProvider.providers &&
          actionProvider.providers.map((provider: ActionProvider) => {
            if (provider?.appInfo?.appSubscriptionsInfos) {
              provider.appInfo.appSubscriptionsInfos = provider.appInfo.appSubscriptionsInfos.filter((as) => as.tenantID == selectedWorkflow.tenantID);
            }

            appInfosMap.set(provider.id, provider.appInfo);
          });
      }
      setActionAppInfos(appInfosMap);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncActionAppInfos();
  }, [actionProvidersMap]);


  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        setReferenceName(operatorStep.referenceName);

        operatorStep.parameters = {};
        operatorStep.parameters.approvals_required_from = values.approvals_required_from;
        operatorStep.parameters.email_approval = emailApproval;
        operatorStep.parameters.additional_notes = values.additional_notes;
        if (values.conditions) {
          operatorStep.parameters.conditions = values.conditions;
        }

        operatorStep.parameters.approvers = [] as OperatorParameterApprover[];
        values.approvers.map((approver: string) => {
          if(validator.isEmail(approver)) {
            operatorStep.parameters.approvers.push({
              id: uuidv4(), //TBD - generate uuid using email address
              email: approver,
              isManagedUser: false,
            } as OperatorParameterApprover);
          } else if (validator.isUUID(approver)) {
            operatorStep.parameters.approvers.push({
              id: approver,
              email: users[approver]?.email,
              isManagedUser: true,
            } as OperatorParameterApprover);
          }
        });

        operatorStep.parameters.timeout = approvalTimeoutDays * 24 * 60 + approvalTimeoutHours * 60 + approvalTimeoutMinutes;

        const actionProvider = {} as WorkflowStepProvider;
        if (operatorStep.parameters.email_approval) {
          if (selectedActionApp) {
            const providerId = selectedActionApp.providerId as string;
            if (providerId) {
              const appInfo = actionAppInfos.get(providerId);
              if (appInfo) {
                actionProvider.providerID = providerId;
                actionProvider.appID = appInfo.id;
                actionProvider.displayName = appInfo.displayName;
                actionProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
                const subscriptions = selectedActionApp.appSubscriptions as string[];
                subscriptions?.map((subscriptionId) => {
                  actionProvider.appSubscriptionInfos.push({
                    appSubscriptionID: subscriptionId,
                  } as WorkflowAppSubscriptionInfo);
                });
              }
            }
          }
        } 
        operatorStep.actionProvider = actionProvider;  
        
        selectedWorkflow.steps[id] = operatorStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateApproverSelectFields = (approvers: string[]) => {
    setApproversFieldStatus("");
    setApprovers(approvers);
    !emailApproval && setEmailApproval(approvers?.some((approver) => validator.isEmail(approver)));

    approvers.map((approver) => {
      if (validator.isUUID(approver)) {
        if (!Object.keys(users).includes(approver)) {
          setApproversFieldStatus("error");
        }
      } else {
        if(!validator.isEmail(approver)) {
          setApproversFieldStatus("error");
        }
        //TODO - validate user domain for emails
      }
    });
  };

  return (
    <Form
      form={form}
      name="operatorCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Operator Name"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" && <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
          <CollapsePanel 
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.parametersShortIcon} />
                <Text>Parameters</Text>
              </Space>
            }
            ghost={false} 
            collapsePanel={false}
          >
            <Space size={token.sizeXXS} direction="vertical" style={{ display: "flex" }}>
              <Form.Item 
                name="approvers"
                required
                label={<FieldLabel label={"Approvers"} help={"Approvers list"} />}
                rules={[
                  {
                    validator: (_, values: string[]) => 
                        (values.length == 0) 
                          ?
                            Promise.reject("Approvers are required!!")
                          :
                            (!values?.every((value) => validator.isEmail(value) || validator.isUUID(value))) 
                              ? Promise.reject("Invalid approvers!!")
                              : Promise.resolve(),
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <Select
                  disabled={!editMode}
                  showAction={["focus", "click"]}
                  placeholder={`Select user or type approver's valid email`}
                  allowClear
                  mode="tags"
                  status={approversFieldStatus}
                  onChange={validateApproverSelectFields}
                >
                  {Object.keys(users)
                    .map((userId) => (
                      <Option key={userId} value={userId}>
                        {users[userId].firstName} {users[userId].lastName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item 
                name="approvals_required_from"
                label={<FieldLabel label={"Approvals Required From"} help={"Required approvals from all approvals or first approval from aprovers"} />}
                rules={[
                  { required: true, message: `Approvals required from is required!`}
                ]}
                validateTrigger="onSubmit"
              >
                <Radio.Group
                  disabled={!editMode}
                  value={approvalsRequiredFrom}
                  onChange={(e: RadioChangeEvent) => setApprovalsRequiredFrom(e.target.value)}
                >
                  <Radio.Button value={ApprovalsRequiredFrom.All}>All</Radio.Button>
                  <Radio.Button value={ApprovalsRequiredFrom.Any}>Any</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item 
                name="timeout"
                required
                label={<FieldLabel label={"Maximum Time To Wait"} help={"Max time to wait for approval"} />}
                validateTrigger="onSubmit"
              >
                {
                  <Space style={{ display: "flex" }}>
                    <Select
                      disabled={!editMode}
                      style={{ width: 55 }}
                      value={approvalTimeoutDays}
                      onSelect={setApprovalTimeoutDays}
                      options={Array.from({ length: 8 }, (_, i) => ({ value: i }))}
                    />
                    {"Days"}
                    <Select
                      disabled={!editMode}
                      style={{ width: 60 }}
                      value={approvalTimeoutHours}
                      onSelect={setApprovalTimeoutHours}
                      options={Array.from({ length: 24 }, (_, i) => ({ value: i }))}
                    />
                    {"Hours"}
                    <Select
                      disabled={!editMode}
                      style={{ width: 60 }}
                      value={approvalTimeoutMinutes}
                      onSelect={setApprovalTimeoutMinutes}
                      options={Array.from({ length: 60 }, (_, i) => ({ value: i }))}
                    />
                    {"Minutes"}
                  </Space>
                }
              </Form.Item>
              <Form.Item 
                name="email_approval"
                label={<FieldLabel label={"Email Approval"} help={"Approval via email"} />}
                rules={[
                  { required: true, message: "Email approval is required!!" },
                ]}
                validateTrigger="onSubmit"
              >
                <Switch
                  disabled={!editMode || approvers?.some((approver) => validator.isEmail(approver))}
                  checked={emailApproval}
                  onChange={setEmailApproval}
                />
              </Form.Item>
              {emailApproval &&
                <CollapsePanel
                  name={
                    <Space size={token.marginXXS}>
                      <SvgIcon component={workflowIcons.appsShortIcon} />
                      <Text>Email Apps</Text>
                    </Space>
                  }
                  ghost={false}
                  collapsePanel={false}
                  style={{ "marginBottom": token.margin }}
                >
                  <Form.Item
                    name="actionApps"
                    validateTrigger="onSubmit"
                    rules={[
                      { validator: (_, value) => (!value || (value && value.length == 0)) ? Promise.reject("Apps are required!"):Promise.resolve()},
                    ]}
                  >
                    <NodeCardApps 
                      isSingle={true}
                      appInfos={actionAppInfos} 
                      selectedApps={selectedActionApp}  
                      editMode={editMode} 
                      onChange={(app) => 
                        setSelectedActionApp(
                          form.getFieldValue("actionApps")
                              ? form.getFieldValue("actionApps")
                              : app as Record<string, string | string[]>
                        )
                      }
                    />
                  </Form.Item>
                </CollapsePanel>
              }
              <Form.Item 
                name="additional_notes"
                label={<FieldLabel label={"Additional Notes"} help={"Additional notes to add in approval"} />}
                rules={[
                 {
                    validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                  }
                ]}
                validateTrigger="onSubmit"
              >
                <SuggestionsInput
                  editMode={editMode}
                  name={"Additional Notes"} 
                  suggestionsTree={parameterSuggestionsTree}
                  textType={TextType.Rich}
                />
              </Form.Item>
              <Form.Item 
                name="conditions"
                label={<FieldLabel label={"Conditions"} help={"Conditions to send approval, optional"} />}
                rules={[
                  {
                    validator: (_, value) => validateFormFields(conditionsForm),
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <ConditionsForm 
                  editMode={editMode}
                  required={false}
                  conditions={selectedWorkflow?.steps[id]?.parameters?.conditions ? selectedWorkflow?.steps[id]?.parameters?.conditions : undefined}
                  factSuggestionsTree={parameterSuggestionsTree}
                  onRender={form => setConditionsForm(form)}
                />
             </Form.Item>
            </Space>
          </CollapsePanel>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default ApprovalOperatorCard;
