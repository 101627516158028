import { CSSProperties, FC, useState,  } from "react";
import {
  StepForwardOutlined,
  CopyOutlined,
  DeleteFilled,
  SwapOutlined,
} from "@ant-design/icons";

import {
  Tooltip,
  Button,
  theme,
} from "antd";

import { useWorkflowStore } from "store";
import {  WorkflowNodeActionType, WorkflowNodeActionProps, WorkflowNodeType, OperatorType, NodeActionCallbackFn, WorkflowStepOperator } from "types";
import { getStepColor, getTriggerDisplayName } from "utility/workflow";

import Modal from "../../Modal";
import NodeDeleteOptions from "./deleteOptions";

const NodeAction: FC<WorkflowNodeActionProps> = ({
  id,
  resourceType,
  nodeType,
  actionCallback,
  isCart,
}) => {
  const { token } = theme.useToken();
  const [deleteNodeModal, setDeleteNodeModal] = useState(false);
  const [keepBranchId, setKeepBranchId] = useState<string>("");
  const defaultKeepBranchIndex = 0;
  const antdButtonSize = "small";
  const showHoverActions = actionCallback && nodeType != WorkflowNodeType.Trigger;
  const showExecuteAction = actionCallback && nodeType != WorkflowNodeType.Trigger;
  const showDeleteAction = actionCallback && nodeType != WorkflowNodeType.Trigger;
  const showCopyAction = actionCallback && (nodeType != WorkflowNodeType.Trigger) && 
      (resourceType != OperatorType.Condition)&& 
      (resourceType != OperatorType.Approval) && 
      (resourceType != OperatorType.Loop) && 
      (resourceType != OperatorType.Parallel); 
  const showSwapBranch = actionCallback && (nodeType == WorkflowNodeType.Operator) && 
      ((resourceType == OperatorType.Condition) ||  (resourceType == OperatorType.Approval));

  const buttonIconStyle: CSSProperties = {
    color: token.colorPrimary,
    borderColor: token.colorBorder
  }
  
  const { selectedWorkflow } = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow
    }),
  );
  
  const getTooltipPlacement = () => {
    return isCart ? "bottom" : "top";
  }

  const getDisplayName = () => {
    let displayName: string |undefined;
    if(nodeType == WorkflowNodeType.Trigger){
      displayName = getTriggerDisplayName(selectedWorkflow);
    }else {
      displayName = selectedWorkflow.steps[id]?.name ? selectedWorkflow.steps[id]?.name: "";
    }
    return displayName? displayName : "";
  }

  const onNodeDelete = () => {
    let calculatedKeepBranchId =  keepBranchId;
    if(calculatedKeepBranchId == "") {
      if(haveMoreThanOneChild()){
        const childrens = getStepChildrens();
        calculatedKeepBranchId = childrens[0];
      }
    }
    actionCallback && actionCallback(WorkflowNodeActionType.Delete, id, calculatedKeepBranchId);
    setDeleteNodeModal(false);
    setKeepBranchId("");
  }

  const haveMoreThanOneChild = (): boolean =>{
    switch(nodeType){
      case WorkflowNodeType.Action:
        return false;
      case WorkflowNodeType.Operator:{   
        switch(resourceType){
          case OperatorType.Condition: {
            const step = selectedWorkflow.steps[id] as WorkflowStepOperator;
            return Object.entries(step?.decisionSteps).length > 1;
          }
          case OperatorType.Approval: {
            const step = selectedWorkflow.steps[id] as WorkflowStepOperator;
            return Object.entries(step?.decisionSteps).length > 1;
          }
          case OperatorType.Parallel: {
            const step = selectedWorkflow.steps[id] as WorkflowStepOperator;
            return Object.entries(step?.parallelSteps).length > 1;
          }
          case OperatorType.Wait, OperatorType.Search, OperatorType.KV, OperatorType.Subworkflow, OperatorType.Http, OperatorType.Db, OperatorType.Ai, OperatorType.Script:
          default:
            return false;
        }
      }
      default:
        return false;
    }
  }

  const getStepChildrens = (): string[] =>{
    switch(nodeType){
      case WorkflowNodeType.Operator:{   
        switch(resourceType){
          case OperatorType.Condition: {
            const step = selectedWorkflow.steps[id] as WorkflowStepOperator;
            const childrens: string [] = [];
            Object.entries(step?.decisionSteps).forEach(([label, stepId]) => {
              childrens.push(stepId);
            });
            return childrens;
          }
          case OperatorType.Approval: {
            const step = selectedWorkflow.steps[id] as WorkflowStepOperator;
            const childrens: string [] = [];
            Object.entries(step?.decisionSteps).forEach(([label, stepId]) => {
              childrens.push(stepId);
            });
            return childrens;
          }
          case OperatorType.Parallel: {
            const step = selectedWorkflow.steps[id] as WorkflowStepOperator;
            return step.parallelSteps;
          }
          default:
            return [];
        }
      }
      default:
        return [];
    }
  }
  
  const onKeepBranchChanged = (keepBranchId: string) => {
    setKeepBranchId(keepBranchId);
  };  

  return (
    <>
      { showHoverActions &&
      <div style={{alignContent: "center"}}>
        { showExecuteAction &&
          <Tooltip key="execute" title="Execute step" placement={getTooltipPlacement()} color={token.colorPrimaryHover}>
            <Button 
              type="default" 
              ghost
              size={antdButtonSize}
              icon={<StepForwardOutlined style={buttonIconStyle}/>}
            />
          </Tooltip>
        }
        { showCopyAction && 
          <Tooltip key="copy" title="Copy step" placement={getTooltipPlacement()} color={token.colorPrimaryHover}>
            <Button
              type="default" 
              ghost
              size={antdButtonSize}
              icon={<CopyOutlined style={buttonIconStyle}/>}
              onClick={(event:any) => {
                event.stopPropagation();
                actionCallback(WorkflowNodeActionType.Copy, id);
              }}
            /> 
          </Tooltip>
        }
        { showSwapBranch &&
          <Tooltip key="swap" title="Swap step conditions" placement={getTooltipPlacement()} color={token.colorPrimary}> 
            <Button 
              type="default" 
              ghost
              size={antdButtonSize}
              icon={<SwapOutlined style={buttonIconStyle}/>}
              onClick={(event:any) => {
                event.stopPropagation();
                actionCallback(WorkflowNodeActionType.SwapBranch, id);
              }}
            />
          </Tooltip>
        }
        { showDeleteAction &&
          <Tooltip key="delete" title="Delete step" placement={getTooltipPlacement()} color={token.colorPrimaryHover}>
            <Button 
                type="default" 
                ghost
                size={antdButtonSize}
                icon={<DeleteFilled style={buttonIconStyle}/>}
                onClick={(event:any) => {
                  event.stopPropagation();
                  setDeleteNodeModal(true)
                }}
            />
          </Tooltip>
        }
      </div>}
      {deleteNodeModal && (
        <Modal
          title="Delete Step"
          onClose={() => {
            setDeleteNodeModal(false);
            setKeepBranchId("");
          }}
          open={deleteNodeModal}
          onSubmit={() => onNodeDelete()}
        >
          <p>{`Are you sure you want to delete step  "${getDisplayName()}"  ? `}</p>
          {haveMoreThanOneChild() ?
            <NodeDeleteOptions 
              childrens={getStepChildrens()}
              defaultIndex={defaultKeepBranchIndex}
              onKeepBranchChangedCallback={onKeepBranchChanged}
            />
          :null}
        </Modal>
      )}
    </>
  );
};

export default NodeAction;
