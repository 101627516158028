import { FC, memo, useEffect, useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Card, Tabs, Tooltip, Typography, theme } from "antd";
import { Resizable } from "react-resizable";
import { useWorkflowStore } from "store";
import {
  Approver,
  OperatorType,
  RenderCardProps,
  WorkflowNodeType,
  WorkflowRunStatusCode,
} from "types";
import { getStepIcon, getTriggerDisplayName } from "utility/workflow";

import NodeCard from "../NodeCard";
import RunLogTree from "../RunLogTree";
import WorkflowCard from "../WorkflowCard";

import { commonIcons, workflowIcons } from "assets/icons";
import { createUseStyles } from "react-jss";
import NodeAction from "../NodeAction";
import { TextWithIcon } from "components/TextWithIcon";
import ApprovalAction from "components/ApprovalAction";

const { Text } = Typography;
const RenderCard: FC<RenderCardProps> = (props) => {
  const { token } = theme.useToken();
  const jss = createUseStyles({
    renderCard: {
      minWidth: "400px",
      maxWidth: "800px",
      top: token.padding,
      right: token.padding,
      position: "absolute",
    },
    customHandle: {
      position: "absolute",
      width: "5px",
      height: "100%",
      left: 0,
      top: 0,
      cursor: "col-resize",
    },
  })();

  const [cardWidth, setCardWidth] = useState(320);

  const { selectedWorkflow, selectedWorkflowRun } = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow,
      selectedWorkflowRun: state.selectedWorkflowRun,
    })
  );

  const getEditMode = (editMode: boolean): boolean => {
    return selectedWorkflowRun?.status == WorkflowRunStatusCode.Running || selectedWorkflowRun?.status == WorkflowRunStatusCode.PendingApproval
      ? false
      : editMode;
  };

  const getCardTitle = () => {
    if (props.showWorkflowCard) {
      return  <TextWithIcon 
                icon={workflowIcons.workflowShortIcon}
                text={"Workflow"}
                subText={selectedWorkflow.name}
                color={token.colorTextLightSolid}
              />
    } else if (props.nodeCardProps?.nodeType == WorkflowNodeType.Trigger) {
      return <TextWithIcon 
                icon={getStepIcon(props.nodeCardProps?.nodeType)}
                text={"Trigger"}
                subText={""}
                color={token.colorTextLightSolid}
              />
    } else if (props.nodeCardProps?.nodeType == WorkflowNodeType.Action) {
      let displayName = "";
      if (props.nodeCardProps?.id) {
        displayName = selectedWorkflow.steps[props.nodeCardProps?.id]?.name;
      }
      return <TextWithIcon 
                icon={getStepIcon(props.nodeCardProps?.nodeType)}
                text={"Action"}
                subText={displayName}
                color={token.colorTextLightSolid}
              />
    } else {
      let displayName = "";
      if (props.nodeCardProps?.id) {
        displayName = selectedWorkflow.steps[props.nodeCardProps?.id]?.name;
      }
      return <TextWithIcon 
                icon={ getStepIcon(
                  props.nodeCardProps?.nodeType,
                  props.nodeCardProps?.resourceType
                )}
                text={"Operator"}
                subText={displayName}
                color={token.colorTextLightSolid}
              />
    }
  };

  const getReferenceName = () => {
    let displayName = "";
    const id = props.nodeCardProps?.id;
    if (id) {
      if (props.nodeCardProps?.nodeType == WorkflowNodeType.Trigger) {
        const triggerDisplayName = getTriggerDisplayName(selectedWorkflow);
        if (triggerDisplayName) displayName = triggerDisplayName;
      } else {
        displayName = selectedWorkflow.steps?.[id]?.name ? selectedWorkflow.steps[id]?.name : "";
      }
    }
    return displayName;
  };

  return (
    <Resizable
      width={cardWidth}
      height={300}
      onResize={(e, { size }) => {
        setCardWidth(size.width);
      }}
      resizeHandles={["w"]}
      handle={<span className={jss.customHandle} />}
    >
      <Card
        size="small"
        title={getCardTitle()}
        extra={
          <CloseOutlined
            style={{ color: token.colorTextLightSolid }}
            onClick={() => props.onClose()}
          />
        }
        style={{ width: cardWidth }}
        className={jss.renderCard}
        styles={{
          header: {
            backgroundColor: token.colorPrimary,
            textAlign: "left",
          },
          body: {
            backgroundColor: token.colorBgContainer,
            maxHeight: "calc(100vh - 179px)",
            overflowY: "auto",
            overflowX: "hidden",
            padding: token.padding
          }
        }}
      >
        <Tabs
          defaultActiveKey={
            selectedWorkflowRun?.status == WorkflowRunStatusCode.Running || selectedWorkflowRun?.status == WorkflowRunStatusCode.PendingApproval
              ? "logs"
              : "properties"
          }
          items={[
            {
              label: <TextWithIcon icon={commonIcons.propertiesIcon} text={"Properties"} />,
              key: "properties",
              children: props.showWorkflowCard ? (
                <WorkflowCard editMode={getEditMode(props.editMode)} onClose={props.onClose}/>
              ) : (
                props.nodeCardProps && (
                  <NodeCard
                    {...props.nodeCardProps}
                    editMode={getEditMode(
                      props.nodeCardProps?.editMode as boolean
                    )}
                    onClose={props.onClose}
                  />
                )
              ),
            },
            {
              label: <TextWithIcon icon={commonIcons.logsIcon} text={"Logs"} />,
              key: "logs",
              children: (
                <RunLogTree
                  isWorkflowCard={props.showWorkflowCard}
                  stepId={props.nodeCardProps?.id}
                />
              ),
            },
          ]}
          tabBarExtraContent={
            (
              !props.showWorkflowCard &&
              props.nodeCardProps?.nodeType != WorkflowNodeType.Trigger &&
              props.nodeCardProps?.actionCallback &&
              props.editMode &&
              selectedWorkflowRun.status == WorkflowRunStatusCode.NotRunning
            ) 
              ?
                <NodeAction
                  id={props.nodeCardProps.id}
                  resourceType={selectedWorkflow.steps[props.nodeCardProps?.id]?.type}
                  actionCallback={props.nodeCardProps?.actionCallback}
                  nodeType={props.nodeCardProps.nodeType}
                  isCart={true}
                />
              :
                props.nodeCardProps?.resourceType == OperatorType.Approval 
                ?
                  <ApprovalAction 
                    approvalId={selectedWorkflowRun?.result?.steps?.[props.nodeCardProps?.id]?.approvalID}
                    displayName={getReferenceName()}
                    isTooltipButton={true}
                    toolTipPlacement={"bottom"}
                  />
                :
                  <></>
          }
        />
      </Card>
    </Resizable>
  );
};

export default memo(RenderCard);
